import { useRef, useState, useEffect, useContext } from 'react'
import { useNavigate, Navigate, Routes } from 'react-router-dom'
const Header = () => {
  const firstUserName = localStorage.getItem('userName')
  const secondUserName = JSON.parse(firstUserName)

  //const firstUserOrganisation = localStorage.getItem('userOrganisation')
  //const secondUserOrganisation = JSON.parse(firstUserOrganisation)

  const first = localStorage.getItem('userID')
  const second = JSON.parse(first)

  var userID = localStorage.getItem('userID')
  userID = JSON.parse(userID)

  var userInstitution = localStorage.getItem('userInstitution')
  userInstitution = JSON.parse(userInstitution)

  var userOrganisation = localStorage.getItem('userOrganisation')
  userOrganisation = JSON.parse(userOrganisation)
  if (userOrganisation === '') {
    userOrganisation = userInstitution
  }
  var loggedIn = localStorage.getItem('loggedIn')
  loggedIn = JSON.parse(loggedIn)

  var login = localStorage.getItem('login')
  login = JSON.parse(login)

  var userPosition = localStorage.getItem('userPosition')
  userPosition = JSON.parse(userPosition)

  var userDepartment = localStorage.getItem('userDepartment')
  userDepartment = JSON.parse(userDepartment)

  var userSystemRole = localStorage.getItem('userSystemRole')
  userSystemRole = JSON.parse(userSystemRole)

  var userProvincesID = localStorage.getItem('userProvincesID')
  userProvincesID = JSON.parse(userProvincesID)

  var userCouncilsID = localStorage.getItem('userCouncilsID')
  userCouncilsID = JSON.parse(userCouncilsID)

  var userConstituenciesID = localStorage.getItem('userConstituenciesID')
  userConstituenciesID = JSON.parse(userConstituenciesID)

  var userWardsID = localStorage.getItem('userWardsID')
  userWardsID = JSON.parse(userWardsID)

  let userName = localStorage.getItem('userName')
  userName = JSON.parse(userName)

  let userPhone = localStorage.getItem('userPhone')
  userPhone = JSON.parse(userPhone)

  let userNRC = localStorage.getItem('userNRC')
  userNRC = JSON.parse(userNRC)

  let userEmail = localStorage.getItem('userEmail')
  userEmail = JSON.parse(userEmail)

  let userGender = localStorage.getItem('userGender')
  userGender = JSON.parse(userGender)

  if (second != null) {
    return (
      <>
        {/*<!-- Main Navigation -->*/}
        <header>
          {/*<!-- Navbar -->*/}
          <nav class='navbar fixed-top navbar-expand-lg scrolling-navbar double-nav'>
            {/*<!-- SideNav slide-out button -->*/}
            {/*<div class="float-left">
        <a href="#" data-activates="slide-out" class="button-collapse" onClick={button_collapse}><i class="fas fa-bars"></i></a>
	</div>*/}

            {/*<!-- Breadcrumb -->*/}
            <div class='breadcrumb-dn mr-auto'>
              <p>{userOrganisation}</p>
              {/*<p>KANCHY TECHNOLOGIES BUSINESS MANAGEMENT SYSTEM</p>*/}
            </div>

            <div class='d-flex change-mode'>
              {/*<!--<div class="ml-auto mr-3 change-mode-wrapper">
          <a href="Home"><button class="btn  btn-sm blue-text" id="dark-mode">Dashboard</button></a>
	  </div>-->*/}

              {/*<!-- Navbar links -->*/}
              <ul class='nav navbar-nav nav-flex-icons ml-auto'>
                <li class='nav-item'>
                  <a href='Home' class='nav-link waves-effect'>
                    <i class='fas fa-tachometer-alt fa-fw me-3'></i>{' '}
                    <span class='clearfix d-none d-sm-inline-block'>
                      Dashboard
                    </span>
                  </a>
                </li>
                {/*<!-- Dropdown -->*/}
                <li
                  class='nav-item dropdown notifications-nav'
                  hidden={
                    userSystemRole == 'User' ||
                    userSystemRole == 'Station Administrator'
                  }
                >
                  <a
                    class='nav-link dropdown-toggle waves-effect'
                    id='navbarDropdownMenuLink'
                    data-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    <i class='fas fa-cogs'></i>
                    <span class='d-none d-md-inline-block'>Administration</span>
                  </a>
                  <div
                    class='dropdown-menu dropdown-primary'
                    aria-labelledby='navbarDropdownMenuLink'
                  >
                    <a class='dropdown-item' href='#'>
                      {' '}
                      <span>Register Province</span>{' '}
                    </a>
                    <a class='dropdown-item' href='#'>
                      {' '}
                      <span>Register Local Authority</span>{' '}
                    </a>
                    <a class='dropdown-item' href='#'>
                      {' '}
                      <span>Register Constituency</span>{' '}
                    </a>
                    <a class='dropdown-item' href='#'>
                      {' '}
                      <span>Register Ward</span>{' '}
                    </a>
                  </div>
                </li>

                <li
                  class='nav-item dropdown notifications-nav'
                  hidden={
                    userPosition != 'Council Sectretary' &&
                    userPosition != 'Town Clerk' &&
                    userPosition != 'Minister' &&
                    userPosition != 'PLGO' &&
                    userPosition != 'The President' &&
                    userPosition != 'The Vice President' &&
                    userSystemRole != 'Super Administrator' &&
                    userSystemRole != 'Systems Administrator'
                  }
                >
                  <a
                    class='nav-link dropdown-toggle waves-effect'
                    id='navbarDropdownMenuLink'
                    data-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    <i class='fas fa-cog'></i>
                    <span class='d-none d-md-inline-block'>Settings</span>
                  </a>
                  <div
                    class='dropdown-menu dropdown-primary'
                    aria-labelledby='navbarDropdownMenuLink'
                  >
                    <a class='dropdown-item' href='#'>
                      {' '}
                      <span>Developers</span>{' '}
                    </a>
                    <a class='dropdown-item' href='#'>
                      {' '}
                      <span>API keys</span>{' '}
                    </a>
                    <a class='dropdown-item' href='#'>
                      {' '}
                      <span>Webhooks</span>{' '}
                    </a>
                    <a class='dropdown-item' href='#'>
                      {' '}
                      <span>Developer docs</span>{' '}
                    </a>
                  </div>
                </li>

                {/*<li class="nav-item dropdown notifications-nav">
            <a class="nav-link dropdown-toggle waves-effect" id="navbarDropdownMenuLink" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
               <i class="fas fa-cog"></i>
              <span class="d-none d-md-inline-block">Settings</span>
            </a>
            <div class="dropdown-menu dropdown-primary" aria-labelledby="navbarDropdownMenuLink">
            
            </div>
	</li>*/}

                <li
                  class='nav-item dropdown'
                  hidden={
                    userPosition == 'Councilor' ||
                    userPosition == 'WDC Chairperson' ||
                    userPosition == 'WDC Member' ||
                    userPosition === 'Director' ||
                    userPosition === 'DPO'
                  }
                >
                  <a
                    class='nav-link dropdown-toggle waves-effect'
                    href='#'
                    id='userDropdown'
                    data-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    <i class='fas fa-users'></i>{' '}
                    <span class='clearfix d-none d-sm-inline-block'>
                      CDF Committe
                    </span>
                  </a>
                  <div
                    class='dropdown-menu dropdown-primary'
                    aria-labelledby='userDropdown'
                  >
                    <ul class='waves-effect'>
                      <a href='#' class='dropdown-item'>
                        <span>View Resolutions and Process Tracker</span>
                      </a>
                      {/*<a href='#' class='dropdown-item'>
                        <span>Community Projects</span>
                      </a>
                      <a href='#' class='dropdown-item'>
                        <span>Skills Development Bursaries</span>
                      </a>
                      <a href='#' class='dropdown-item'>
                        <span>Secondary Boarding School Bursaries</span>
                      </a>
                      <a href='#' class='dropdown-item'>
                        <span>Loans</span>
                      </a>
                      <a href='#' class='dropdown-item'>
                        <span>youth, Women and Community Grants</span>
                      </a>*/}
                      <a
                        href='CDFCRecordMinutes'
                        class='dropdown-item'
                        hidden={
                          userPosition != 'CDF Committee Member' &&
                          userPosition != 'CDF Committee Chairperson'
                        }
                      >
                        <span>Record Minutes</span>
                      </a>
                    </ul>
                  </div>
                </li>
                <li
                  class='nav-item dropdown'
                  hidden={
                    userPosition != 'Councilor' &&
                    userPosition != 'WDC Chairperson' &&
                    userPosition != 'WDC Member' &&
                    userPosition != 'Minister' &&
                    userPosition != 'The President' &&
                    userPosition != 'The Vice President'
                  }
                >
                  <a
                    class='nav-link dropdown-toggle waves-effect'
                    href='#'
                    id='userDropdown'
                    data-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    <i class='fas fa-users'></i>{' '}
                    <span class='clearfix d-none d-sm-inline-block'>
                      WD Committee
                    </span>
                  </a>
                  <div
                    class='dropdown-menu dropdown-primary'
                    aria-labelledby='userDropdown'
                  >
                    <ul class='waves-effect'>
                      <a href='#' class='dropdown-item'>
                        <span>View Resolutions and Process Tracker</span>
                      </a>
                      {/* <a href='#' class='dropdown-item'>
                        <span>Community Projects</span>
                      </a>
                      <a href='#' class='dropdown-item'>
                        <span>Skills Development Bursaries</span>
                      </a>
                      <a href='#' class='dropdown-item'>
                        <span>Secondary Boarding School Bursaries</span>
                      </a>
                      <a href='#' class='dropdown-item'>
                        <span>Loans</span>
                      </a>
                      <a href='#' class='dropdown-item'>
                        <span>youth, Women and Community Grants</span>
                      </a>*/}
                      <a
                        href='WDCRecordMinutes'
                        class='dropdown-item'
                        hidden={
                          userPosition != 'Councilor' &&
                          userPosition != 'WDC Committee Member' &&
                          userPosition != 'WDC Committee Chairperson'
                        }
                      >
                        <span>Record Minutes</span>
                      </a>
                    </ul>
                  </div>
                </li>
                <li
                  class='nav-item dropdown'
                  hidden={
                    userPosition === 'Councilor' ||
                    userPosition === 'WDC Chairperson' ||
                    userPosition === 'WDC Member' ||
                    userPosition === 'PLGO'
                  }
                >
                  <a
                    class='nav-link dropdown-toggle waves-effect'
                    href='#'
                    id='userDropdown'
                    data-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    <i class='fas fa-tasks'></i>{' '}
                    <span class='clearfix d-none d-sm-inline-block'>
                      Directorates
                    </span>
                  </a>
                  <div
                    class='dropdown-menu dropdown-primary'
                    aria-labelledby='userDropdown'
                  >
                    <a class='dropdown-item' href='#'>
                      {' '}
                      <span>Planning</span>{' '}
                    </a>
                    <a class='dropdown-item' href='#'>
                      {' '}
                      <span>Finance</span>{' '}
                    </a>
                    <a class='dropdown-item' href='#'>
                      {' '}
                      <span>Engineering</span>{' '}
                    </a>
                    <a class='dropdown-item' href='#'>
                      {' '}
                      <span>Procurement</span>{' '}
                    </a>
                  </div>
                </li>

                <li
                  class='nav-item dropdown'
                  hidden={userPosition === 'Director' || userPosition === 'DPO'}
                >
                  <a
                    class='nav-link dropdown-toggle waves-effect'
                    href='#'
                    id='userDropdown'
                    data-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    <i class='fas fa-graduation-cap'></i>{' '}
                    <span class='clearfix d-none d-sm-inline-block'>
                      Learners Tracker
                    </span>
                  </a>
                  <div
                    class='dropdown-menu dropdown-primary'
                    aria-labelledby='navbarDropdownMenuLink'
                  >
                    <a class='dropdown-item' href='#'>
                      {' '}
                      <span>Skills Development Bursaries</span>{' '}
                    </a>
                    <a class='dropdown-item' href='#'>
                      {' '}
                      <span>Secondary Boarding Schools Bursaries</span>{' '}
                    </a>
                  </div>
                </li>

                <li
                  class='nav-item dropdown'
                  hidden={
                    userPosition === 'Councilor' ||
                    userPosition === 'WDC Member' ||
                    userPosition === 'CDF Committee Member' ||
                    userPosition === 'MP' ||
                    userPosition === 'Director' ||
                    userPosition === 'DPO' ||
                    userPosition === 'PLGO'
                  }
                >
                  <a
                    class='nav-link dropdown-toggle waves-effect'
                    href='#'
                    id='userDropdown'
                    data-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    <i class='fas fa-users'></i>{' '}
                    <span class='clearfix d-none d-sm-inline-block'>
                      User Management
                    </span>
                  </a>
                  <div
                    class='dropdown-menu dropdown-primary'
                    aria-labelledby='navbarDropdownMenuLink'
                  >
                    <a class='dropdown-item' href='#'>
                      {' '}
                      <span>Register CDF Beneficiary</span>{' '}
                    </a>
                    <a class='dropdown-item' href='RegisterUsers'>
                      {' '}
                      <span>User Creation</span>{' '}
                    </a>
                  </div>
                </li>

                <li class='nav-item dropdown'>
                  <a
                    class='nav-link dropdown-toggle waves-effect'
                    href='#'
                    id='userDropdown'
                    data-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    <i class='fas fa-user'></i>{' '}
                    <span class='clearfix d-none d-sm-inline-block'>
                      Profile
                    </span>
                  </a>
                  <div
                    class='dropdown-menu dropdown-menu-right'
                    aria-labelledby='userDropdown'
                    style={{
                      height: '',
                      width: '400%',
                      position: ''
                    }}
                  >
                    <div
                      class='card-header white-text'
                      style={{ width: '100%', position: '' }}
                    >
                      <i class='fas fa-user-alt'></i> <u>{secondUserName}</u>
                    </div>
                    <a class='dropdown-item' href='Logout'>
                      <i class='w-fa fas fa-sign-out-alt'></i> Log Out
                    </a>
                    {/*<a class="dropdown-item" href="Form1">Form 1</a>*/}
                    {/*<a class="dropdown-item" href="DataTables">Data Tables</a>*/}
                    {/*} <a class='dropdown-item' href='Tesseract'>
                      Tesseract
                    </a>*/}
                    <a class='dropdown-item' href='#'>
                      {' '}
                      <i class='fa fa-edit'></i> My Profile
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </nav>
          {/*<!-- Navbar -->*/}
        </header>
      </>
    )
  }
}

export default Header
