import { useRef, useState, useEffect, useContext } from 'react'
import '../App.css'
import {
  faCheck,
  faTimes,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Axios from '../api/axios'
import $, { nodeName } from 'jquery'
import 'jquery/dist/jquery.min.js'
import { useNavigate } from 'react-router-dom'
import 'jquery/dist/jquery.min.js'
import 'datatables.net-dt/js/dataTables.dataTables'
import 'datatables.net-dt/css/jquery.dataTables.min.css'
import 'datatables.net-buttons/js/dataTables.buttons.js'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.flash.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'
//const USER_REGEX = /^[a-zAZ][a-zA-Z0-9_]{3,23}$/;
const QUANTITY_REGEX = /^\d{1,9}$/
//const PRICE_REGEX = /(?:^[1-9]([0-9]+)?(?:\.[0-9]{1,2})?$)|(?:^(?:0)$)|(?:^[0-9]\.[0-9](?:[0-9])?$)/ ;
const PRICE_REGEX = /^\d+(,\d{3})*(\.\d{2,2})?$/
//const PRICE_REGEX = /^(?!0+$)*[,\d{3}]*{0,10}$/;
//const EMAIL_REGEX = /^[^@]+@[^@]+\.[^@]{2,4}$/;

const SkillsDevelopmentBursaryApplicationForm = () => {
  const server_app_api = window.SERVER_APP_API
  const server_app_api_php = window.SERVER_APP_API_PHP

  const [surname, setsurname] = useState('')
  const [otherNames, setotherNames] = useState('')
  const [sex, setsex] = useState('')
  const [nationality, setnationality] = useState('')
  const [NRCNumber, setNRCNumber] = useState('')
  const [NRCFile, setNRCFile] = useState('')
  const [dob, setdob] = useState('')
  const [pob, setpob] = useState('')
  const [province, setprovince] = useState('')
  const [provinceDropDown, setprovinceDropDown] = useState([])
  const [council, setcouncil] = useState('')
  const [councilDropDown, setcouncilDropDown] = useState([])
  const [constituency, setconstituency] = useState('')
  const [constituencyDropDown, setconstituencyDropDown] = useState([])
  const [ward, setward] = useState('')
  const [wardDropDown, setwardDropDown] = useState([])
  const [zone, setzone] = useState('')
  const [postalAddress, setpostalAddress] = useState('')
  const [vulnerabilityStatus, setvulnerabilityStatus] = useState('')
  const [pleaseSpecifyVulnerability, setpleaseSpecifyVulnerability] =
    useState('')
  const [areYouDisabled, setareYouDisabled] = useState('')
  const [
    pleaseSpecifyNatureOfVulnerability,
    setpleaseSpecifyNatureOfVulnerability
  ] = useState('')
  const [doYouFinancialChallenge, setdoYouFinancialChallenge] = useState('')
  const [pleaseSpecifyFinancialChallenge, setpleaseSpecifyFinancialChallenge] =
    useState('')
  const [SchoolLeaverOrNot, setSchoolLeaverOrNot] = useState('')
  const [lastGradeAttended, setlastGradeAttended] = useState('')
  const [lastSchoolAttended, setlastSchoolAttended] = useState('')
  const [district, setdistrict] = useState('')
  const [fromDate, setfromDate] = useState('')
  const [toDate, settoDate] = useState('')
  const [highestCertificateAttained, sethighestCertificateAttained] =
    useState('')
  const [
    haveYouReceivedAnAcceptanceLetter,
    sethaveYouReceivedAnAcceptanceLetter
  ] = useState('')
  const [
    nameTheInstitutionWhereYouHaveBeenAccepted,
    setnameTheInstitutionWhereYouHaveBeenAccepted
  ] = useState('')
  const [
    whatProgrammeOfStudyDoYouWishToPursue,
    setwhatProgrammeOfStudyDoYouWishToPursue
  ] = useState('')
  const [durationOfProgramme, setdurationOfProgramme] = useState('')
  const [
    haveYouAppliedForAnyScholarshipBefore,
    sethaveYouAppliedForAnyScholarshipBefore
  ] = useState('')
  const [AnyScholarshipBeforeDetails, setAnyScholarshipBeforeDetails] =
    useState('')
  const [
    haveYouEverBenefitedFromTheCDFBursaryBefore,
    sethaveYouEverBenefitedFromTheCDFBursaryBefore
  ] = useState('')
  const [
    detailsOfHowYouBenefitedFromTheCDFBursaryBefore,
    setdetailsOfHowYouBenefitedFromTheCDFBursaryBefore
  ] = useState('')
  const [parentSurname, setparentSurname] = useState('')
  const [parentOtherNames, setparentOtherNames] = useState('')
  const [parentGender, setparentGender] = useState('')
  const [parentDob, setparentDob] = useState('')
  const [parentNationality, setparentNationality] = useState('')
  const [parentNRC, setparentNRC] = useState('')
  const [parentRelationshipToApplicant, setparentRelationshipToApplicant] =
    useState('')
  const [parentVillage, setparentVillage] = useState('')
  const [parentChief, setparentChief] = useState('')
  const [parentDistrict, setparentDistrict] = useState('')
  const [parentResidentialAddress, setparentResidentialAddress] = useState('')
  const [parentConstituency, setparentConstituency] = useState('')
  const [parentProvince, setparentProvince] = useState('')
  const [parentPostalAddress, setparentPostalAddress] = useState('')
  const [parentMobilePhoneNumber, setparentMobilePhoneNumber] = useState('')
  const [parentEmailAddress, setparentEmailAddress] = useState('')
  const [parentFatherOccupationOf, setparentFatherOccupationOf] = useState('')
  const [parentMotherOccupationOf, setparentMotherOccupationOf] = useState('')
  const [parentGuardianOccupationOf, setparentGuardianOccupationOf] =
    useState('')
  const [parentFatherNameOfEmployerOf, setparentFatherNameOfEmployerOf] =
    useState('')
  const [parentMotherNameOfEmployerOf, setparentMotherNameOfEmployerOf] =
    useState('')
  const [parentGuardianNameOfEmployerOf, setparentGuardianNameOfEmployerOf] =
    useState('')
  const [parentFatherPosition, setparentFatherPosition] = useState('')
  const [parentMotherPosition, setparentMotherPosition] = useState('')
  const [parentGuardianPosition, setparentGuardianPosition] = useState('')
  const [parentFatherAddressOfEmployer, setparentFatherAddressOfEmployer] =
    useState('')
  const [parentMotherAddressOfEmployer, setparentMotherAddressOfEmployer] =
    useState('')
  const [parentGuardianAddressOfEmployer, setparentGuardianAddressOfEmployer] =
    useState('')
  const [declarationApplicantName, setdeclarationApplicantName] = useState('')
  const [declarationApplicantNRC, setdeclarationApplicantNRC] = useState('')
  const [declarationSignatureOfApplicant, setdeclarationSignatureOfApplicant] =
    useState('')
  const [declarationDateOfApplicant, setdeclarationDateOfApplicant] =
    useState('')
  const [declarationSignatureOfParent, setdeclarationSignatureOfParent] =
    useState('')
  const [declarationDateOfParent, setdeclarationDateOfParent] = useState('')

  const saveNRCFile = e => {
    setNRCFile(e.target.files[0])
  }
  useEffect(() => {
    localStorage.removeItem('userID')
  }, [])

  useEffect(() => {
    var formDataViewProvinces = new FormData(this)
    formDataViewProvinces.append('apiType', 'getViewProvincesDropDown')
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataViewProvinces,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setprovinceDropDown(response.data)
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [])

  useEffect(() => {
    var formDataViewCouncils = new FormData(this)
    formDataViewCouncils.append('apiType', 'getViewCouncilsDropDown')
    formDataViewCouncils.append('provincesID', province)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataViewCouncils,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(res => {
      setcouncilDropDown(res.data)
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [province]) //councilDropDown

  useEffect(() => {
    var formDataViewConstituencies = new FormData(this)
    formDataViewConstituencies.append(
      'apiType',
      'getViewConstituenciesDropDown'
    )
    formDataViewConstituencies.append('councilsID', council)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataViewConstituencies,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(res => {
      setconstituencyDropDown(res.data)
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [province, council])

  useEffect(() => {
    var formDataViewWards = new FormData(this)
    formDataViewWards.append('apiType', 'getViewWardsDropDown')
    formDataViewWards.append('constituenciesID', constituency)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataViewWards,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(res => {
      setwardDropDown(res.data)
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [province, council, constituency])

  const handleSubmit = async e => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadSkillsDevelopmentBursaryApplicationForm')
    formData.append('surname', surname)
    formData.append('otherNames', otherNames)
    formData.append('sex', sex)
    formData.append('nationality', nationality)
    formData.append('NRCNumber', NRCNumber)
    formData.append('NRCFile', NRCFile)
    formData.append('dob', dob)
    formData.append('pob', pob)
    formData.append('province', province)
    formData.append('council', council)
    formData.append('constituency', constituency)
    formData.append('ward', ward)
    formData.append('zone', zone)
    formData.append('postalAddress', postalAddress)
    formData.append('vulnerabilityStatus', vulnerabilityStatus)
    formData.append('pleaseSpecifyVulnerability', pleaseSpecifyVulnerability)
    formData.append('areYouDisabled', areYouDisabled)
    formData.append(
      'pleaseSpecifyNatureOfVulnerability',
      pleaseSpecifyNatureOfVulnerability
    )
    formData.append('doYouFinancialChallenge', doYouFinancialChallenge)
    formData.append(
      'pleaseSpecifyFinancialChallenge',
      pleaseSpecifyFinancialChallenge
    )
    formData.append('SchoolLeaverOrNot', SchoolLeaverOrNot)
    formData.append('lastGradeAttended', lastGradeAttended)
    formData.append('lastSchoolAttended', lastSchoolAttended)
    formData.append('district', district)
    formData.append('fromDate', fromDate)
    formData.append('toDate', toDate)
    formData.append('highestCertificateAttained', highestCertificateAttained)
    formData.append(
      'haveYouReceivedAnAcceptanceLetter',
      haveYouReceivedAnAcceptanceLetter
    )
    formData.append(
      'nameTheInstitutionWhereYouHaveBeenAccepted',
      nameTheInstitutionWhereYouHaveBeenAccepted
    )
    formData.append(
      'whatProgrammeOfStudyDoYouWishToPursue',
      whatProgrammeOfStudyDoYouWishToPursue
    )
    formData.append('durationOfProgramme', durationOfProgramme)
    formData.append(
      'haveYouAppliedForAnyScholarshipBefore',
      haveYouAppliedForAnyScholarshipBefore
    )
    formData.append('AnyScholarshipBeforeDetails', AnyScholarshipBeforeDetails)
    formData.append(
      'haveYouEverBenefitedFromTheCDFBursaryBefore',
      haveYouEverBenefitedFromTheCDFBursaryBefore
    )
    formData.append(
      'detailsOfHowYouBenefitedFromTheCDFBursaryBefore',
      detailsOfHowYouBenefitedFromTheCDFBursaryBefore
    )
    formData.append('parentSurname', parentSurname)
    formData.append('parentOtherNames', parentOtherNames)
    formData.append('parentGender', parentGender)
    formData.append('parentDob', parentDob)
    formData.append('parentNationality', parentNationality)
    formData.append('parentNRC', parentNRC)
    formData.append(
      'parentRelationshipToApplicant',
      parentRelationshipToApplicant
    )
    formData.append('parentVillage', parentVillage)
    formData.append('parentChief', parentChief)
    formData.append('parentDistrict', parentDistrict)
    formData.append('parentResidentialAddress', parentResidentialAddress)
    formData.append('parentConstituency', parentConstituency)
    formData.append('parentProvince', parentProvince)
    formData.append('parentPostalAddress', parentPostalAddress)
    formData.append('parentMobilePhoneNumber', parentMobilePhoneNumber)
    formData.append('parentEmailAddress', parentEmailAddress)
    formData.append('parentFatherOccupationOf', parentFatherOccupationOf)
    formData.append('parentMotherOccupationOf', parentMotherOccupationOf)
    formData.append('parentGuardianOccupationOf', parentGuardianOccupationOf)
    formData.append(
      'parentFatherNameOfEmployerOf',
      parentFatherNameOfEmployerOf
    )
    formData.append(
      'parentMotherNameOfEmployerOf',
      parentMotherNameOfEmployerOf
    )
    formData.append(
      'parentGuardianNameOfEmployerOf',
      parentGuardianNameOfEmployerOf
    )
    formData.append('parentFatherPosition', parentFatherPosition)
    formData.append('parentMotherPosition', parentMotherPosition)
    formData.append('parentGuardianPosition', parentGuardianPosition)
    formData.append(
      'parentFatherAddressOfEmployer',
      parentFatherAddressOfEmployer
    )
    formData.append(
      'parentMotherAddressOfEmployer',
      parentMotherAddressOfEmployer
    )
    formData.append(
      'parentGuardianAddressOfEmployer',
      parentGuardianAddressOfEmployer
    )
    formData.append('declarationApplicantName', declarationApplicantName)
    formData.append('declarationApplicantNRC', declarationApplicantNRC)
    formData.append(
      'declarationSignatureOfApplicant',
      declarationSignatureOfApplicant
    )
    formData.append('declarationDateOfApplicant', declarationDateOfApplicant)
    formData.append(
      'declarationSignatureOfParent',
      declarationSignatureOfParent
    )
    formData.append('declarationDateOfParent', declarationDateOfParent)

    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      //setPosTableTotal(response.data);
      alert(response.data)
    })
  }

  return (
    <>
      {/*<!-- Main layout -->*/}
      <div>
        <div class='container-fluid '>
          <div class='row ' style={{ paddingTop: '5%' }}>
            {/* <!--Grid column-->*/}
            <div class='col-md-12 mb-0.00001'>
              {/* <!--Card-->*/}
              <div class='card'>
                {/*<!--Card content-->*/}
                <div class='card-body'>
                  <div
                    class='form-header white-text'
                    style={{ backgroundColor: 'green' }}
                  >
                    <center>
                      CONSTITUENCY DEVELOPMENT FUND - INFORMATION MANAGEMENT
                      SYSTEM
                    </center>
                  </div>
                  <div
                    style={{
                      top: '0',
                      left: '0',
                      marginBottom: '2%',
                      float: 'left'
                    }}
                  >
                    <i class='fas fa-home'></i>
                    <a href='/' style={{ color: 'blue' }}>
                      Home
                    </a>
                  </div>
                  <br /> <br />
                  <section>
                    <div class='text-left'>
                      <center>
                        {' '}
                        <img src={require('./img/coat of arms.jpg')} />
                        <strong>
                          <br />
                          CONSTITUENCY DEVELOPMENT FUND (CDF) <br /> SKILLS
                          DEVELOPMENT BURSARY APPLICATION FORM
                        </strong>
                      </center>
                      <br />
                      <strong>Instructions:</strong> This application form
                      should be completed by the applicant and sent together
                      with documents outlined in Annex 1 to the Chairperson,
                      Ward Development Committee. <br />
                      <u>
                        <b>NOTE:</b>
                      </u>{' '}
                      This form is not for sale.
                      <br />
                      <i>
                        Disclaimer: Completion of the form does not guarantee
                        the award of CDF Skills Development Bursary.
                      </i>
                    </div>

                    <br />

                    <br />
                  </section>
                  {/*<!--\form heading -->*/}
                  <div class='card-header' style={{ backgroundColor: 'green' }}>
                    <center>
                      <h6 class='white-text'>
                        PART A: GENERAL DETAILS{' '}
                        <span class='yellow-text'>
                          (To be filled by the applicant)
                        </span>{' '}
                      </h6>
                    </center>
                  </div>
                  <br />
                  <div id='divShowMessageFromAjax'></div>
                  {/*<!--Grid row-->	*/}
                  <div class='row'>
                    {/*<!--Grid column-->*/}
                    <div class='col-md-4 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          {' '}
                          Surname<span class='red-text'>*</span>
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          Placeholder=''
                          value={surname}
                          onChange={e => setsurname(e.target.value)}
                          id='surname'
                          required
                        />
                      </div>
                    </div>
                    {/*<!--\Grid column-->	*/}
                    {/*<!--Grid column-->*/}
                    <div class='col-md-4 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          {' '}
                          Other Names<span class='red-text'>*</span>
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          Placeholder=''
                          value={otherNames}
                          onChange={e => setotherNames(e.target.value)}
                          id='otherNames'
                          required
                        />
                      </div>
                    </div>
                    {/*<!--\Grid column-->	*/}
                    {/*<!--Grid column-->*/}
                    <div class='col-md-4 mb-1 text-left'>
                      {/*  <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        {' '}
                        Sex<span class='red-text'>*</span>
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        value={sex}
                        onChange={e => setsex(e.target.value)}
                        id='sex'
                        required
                      >
                        <option value=''>Choose...</option>
                        <option>Male</option>
                        <option>Female</option>
                      </select>
                      {/*	<!--</div>-->*/}
                    </div>
                    {/*<!--\Grid column-->	*/}
                  </div>
                  {/*<!--Grid row .--> */}
                  {/*<!--Grid row-->	*/}
                  <div class='row'>
                    {/*<!--Grid column-->*/}
                    <div class='col-md-4 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Nationality <span class='red-text'>*</span>
                        </label>
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          Placeholder=''
                          value={nationality}
                          onChange={e => setnationality(e.target.value)}
                          id='nationality'
                          required
                        />
                      </div>
                    </div>
                    {/*<!--\Grid column-->	*/}
                    {/*<!--Grid column-->*/}
                    <div class='col-md-4 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          N.R.C. Number <span class='red-text'>*</span>
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          Placeholder=''
                          value={NRCNumber}
                          onChange={e => setNRCNumber(e.target.value)}
                          id='NRCNumber'
                          required
                        />
                      </div>
                    </div>
                    {/*<!--\Grid column-->	*/}
                    {/*<!--Grid column-->*/}
                    <div class='col-md-4 mb-1 text-left'>
                      {/*<!---<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        {' '}
                        NRC (Photocopy to be attached)
                        <span class='red-text'>*</span>
                      </label>
                      <input
                        type='file'
                        class='form-control'
                        Placeholder=''
                        /*
                        value={NRCFile}
                        onChange={e => setNRCFile(e.target.value)}
                  */
                        onChange={saveNRCFile}
                        id='NRCFile'
                        required
                      />
                      {/*<!--</div>-->*/}
                    </div>
                    {/* <!--\Grid column-->	*/}
                  </div>
                  {/*<!--Grid row .-->*/}
                  {/*<!--Grid row-->		*/}
                  <div class='row'>
                    {/*<!--Grid column-->*/}
                    <div class='col-md-6 mb-1 text-left'>
                      {/* <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Date of Birth <span class='red-text'>*</span>
                      </label>
                      <input
                        type='date'
                        class='form-control'
                        Placeholder=''
                        value={dob}
                        onChange={e => setdob(e.target.value)}
                        id='dob'
                        required
                      />
                      {/*<!--</div>-->*/}
                    </div>
                    {/*<!--\Grid column-->	*/}
                    {/*<!--Grid column-->*/}
                    <div class='col-md-6 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Place of Birth <span class='red-text'>*</span>
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          Placeholder=''
                          value={pob}
                          onChange={e => setpob(e.target.value)}
                          id='pob'
                          required
                        />
                      </div>
                    </div>
                    {/*<!--\Grid column-->	*/}
                  </div>
                  {/*<!--Grid row .-->	*/}
                  <center>
                    <h4>
                      <u>RESIDENCE OF APPLICANT </u>
                    </h4>
                  </center>
                  {/*<!--Grid row-->				*/}
                  <div class='row'>
                    {/* <!--Grid column -->*/}
                    <div class='col-lg-4 col-md-12 mb-1 text-left'>
                      <label for='registerFullName'>
                        Provinces<span class='red-text'>*</span>
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        onChange={e => setprovince(e.target.value)}
                        id='province'
                        required
                      >
                        <option value=''>Choose ....</option>
                        {provinceDropDown.map(valProvince => {
                          return (
                            <option value={valProvince.provincesID}>
                              {valProvince.province}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                    {/* <!--\Grid column-->*/}

                    {/* <!--Grid column-->*/}
                    <div
                      class='col-lg-4 col-md-12 mb-0.01 text-left'
                      id='hideLocalAuthority'
                    >
                      {/* <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Council<span class='red-text'>*</span>{' '}
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        onChange={e => setcouncil(e.target.value)}
                        id='province'
                        required
                        searchable='Search here..'
                      >
                        <option value=''>Choose ....</option>
                        {councilDropDown.map(valCouncil => {
                          return (
                            <option value={valCouncil.councilsID}>
                              {valCouncil.council}
                            </option>
                          )
                        })}
                      </select>
                      {/*<!--</div>-->*/}
                    </div>
                    {/*  <!--Grid column -->*/}
                    <div
                      class='col-lg-4 col-md-12 mb-0.01'
                      style={{ display: 'none' }}
                      id='divShowlocalAuthority'
                    ></div>
                    {/*<!--\Grid column-->*/}

                    {/*<!--\Grid column-->*/}
                    <div
                      class='col-lg-4 col-md-12 mb-1 text-left'
                      id='hideConstituency'
                    >
                      {/* <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Constituency<span class='red-text'>*</span>{' '}
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        onChange={e => setconstituency(e.target.value)}
                        id='constituency'
                        required
                        searchable='Search here..'
                      >
                        <option value=''>Choose ....</option>
                        {constituencyDropDown.map(valConstituency => {
                          return (
                            <option value={valConstituency.constituenciesID}>
                              {valConstituency.constituency}
                            </option>
                          )
                        })}
                      </select>
                      {/*<!--</div>-->*/}
                    </div>
                    {/*<!--Grid column -->*/}
                    <div
                      class='col-lg-4 col-md-12 mb-0.01'
                      style={{ display: 'none' }}
                      id='divShowConstituency'
                    ></div>
                    {/*<!--\Grid column-->*/}

                    {/* <!--\Grid column-->*/}
                    <div
                      class='col-lg-4 col-md-12 mb-1 text-left'
                      id='hideWard'
                    >
                      {/*<!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Ward<span class='red-text'>*</span>{' '}
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        onChange={e => setward(e.target.value)}
                        id='ward'
                        required
                        searchable='Search here..'
                      >
                        <option value=''>Choose ....</option>
                        {wardDropDown.map(valWard => {
                          return (
                            <option value={valWard.wardsID}>
                              {valWard.ward}
                            </option>
                          )
                        })}
                      </select>
                      {/*<!--</div>-->*/}
                    </div>
                    {/* <!--Grid column -->*/}
                    <div
                      class='col-lg-4 col-md-12 mb-0.01'
                      style={{ display: 'none' }}
                      id='divShowWard'
                    ></div>
                    {/*<!--\Grid column-->*/}

                    {/* <!--Grid column-->	*/}
                    <div class='col-lg-3 col-md-12 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Zone<span class='red-text'>*</span>
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          Placeholder=''
                          value={zone}
                          onChange={e => setzone(e.target.value)}
                          id='zone'
                          required
                        />
                      </div>
                    </div>
                    {/*<!--\Grid column-->	*/}
                    {/* <!--Grid column-->	*/}
                    <div class='col-lg-5 col-md-12 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Postal Address<span class='red-text'>*</span>
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          Placeholder=''
                          value={postalAddress}
                          onChange={e => setpostalAddress(e.target.value)}
                          id='postalAddress'
                          required
                        />
                      </div>
                    </div>
                    {/* <!--\Grid column-->	*/}
                  </div>
                  {/*<!--Grid row .-->*/}
                  <center>
                    <h4>
                      <u> VULNERABILITY STATUS </u>
                    </h4>
                  </center>
                  {/*<!--Grid row-->		*/}
                  <div class='row'>
                    {/*	<!--Grid column-->*/}
                    <div class='col-md-12 mb-0.001 text-left'>
                      {/* <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        {' '}
                        Status<span class='red-text'>*</span>
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        value={vulnerabilityStatus}
                        onChange={e => setvulnerabilityStatus(e.target.value)}
                        id='vulnerabilityStatus'
                        required
                      >
                        <option value=''>Choose...</option>
                        <option>Single Orphan</option>
                        <option>Double Orphan</option>
                        <option>Other</option>
                      </select>
                      {/*<!--</div>--> */}
                    </div>
                    {/* <!--\Grid column-->	*/}
                    {/*<!--Grid column--> */}
                    <div
                      class='col-md-12 mb-0.001'
                      id='hideVulnerabilityStatus'
                    >
                      {/*<!-- <div class="md-form">--> */}
                      {vulnerabilityStatus != 'Other' ||
                      vulnerabilityStatus === '' ? (
                        <>
                          <label for='registerFullName'>
                            <span class='red-text'></span>
                          </label>
                          <input
                            type='text'
                            class='form-control'
                            Placeholder='If Other, Please Specify'
                            disabled
                          />{' '}
                        </>
                      ) : (
                        <></>
                      )}
                      {/*<!--</div>--> */}
                    </div>
                    {/*<!--\Grid column-->	*/}
                    {vulnerabilityStatus === 'Other' ? (
                      <>
                        {/*<!--Grid column--> */}
                        <div
                          class='col-md-12 mb-0.001 text-left'
                          id='showVulnerabilityStatus'
                        >
                          <div class='md-form'>
                            <label for='registerFullName'>
                              Please Specify Vulnerability
                              <span class='red-text'>*</span>
                            </label>
                            <br />
                            <input
                              type='text'
                              class='form-control'
                              Placeholder=''
                              value={pleaseSpecifyVulnerability}
                              onChange={e =>
                                setpleaseSpecifyVulnerability(e.target.value)
                              }
                              id='pleaseSpecifyVulnerability'
                              required
                            />
                          </div>
                        </div>
                        {/*<!--\Grid column-->	*/}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  {/*<!--Grid row .--> */}
                  {/* <!--Grid row-->	*/}
                  <div class='row'>
                    {/* 	<!--Grid column--> */}
                    <div class='col-md-12 mb-0.001 text-left'>
                      {/*  <!--<div class="md-form">--> */}
                      <label for='registerFullName'>
                        Are you disabled? <span class='red-text'>*</span>
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        value={areYouDisabled}
                        onChange={e => setareYouDisabled(e.target.value)}
                        id='areYouDisabled'
                        required
                      >
                        <option value=''>Choose...</option>
                        <option>Yes</option>
                        <option>No</option>
                      </select>
                      {/* 	<!--</div>--> */}
                    </div>
                    {/*  <!--\Grid column-->	*/}
                    {/* 	<!--Grid column--> */}
                    <div class='col-md-12 mb-0.001' id='hideAreYouDisabled'>
                      {/*  <!-- <div class="md-form">--> */}
                      {areYouDisabled === 'No' || areYouDisabled === '' ? (
                        <>
                          <label for='registerFullName'>
                            <span class='red-text'></span>
                          </label>
                          <input
                            type='text'
                            class='form-control'
                            Placeholder='if yes, specify nature of disability'
                            disabled
                          />
                        </>
                      ) : (
                        <></>
                      )}
                      {/* 	<!--</div>-->*/}
                    </div>
                    {/* <!--\Grid column-->		*/}
                    {/* <!--Grid column-->*/}
                    {areYouDisabled === 'Yes' ? (
                      <>
                        <div class='col-md-12 mb-0.001' id='showAreYouDisabled'>
                          <div class='md-form'>
                            <label for='registerFullName'>
                              Please Specify Vulnerability
                              <span class='red-text'>*</span>
                            </label>{' '}
                            <br />
                            <input
                              type='text'
                              class='form-control'
                              Placeholder=''
                              value={pleaseSpecifyNatureOfVulnerability}
                              onChange={e =>
                                setpleaseSpecifyNatureOfVulnerability(
                                  e.target.value
                                )
                              }
                              id='pleaseSpecifyNatureOfVulnerability'
                              required
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    {/* <!--\Grid column-->	*/}
                  </div>
                  {/* <!--Grid row .-->*/}
                  {/*<!--Grid row-->			*/}
                  <div class='row'>
                    {/*	<!--Grid column-->*/}
                    <div class='col-md-12 mb-0.001 text-left'>
                      {/* <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Do You Have Financial Challenge?{' '}
                        <span class='red-text'>*</span>
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        value={doYouFinancialChallenge}
                        onChange={e =>
                          setdoYouFinancialChallenge(e.target.value)
                        }
                        id='doYouFinancialChallenge'
                        required
                      >
                        <option value=''>Choose...</option>
                        <option>Yes</option>
                        <option>No</option>
                      </select>
                      {/*	<!--</div>-->*/}
                    </div>
                    {/*<!--\Grid column-->	*/}
                    {/*	<!--Grid column-->*/}
                    {doYouFinancialChallenge === 'No' ||
                    doYouFinancialChallenge === '' ? (
                      <>
                        <div
                          class='col-md-12 mb-0.001'
                          id='hideDoYouFinancialChallenge'
                        >
                          {/* <!-- <div class="md-form">-->*/}
                          <label for='registerFullName'>
                            <span class='red-text'></span>
                          </label>
                          <input
                            type='text'
                            class='form-control'
                            Placeholder='If Yes, Specify Financial Challenge'
                            disabled
                          />
                          {/*		<!--</div>-->*/}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    {/*<!--\Grid column-->		*/}
                    {/*<!--Grid column-->*/}

                    {doYouFinancialChallenge === 'Yes' ? (
                      <>
                        <div
                          class='col-md-12 mb-0.001'
                          id='showDoYouFinancialChallenge'
                        >
                          <div class='md-form'>
                            <label for='registerFullName'>
                              Please Specify Financial Challenge
                              <span class='red-text'>*</span>
                            </label>{' '}
                            <br />
                            <input
                              type='text'
                              class='form-control'
                              Placeholder=''
                              value={pleaseSpecifyFinancialChallenge}
                              onChange={e =>
                                setpleaseSpecifyFinancialChallenge(
                                  e.target.value
                                )
                              }
                              id='pleaseSpecifyFinancialChallenge'
                              required
                            />
                          </div>
                        </div>{' '}
                      </>
                    ) : (
                      <></>
                    )}
                    {/*<!--\Grid column-->	*/}
                  </div>
                  {/*<!--Grid row .-->*/}
                  <div class='card-header' style={{ backgroundColor: 'green' }}>
                    <center>
                      <h6 class='white-text'>
                        PART B: SCHOOL AND COURSE DETAILS{' '}
                      </h6>
                    </center>
                  </div>
                  {/*<!--Grid row-->*/}
                  <div class='row'>
                    {/* <!--Grid column-->	*/}
                    <div class='col-md-3 mb-1'>
                      <label for='registerFullName'>
                        <span class='red-text'></span>
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        value={SchoolLeaverOrNot}
                        onChange={e => setSchoolLeaverOrNot(e.target.value)}
                        id='SchoolLeaverOrNot'
                        required
                      >
                        <option>School Leaver</option>
                        <option>Non School Leaver</option>
                      </select>
                    </div>
                    {/* <!--\Grid column-->	*/}

                    {/* <!--Grid column-->	*/}
                    <div class='col-md-3 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          <span class='red-text'></span>
                        </label>
                        <input
                          type='text'
                          class='form-control'
                          Placeholder='Last Grade Attended'
                          value={lastGradeAttended}
                          onChange={e => setlastGradeAttended(e.target.value)}
                          id='lastGradeAttended'
                          required
                        />
                      </div>
                    </div>
                    {/* <!--\Grid column-->*/}
                    {/*<!--Grid column-->	*/}
                    <div class='col-md-3 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          <span class='red-text'></span>
                        </label>
                        <input
                          type='text'
                          class='form-control'
                          Placeholder='Last School Attended'
                          value={lastSchoolAttended}
                          onChange={e => setlastSchoolAttended(e.target.value)}
                          id='lastSchoolAttended'
                          required
                        />
                      </div>
                    </div>
                    {/*<!--\Grid column-->	*/}
                    {/*<!--Grid column-->	*/}
                    <div class='col-md-3 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          <span class='red-text'></span>
                        </label>
                        <input
                          type='text'
                          class='form-control'
                          Placeholder='District'
                          value={district}
                          onChange={e => setdistrict(e.target.value)}
                          id='district'
                          required
                        />
                      </div>
                    </div>
                    {/* <!--\Grid column-->		*/}
                  </div>
                  {/*<!--\Grid row-->   */}
                  {/*<!--Grid row-->*/}
                  <div class='row'>
                    {/*<!--Grid column-->	*/}
                    <div class='col-md-3 mb-1 text-left'>
                      {/* <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        From Date<span class='red-text'></span>
                      </label>
                      <input
                        type='date'
                        class='form-control'
                        Placeholder=''
                        value={fromDate}
                        onChange={e => setfromDate(e.target.value)}
                        id='fromDate'
                        required
                      />
                      {/*<!--</div>-->*/}
                    </div>
                    {/*<!--\Grid column-->*/}
                    {/*<!--Grid column-->	*/}
                    <div class='col-md-3 mb-1 text-left'>
                      {/* <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        To Date<span class='red-text'></span>
                      </label>
                      <input
                        type='date'
                        class='form-control'
                        Placeholder=''
                        value={toDate}
                        onChange={e => settoDate(e.target.value)}
                        id='toDate'
                        required
                      />
                      {/*<!--</div>-->*/}
                    </div>
                    {/*<!--\Grid column-->	*/}
                    {/*<!--Grid column-->	*/}
                    <div class='col-md-6 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          <span class='red-text'></span>
                        </label>
                        <input
                          type='text'
                          class='form-control'
                          Placeholder='Highest Certificate Attained'
                          value={highestCertificateAttained}
                          onChange={e =>
                            sethighestCertificateAttained(e.target.value)
                          }
                          id='highestCertificateAttained'
                          required
                        />
                      </div>
                    </div>
                    {/*<!--\Grid column-->		*/}
                  </div>
                  {/*<!--\Grid row-->*/}
                  {/*<!--Grid row-->	*/}
                  <div class='row'>
                    {/*	<!--Grid column-->*/}
                    <div class='col-md-12 mb-0.001 text-left'>
                      {/* <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Have You Received An Acceptance Letter?
                        <span class='red-text'>*</span>
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        value={haveYouReceivedAnAcceptanceLetter}
                        onChange={e =>
                          sethaveYouReceivedAnAcceptanceLetter(e.target.value)
                        }
                        id='haveYouReceivedAnAcceptanceLetter'
                        required
                      >
                        <option value=''>Choose...</option>
                        <option>Yes</option>
                        <option>No</option>
                      </select>
                      {/*	<!--</div>-->*/}
                    </div>
                    {/*<!--\Grid column-->	*/}
                    {/*	<!--Grid column-->*/}
                    {haveYouReceivedAnAcceptanceLetter === 'No' ||
                    haveYouReceivedAnAcceptanceLetter === '' ? (
                      <>
                        <div
                          class='col-md-12 mb-0.001 text-left'
                          id='hideHaveYouReceivedAnAcceptanceLetter'
                        >
                          {/*<!-- <div class="md-form">-->*/}
                          <label for='registerFullName'>
                            If Your Answer Above Is Yes, Name The Institution
                            Where You Have Been Accepted
                            <span class='red-text'></span>
                          </label>
                          <input
                            type='text'
                            class='form-control'
                            Placeholder=''
                            disabled
                          />
                          {/*	<!--</div>-->*/}
                        </div>{' '}
                      </>
                    ) : (
                      <></>
                    )}
                    {/*<!--\Grid column-->	*/}
                    {/*<!--Grid column-->*/}

                    {haveYouReceivedAnAcceptanceLetter === 'Yes' ? (
                      <>
                        <div
                          class='col-md-12 mb-0.001'
                          id='showHaveYouReceivedAnAcceptanceLetter'
                        >
                          <div class='md-form'>
                            <label for='registerFullName'>
                              Name The Institution Where You Have Been Accepted
                              <span class='red-text'>*</span>
                            </label>{' '}
                            <br />
                            <input
                              type='text'
                              class='form-control'
                              Placeholder=''
                              value={nameTheInstitutionWhereYouHaveBeenAccepted}
                              onChange={e =>
                                setnameTheInstitutionWhereYouHaveBeenAccepted(
                                  e.target.value
                                )
                              }
                              id='nameTheInstitutionWhereYouHaveBeenAccepted'
                              required
                            />
                          </div>
                        </div>{' '}
                      </>
                    ) : (
                      <></>
                    )}
                    {/*<!--\Grid column-->	*/}
                  </div>
                  {/*<!--Grid row .-->*/}
                  {/*<!--Grid row-->			*/}
                  <div class='row'>
                    {/*<!--Grid column-->	*/}
                    <div class='col-lg-8 col-md-12 mb-1 text-left'>
                      {/*<div class="md-form">*/}
                      <label for='registerFullName'>
                        What Programme Of Study Do You Wish To Pursue?
                        <span class='red-text'></span>
                      </label>
                      <input
                        type='text'
                        class='form-control'
                        Placeholder=''
                        value={whatProgrammeOfStudyDoYouWishToPursue}
                        onChange={e =>
                          setwhatProgrammeOfStudyDoYouWishToPursue(
                            e.target.value
                          )
                        }
                        id='whatProgrammeOfStudyDoYouWishToPursue'
                      />
                      {/*</div>*/}
                    </div>
                    {/*<!--\Grid column-->	*/}

                    {/*<!--Grid column-->	*/}
                    <div class='col-lg-4 col-md-12 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Duration Of Programme<span class='red-text'></span>
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          Placeholder=''
                          value={durationOfProgramme}
                          onChange={e => setdurationOfProgramme(e.target.value)}
                          id='durationOfProgramme'
                        />
                      </div>
                    </div>
                    {/*<!--\Grid column-->	*/}
                  </div>
                  {/* <!--\Grid row-->*/}
                  {/*<!--Grid row-->		*/}
                  <div class='row'>
                    {/*	<!--Grid column-->*/}
                    <div class='col-md-12 mb-0.001 text-left'>
                      {/*<!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Have You Applied For Or Received Any Scholarship,
                        Bursary From Any Other Organizations Or Authority
                        Before?<span class='red-text'>*</span>
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        value={haveYouAppliedForAnyScholarshipBefore}
                        onChange={e =>
                          sethaveYouAppliedForAnyScholarshipBefore(
                            e.target.value
                          )
                        }
                        id='haveYouAppliedForAnyScholarshipBefore'
                        required
                      >
                        <option value=''>Choose...</option>
                        <option>Yes</option>
                        <option>No</option>
                      </select>
                      {/*	<!--</div>-->*/}
                    </div>
                    {/*<!--\Grid column-->	*/}
                    {/*	<!--Grid column-->*/}
                    {haveYouAppliedForAnyScholarshipBefore === 'No' ||
                    haveYouAppliedForAnyScholarshipBefore === '' ? (
                      <>
                        {' '}
                        <div
                          class='col-md-12 mb-0.001 text-left'
                          id='hideHaveYouAppliedForAnyScholarshipBefore'
                        >
                          {/*<!-- <div class="md-form">-->*/}
                          <label for='registerFullName'>
                            If Your Answer Above Was Yes, Give Details
                            <span class='red-text'></span>
                          </label>
                          <input
                            type='text'
                            class='form-control'
                            Placeholder=''
                            disabled
                          />
                          {/*	<!--</div>-->*/}
                        </div>{' '}
                      </>
                    ) : (
                      <></>
                    )}
                    {/*<!--\Grid column-->			*/}
                    {/*<!--Grid column-->*/}
                    {haveYouAppliedForAnyScholarshipBefore === 'Yes' ? (
                      <>
                        <div
                          class='col-md-12 mb-0.001 text-left'
                          id='showHaveYouAppliedForAnyScholarshipBefore'
                        >
                          {/*<div class="md-form">*/}
                          <label for='registerFullName'>
                            Name The Institution Where You Have Been Accepted
                            <span class='red-text'>*</span>
                          </label>
                          <input
                            type='text'
                            class='form-control'
                            Placeholder=''
                            value={AnyScholarshipBeforeDetails}
                            onChange={e =>
                              setAnyScholarshipBeforeDetails(e.target.value)
                            }
                            id='AnyScholarshipBeforeDetails'
                            required
                          />
                          {/*</div>*/}
                        </div>{' '}
                      </>
                    ) : (
                      <></>
                    )}
                    {/*<!--\Grid column-->	*/}
                  </div>
                  {/*<!--Grid row .-->*/}
                  {/*<!--Grid row-->		*/}
                  <div class='row'>
                    {/*<!--Grid column-->*/}
                    <div class='col-md-12 mb-0.001 text-left'>
                      {/*<!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Have You Ever Benefited From The CDF Skills Development
                        Bursary Sponsorship Before?
                        <span class='red-text'>*</span>
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        value={haveYouEverBenefitedFromTheCDFBursaryBefore}
                        onChange={e =>
                          sethaveYouEverBenefitedFromTheCDFBursaryBefore(
                            e.target.value
                          )
                        }
                        id='haveYouEverBenefitedFromTheCDFBursaryBefore'
                        required
                      >
                        <option value=''>Choose...</option>
                        <option>Yes</option>
                        <option>No</option>
                      </select>
                      {/*<!--</div>-->*/}
                    </div>
                    {/*<!--\Grid column-->	*/}
                    {/*<!--Grid column-->*/}
                    {haveYouEverBenefitedFromTheCDFBursaryBefore === 'No' ||
                    haveYouEverBenefitedFromTheCDFBursaryBefore === '' ? (
                      <>
                        {' '}
                        <div
                          class='col-md-12 mb-0.001 text-left'
                          id='hideHaveYouEverBenefitedFromTheCDFBursaryBefore'
                        >
                          {/*<!-- <div class="md-form">-->*/}
                          <label for='registerFullName'>
                            If Your Answer Above Was Yes, Give Details Of When
                            And How You Benefited
                            <span class='red-text'></span>
                          </label>
                          <input
                            type='text'
                            class='form-control'
                            Placeholder=''
                            disabled
                          />
                          {/*<!--</div>-->*/}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    {/*<!--\Grid column-->	*/}
                    {/*<!--Grid column-->*/}
                    {haveYouEverBenefitedFromTheCDFBursaryBefore === 'Yes' ? (
                      <>
                        <div
                          class='col-md-12 mb-0.001 text-left'
                          id='showHaveYouEverBenefitedFromTheCDFBursaryBefore'
                        >
                          {/*<div class="md-form">*/}
                          <label for='registerFullName'>
                            Give Details Of When And How You Benefited
                            <span class='red-text'>*</span>
                          </label>
                          <input
                            type='text'
                            class='form-control'
                            Placeholder=''
                            value={
                              detailsOfHowYouBenefitedFromTheCDFBursaryBefore
                            }
                            onChange={e =>
                              setdetailsOfHowYouBenefitedFromTheCDFBursaryBefore(
                                e.target.value
                              )
                            }
                            id='detailsOfHowYouBenefitedFromTheCDFBursaryBefore'
                            required
                          />
                          {/*</div>*/}
                        </div>{' '}
                      </>
                    ) : (
                      <></>
                    )}
                    {/*<!--\Grid column-->	*/}
                  </div>
                  {/*<!--Grid row .-->*/}
                  <br />{' '}
                  <div class='card-header' style={{ backgroundColor: 'green' }}>
                    <center>
                      <h6 class='white-text'>
                        PART C: PERSONAL DETAILS OF PARENT/GUARDIAN{' '}
                      </h6>
                    </center>
                  </div>
                  {/* <!--Grid row-->	*/}
                  <div class='row'>
                    {/*<!--Grid column-->		*/}
                    <div class='col-lg-2 col-md-12 mb-0.001'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Surname<span class='red-text'>*</span>{' '}
                        </label>
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          placeholder=''
                          value={parentSurname}
                          onChange={e => setparentSurname(e.target.value)}
                          id='parentSurname'
                          required
                        />
                      </div>
                    </div>

                    {/*<!--\Grid column-->		*/}

                    {/*<!--Grid column-->	*/}
                    <div class='col-lg-4 col-md-12 mb-0.001'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Other Names<span class='red-text'>*</span>{' '}
                        </label>
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          placeholder=''
                          value={parentOtherNames}
                          onChange={e => setparentOtherNames(e.target.value)}
                          id='parentOtherNames'
                          required
                        />
                      </div>
                    </div>
                    {/*<!--\Grid column-->		*/}
                    {/*<!--Grid column-->*/}
                    <div class='col-lg-2 col-md-2 mb-0.001 text-file'>
                      <label for='registerFullName'>
                        Gender<span class='red-text'></span>
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        value={parentGender}
                        onChange={e => setparentGender(e.target.value)}
                        id='parentGender'
                        required
                      >
                        <option value=''>Choose...</option>
                        <option>Male</option>
                        <option>Female</option>
                      </select>
                    </div>
                    {/*<!--\Grid column-->		*/}

                    {/*<!--Grid column-->	*/}
                    <div class='col-lg-4 col-md-12 mb-0.001 text-left'>
                      {/*	<!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Date Of Birth<span class='red-text'>*</span>{' '}
                      </label>
                      <input
                        type='date'
                        class='form-control'
                        placeholder=''
                        value={parentDob}
                        onChange={e => setparentDob(e.target.value)}
                        id='parentDob'
                        required
                      />
                      {/*<!--</div>-->	*/}
                    </div>
                    {/*<!--\Grid column-->	*/}
                  </div>
                  {/*<!--Grid row-->	*/}
                  {/*<!--Grid row-->		*/}
                  <div class='row'>
                    {/*<!--Grid column-->	*/}
                    <div class='col-lg-3 col-md-12 mb-0.001'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Nationality<span class='red-text'>*</span>{' '}
                        </label>
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          placeholder=''
                          value={parentNationality}
                          onChange={e => setparentNationality(e.target.value)}
                          id='parentNationality'
                          required
                        />
                      </div>
                    </div>
                    {/*<!--\Grid column-->*/}

                    {/*<!--Grid column-->	*/}
                    <div class='col-lg-2 col-md-12 mb-0.001'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          NRC Number<span class='red-text'>*</span>{' '}
                        </label>
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          placeholder=''
                          value={parentNRC}
                          onChange={e => setparentNRC(e.target.value)}
                          id='parentNRC'
                          required
                        />
                      </div>
                    </div>
                    {/*<!--\Grid column-->*/}

                    {/*<!--Grid column-->	*/}
                    <div class='col-lg-4 col-md-12 mb-0.001'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Relationship To Applicant
                          <span class='red-text'>*</span>{' '}
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          placeholder=''
                          value={parentRelationshipToApplicant}
                          onChange={e =>
                            setparentRelationshipToApplicant(e.target.value)
                          }
                          id='parentRelationshipToApplicant'
                          required
                        />
                      </div>
                    </div>
                    {/*<!--\Grid column-->*/}

                    {/*<!--Grid column-->	*/}
                    <div class='col-lg-3 col-md-12 mb-0.001'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Village<span class='red-text'>*</span>{' '}
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          placeholder=''
                          value={parentVillage}
                          onChange={e => setparentVillage(e.target.value)}
                          id='parentVillage'
                          required
                        />
                      </div>
                    </div>
                    {/*<!--\Grid column-->*/}
                  </div>
                  {/*<!--Grid row-->*/}
                  {/*<!--Grid row-->		*/}
                  <div class='row'>
                    {/*<!--Grid column-->		*/}
                    <div class='col-lg-2 col-md-12 mb-0.001'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Chief<span class='red-text'>*</span>{' '}
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          placeholder=''
                          value={parentChief}
                          onChange={e => setparentChief(e.target.value)}
                          id='parentChief'
                          required
                        />
                      </div>
                    </div>
                    {/*<!--\Grid column-->*/}

                    {/*<!--Grid column-->		*/}
                    <div class='col-lg-2 col-md-12 mb-0.001'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          District<span class='red-text'>*</span>{' '}
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          placeholder=''
                          value={parentDistrict}
                          onChange={e => setparentDistrict(e.target.value)}
                          id='parentDistrict'
                          required
                        />
                      </div>
                    </div>
                    {/*<!--\Grid column-->*/}

                    {/*<!--Grid column-->	*/}
                    <div class='col-lg-3 col-md-12 mb-0.001'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Residential Address<span class='red-text'>*</span>{' '}
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          placeholder=''
                          value={parentResidentialAddress}
                          onChange={e =>
                            setparentResidentialAddress(e.target.value)
                          }
                          id='parentResidentialAddress'
                          required
                        />
                      </div>
                    </div>
                    {/*<!--\Grid column-->*/}

                    {/*<!--Grid column-->	*/}
                    <div class='col-lg-3 col-md-12 mb-0.001'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Constituency<span class='red-text'>*</span>{' '}
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          placeholder=''
                          value={parentConstituency}
                          onChange={e => setparentConstituency(e.target.value)}
                          id='parentConstituency'
                          required
                        />
                      </div>
                    </div>
                    {/*<!--\Grid column-->*/}

                    {/*<!--Grid column-->		*/}
                    <div class='col-lg-2 col-md-12 mb-0.001'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Province<span class='red-text'>*</span>{' '}
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          placeholder=''
                          value={parentProvince}
                          onChange={e => setparentProvince(e.target.value)}
                          id='parentProvince'
                          required
                        />
                      </div>
                    </div>
                    {/*<!--\Grid column-->*/}
                  </div>
                  {/*<!--Grid row-->*/}
                  {/*<!--Grid row-->*/}
                  <div class='row'>
                    {/*<!--Grid column-->		*/}
                    <div class='col-lg-4 col-md-12 mb-0.001'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Postal Address<span class='red-text'>*</span>{' '}
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          placeholder=''
                          value={parentPostalAddress}
                          onChange={e => setparentPostalAddress(e.target.value)}
                          id='parentPostalAddress'
                          required
                        />
                      </div>
                    </div>
                    {/*<!--\Grid column-->		*/}

                    {/*<!--Grid column-->		*/}
                    <div class='col-lg-4 col-md-12 mb-0.001'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Mobile Phone Number<span class='red-text'></span>{' '}
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          placeholder=''
                          value={parentMobilePhoneNumber}
                          onChange={e =>
                            setparentMobilePhoneNumber(e.target.value)
                          }
                          id='parentMobilePhoneNumber'
                        />
                      </div>
                    </div>
                    {/*<!--\Grid column-->*/}

                    {/*<!--Grid column-->		*/}
                    <div class='col-lg-4 col-md-12 mb-0.001'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          E-mail Address<span class='red-text'></span>{' '}
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          placeholder=''
                          value={parentEmailAddress}
                          onChange={e => setparentEmailAddress(e.target.value)}
                          id='parentEmailAddress'
                        />
                      </div>
                    </div>
                    {/*<!--\Grid column-->*/}
                  </div>
                  {/*<!--\Grid row-->*/}
                  <div class='card-header' style={{ backgroundColor: 'green' }}>
                    <center>
                      <h6 class='white-text'>
                        {' '}
                        PART D: EMPLOYMENT DETAILS OF PARENT/GUARDIAN (WHERE
                        APPLICABLE){' '}
                      </h6>
                    </center>
                  </div>
                  <br />
                  <h6>
                    <center>OCCUPATION OF :</center>
                  </h6>
                  {/*  <!--Grid row-->*/}
                  <div class='row'>
                    {/*<!--Grid column-->		*/}
                    <div class='col-lg-4 col-md-12 mb-0.001'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Father<span class='red-text'>*</span>{' '}
                        </label>
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          placeholder=''
                          value={parentFatherOccupationOf}
                          onChange={e =>
                            setparentFatherOccupationOf(e.target.value)
                          }
                          id='parentFatherOccupationOf'
                          required
                        />
                      </div>
                    </div>
                    {/*<!--\Grid column-->	*/}
                    {/*<!--Grid column-->	*/}
                    <div class='col-lg-4 col-md-12 mb-0.001'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Mother<span class='red-text'>*</span>{' '}
                        </label>
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          placeholder=''
                          value={parentMotherOccupationOf}
                          onChange={e =>
                            setparentMotherOccupationOf(e.target.value)
                          }
                          id='parentMotherOccupationOf'
                          required
                        />
                      </div>
                    </div>
                    {/*<!--\Grid column-->*/}
                    {/*<!--Grid column-->		*/}
                    <div class='col-lg-4 col-md-12 mb-0.001'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Guardian<span class='red-text'></span>{' '}
                        </label>
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          placeholder=''
                          value={parentGuardianOccupationOf}
                          onChange={e =>
                            setparentGuardianOccupationOf(e.target.value)
                          }
                          id='parentGuardianOccupationOf'
                          required
                        />
                      </div>
                    </div>
                    {/*<!--\Grid column-->*/}
                  </div>
                  {/*<!--\Grid row-->*/}
                  <br />
                  <h6>
                    <center>NAME OF EMPLOYER OF :</center>
                  </h6>
                  {/*  <!--Grid row-->*/}
                  <div class='row'>
                    {/*<!--Grid column-->		*/}
                    <div class='col-lg-4 col-md-12 mb-0.001'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Father<span class='red-text'></span>{' '}
                        </label>
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          placeholder=''
                          value={parentFatherNameOfEmployerOf}
                          onChange={e =>
                            setparentFatherNameOfEmployerOf(e.target.value)
                          }
                          id='parentFatherNameOfEmployerOf'
                        />
                      </div>
                    </div>
                    {/*<!--\Grid column-->	*/}
                    {/*<!--Grid column-->	*/}
                    <div class='col-lg-4 col-md-12 mb-0.001'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Mother<span class='red-text'></span>{' '}
                        </label>
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          placeholder=''
                          value={parentMotherNameOfEmployerOf}
                          onChange={e =>
                            setparentMotherNameOfEmployerOf(e.target.value)
                          }
                          id='parentMotherNameOfEmployerOf'
                        />
                      </div>
                    </div>
                    {/*<!--\Grid column-->*/}
                    {/*<!--Grid column-->		*/}
                    <div class='col-lg-4 col-md-12 mb-0.001'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Guardian<span class='red-text'></span>{' '}
                        </label>
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          placeholder=''
                          value={parentGuardianNameOfEmployerOf}
                          onChange={e =>
                            setparentGuardianNameOfEmployerOf(e.target.value)
                          }
                          id='parentGuardianNameOfEmployerOf'
                        />
                      </div>
                    </div>
                    {/*<!--\Grid column-->*/}
                  </div>
                  {/*<!--\Grid row-->*/}
                  <br />
                  <h6>
                    <center>POSITION/ RANK OF :</center>
                  </h6>
                  {/* <!--Grid row-->*/}
                  <div class='row'>
                    {/*<!--Grid column-->		*/}
                    <div class='col-lg-4 col-md-12 mb-0.001'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Father<span class='red-text'></span>{' '}
                        </label>
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          placeholder=''
                          value={parentFatherPosition}
                          onChange={e =>
                            setparentFatherPosition(e.target.value)
                          }
                          id='parentFatherPosition'
                        />
                      </div>
                    </div>
                    {/*<!--\Grid column-->	*/}
                    {/*<!--Grid column-->	*/}
                    <div class='col-lg-4 col-md-12 mb-0.001'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Mother<span class='red-text'></span>{' '}
                        </label>
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          placeholder=''
                          value={parentMotherPosition}
                          onChange={e =>
                            setparentMotherPosition(e.target.value)
                          }
                          id='parentMotherPosition'
                        />
                      </div>
                    </div>
                    {/*<!--\Grid column-->*/}
                    {/*<!--Grid column-->		*/}
                    <div class='col-lg-4 col-md-12 mb-0.001'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Guardian<span class='red-text'></span>{' '}
                        </label>
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          placeholder=''
                          value={parentGuardianPosition}
                          onChange={e =>
                            setparentGuardianPosition(e.target.value)
                          }
                          id='parentGuardianPosition'
                        />
                      </div>
                    </div>
                    {/*<!--\Grid column-->*/}
                  </div>
                  {/*	<!--\Grid row-->*/}
                  <br />
                  <h6>
                    <center>ADDRESS OF EMPLOYER :</center>
                  </h6>
                  {/* <!--Grid row-->*/}
                  <div class='row'>
                    {/*<!--Grid column-->		*/}
                    <div class='col-lg-4 col-md-12 mb-0.001'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Father<span class='red-text'></span>{' '}
                        </label>
                        <br />
                        <br />
                        <textarea
                          class='form-control'
                          placeholder=''
                          value={parentFatherAddressOfEmployer}
                          onChange={e =>
                            setparentFatherAddressOfEmployer(e.target.value)
                          }
                          id='parentFatherAddressOfEmployer'
                        >
                          {' '}
                        </textarea>
                      </div>
                    </div>
                    {/*<!--\Grid column-->	*/}
                    {/*<!--Grid column-->		*/}
                    <div class='col-lg-4 col-md-12 mb-0.001'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Mother<span class='red-text'></span>{' '}
                        </label>
                        <br />
                        <br />
                        <textarea
                          class='form-control'
                          placeholder=''
                          value={parentMotherAddressOfEmployer}
                          onChange={e =>
                            setparentMotherAddressOfEmployer(e.target.value)
                          }
                          id='parentMotherAddressOfEmployer'
                        >
                          {' '}
                        </textarea>
                      </div>
                    </div>
                    {/*<!--\Grid column-->*/}
                    {/*<!--Grid column-->		*/}
                    <div class='col-lg-4 col-md-12 mb-0.001'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Guardian<span class='red-text'></span>{' '}
                        </label>
                        <br />
                        <br />
                        <textarea
                          class='form-control'
                          placeholder=''
                          value={parentGuardianAddressOfEmployer}
                          onChange={e =>
                            setparentGuardianAddressOfEmployer(e.target.value)
                          }
                          id='parentGuardianAddressOfEmployer'
                        >
                          {' '}
                        </textarea>
                      </div>
                    </div>
                    {/*<!--\Grid column-->*/}
                  </div>
                  {/*<!--\Grid row-->*/}
                  <div
                    class='card-header'
                    style={{ backgroundgColor: 'green !important' }}
                  >
                    <center>
                      <h6 class='white-text'> PART E: DECLARATION </h6>
                    </center>
                  </div>
                  {/*<!--Grid row-->*/}
                  <div class='row'>
                    {/* <!--Grid column-->	*/}
                    <div class='col-lg-12 col-md-12 mb-10 text-left'>
                      <br />I{' '}
                      <input
                        type='text'
                        placeholder='Indicate Name Here'
                        value={declarationApplicantName}
                        onChange={e =>
                          setdeclarationApplicantName(e.target.value)
                        }
                        id='declarationApplicantName'
                      />{' '}
                      of NRC number{' '}
                      <input
                        type='text'
                        placeholder='Indicate NRC Here'
                        value={declarationApplicantNRC}
                        onChange={e =>
                          setdeclarationApplicantNRC(e.target.value)
                        }
                        id='declarationApplicantNRC'
                      />{' '}
                      do declare that to the best of my knowledge, the
                      information I have given is the absolute truth. I also
                      understand that any false information on this form will
                      lead to immediate forfeiture of this assistance and
                      possible prosecution or both.
                    </div>
                    {/* <!--\Grid column-->		*/}
                  </div>
                  {/*<!--\Grid row-->*/}
                  <div class='row'>
                    <div class='col-lg-12 col-md-12 mb-4'>
                      <br />
                    </div>
                  </div>
                  {/* <!--Grid row-->*/}
                  <div class='row'>
                    {/* <!--Grid column-->	*/}
                    <div class='col-lg-3 col-md-12 mb-0.001'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Signature Of Applicant
                          <span class='red-text'>*</span>{' '}
                        </label>
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          placeholder=''
                          value={declarationSignatureOfApplicant}
                          onChange={e =>
                            setdeclarationSignatureOfApplicant(e.target.value)
                          }
                          id='declarationSignatureOfApplicant'
                          required
                        />
                      </div>
                    </div>
                    {/* <!--\Grid column-->	*/}
                    {/*<!--Grid column-->		*/}
                    <div class='col-lg-3 col-md-12 mb-0.001 text-left'>
                      {/* <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Date<span class='red-text'>*</span>{' '}
                      </label>
                      <input
                        type='date'
                        class='form-control'
                        placeholder=''
                        value={declarationDateOfApplicant}
                        onChange={e =>
                          setdeclarationDateOfApplicant(e.target.value)
                        }
                        id='declarationDateOfApplicant'
                        required
                      />
                      {/*   <!--</div>-->		*/}
                    </div>
                    {/*<!--\Grid column-->*/}
                    {/*<!--Grid column-->	*/}
                    <div class='col-lg-3 col-md-12 mb-0.001'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Signature Of Parent/Guardian
                          <span class='red-text'>*</span>{' '}
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          placeholder=''
                          value={declarationSignatureOfParent}
                          onChange={e =>
                            setdeclarationSignatureOfParent(e.target.value)
                          }
                          id='declarationSignatureOfParent'
                          required
                        />
                      </div>
                    </div>
                    {/*  <!--\Grid column-->*/}
                    {/* <!--Grid column-->	*/}
                    <div class='col-lg-3 col-md-12 mb-0.001 text-left'>
                      {/* <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Date<span class='red-text'>*</span>{' '}
                      </label>
                      <input
                        type='date'
                        class='form-control'
                        placeholder=''
                        value={declarationDateOfParent}
                        onChange={e =>
                          setdeclarationDateOfParent(e.target.value)
                        }
                        id='declarationDateOfParent'
                        required
                      />
                      {/*   <!--</div>	-->		*/}
                    </div>
                    {/* <!--\Grid column-->*/}
                  </div>
                  {/* <!--\Grid row-->*/}
                  <button
                    class='btn btn-success  btn-md btn-block mb-5 mt-3'
                    type='submit'
                    onClick={handleSubmit}
                    id='submit'
                  >
                    Submit
                  </button>
                </div>
                {/* <!--/.Card-- br >*/}
              </div>
            </div>
            {/* <!--Grid row-->*/}
          </div>
        </div>
      </div>
      {/*<!-- Main layout -->*/}
    </>
  )
}

export default SkillsDevelopmentBursaryApplicationForm
