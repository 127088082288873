import { useRef, useState, useEffect, useContext } from 'react'
import Header from './header'
const Main = () => {
  sessionStorage.removeItem('reloadCount')

  useEffect(() => {
    localStorage.removeItem('userID')
  }, [])

  return (
    <>
      <>
        <Header />
      </>
      <div>
        {/* Main Navigation */}
        <header>
          {/*<!-- Navbar -->*/}
          <nav
            class='navbar fixed-top navbar-expand-lg navbar-light scrolling-navbar green'
            style={{ backgroundColor: 'green !important' }}
          >
            <div class='container-fluid justify-content-center align-items-center'>
              <button
                class='navbar-toggler white-text'
                type='button'
                data-toggle='collapse'
                data-target='#navbarSupportedContent-4'
                aria-controls='navbarSupportedContent-4'
                aria-expanded='false'
                aria-label='Toggle navigation'
              >
                <span
                  class='navbar-toggler-icon'
                  style={{ backgroundColor: 'white !important' }}
                ></span>
              </button>
              <div
                class='collapse navbar-collapse justify-content-center'
                id='navbarSupportedContent-4'
              >
                <ul class='navbar-nav'>
                  <li class='nav-item ml-4 mb-0'>
                    <a
                      class='nav-link waves-effect waves-light font-weight-bold white-text'
                      href='#'
                    >
                      CONTACT US
                    </a>
                  </li>

                  <li class='nav-item ml-4 mb-0'>
                    <a
                      class='nav-link waves-effect waves-light font-weight-bold white-text'
                      href='#'
                    >
                      ABOUT US
                    </a>
                  </li>
                  <li class='nav-item ml-4 mb-0'>
                    <a
                      class='nav-link waves-effect waves-light font-weight-bold white-text'
                      href='Login'
                    >
                      LOGIN
                    </a>
                  </li>

                  <li class='nav-item dropdown ml-4  mb-0'>
                    <a
                      href='#'
                      class='nav-link dropdown-toggle waves-effect waves-light font-weight-bold white-text'
                      id='navbarDropdownMenuLink-4'
                      data-toggle='dropdown'
                      aria-haspopup='true'
                      aria-expanded='false'
                    >
                      FUND APPLICATION
                    </a>
                    <div
                      class='dropdown-menu dropdown-menu-right'
                      aria-labelledby='navbarDropdownMenuLink-4'
                    >
                      {/*<!--<a class="dropdown-item waves-effect waves-light" href="loan_agreement_form.php">LOAN AGREEMENT FORM</a>-->*/}
                      <a
                        class='dropdown-item waves-effect waves-light'
                        href='GrantApplicationFormForYouthWomenAndCommunityEmpowerment'
                      >
                        GRANT APPLICATION FORM FOR YOUTH, WOMEN AND COMMUNITY
                        EMPOWERMENT
                      </a>
                      <a
                        class='dropdown-item waves-effect waves-light'
                        href='Loan'
                      >
                        LOANS APPLICATION FORM
                      </a>
                      <a
                        class='dropdown-item waves-effect waves-light'
                        href='ApplyForCommunityProject'
                      >
                        APPLICATION FORM FOR COMMUNITY PROJECTS
                      </a>
                      <a
                        class='dropdown-item waves-effect waves-light'
                        href='SkillsDevelopmentBursaryApplicationForm'
                      >
                        SKILLS DEVELOPMENT BURSARY APPLICATION FORM
                      </a>
                      <a
                        class='dropdown-item waves-effect waves-light'
                        href='SecondaryBoardingSchoolBursaryApplicationForm'
                      >
                        SECONDARY BOARDING SCHOOL BURSARY APPLICATION FORM
                      </a>
                    </div>
                  </li>
                </ul>
              </div>

              <ul class='navbar-nav ml-auto nav-flex-icons'>
                <li class='nav-item'>
                  <a class='nav-link waves-effect waves-light'>
                    <i class='fab fa-twitter white-text'></i>
                  </a>
                </li>
                <li class='nav-item'>
                  <a class='nav-link waves-effect waves-light'>
                    <i class='fab fa-facebook white-text'></i>
                  </a>
                </li>
                <li class='nav-item'>
                  <a class='nav-link waves-effect waves-light'>
                    <i class='fab fa-instagram white-text'></i>
                  </a>
                </li>
              </ul>
            </div>
          </nav>
          {/*<!-- Navbar -->*/}

          <section>
            <div class=''>
              <img
                src={require('./img/3.jpg')}
                style={{ width: '100%', height: '20%' }}
              />
            </div>
          </section>
        </header>
        {/* Main Navigation */}

        {/* Main layout */}
        <div>
          <div class='container-fluid mt-md-0 mt-5 mb-5'>
            {/* <!-- Grid row -->*/}
            <div class='row' style={{ marginTop: '-100px' }}>
              {/*<!-- Grid column -->*/}
              <div class='col-md-12 px-lg-5'>
                {/*<!-- Card -->*/}
                <div class='card pb-5 mx-md-3'>
                  <div class='card-body'>
                    <div class='container'>
                      {/* <!-- Section heading -->*/}
                      <h3 class='text-center h3 pt-4 mb-3'>
                        <strong>
                          CONSTITUENCY DEVELOPMENT FUND - INFORMATION MANAGEMENT
                          SYSTEM
                        </strong>
                      </h3>

                      {/*<!-- Grid row -->*/}
                      <div class='row pt-lg-5 pt-3'>
                        {/* <!-- Grid column -->*/}
                        <div class='col-md-12 col-xl-12'>
                          {/* <!-- Grid row -->*/}
                          <div class=''>
                            <center>
                              <h6 class=''>
                                {' '}
                                <i
                                  class='fas fa-quote-left'
                                  aria-hidden='true'
                                ></i>{' '}
                                The CDF is established under Article 162 of the
                                Constitution of Zambia (Amendment) Act No.2 of
                                2016 and operationalised by the Constituency
                                Development Fund Act No. 11 of 2018.The Act
                                provides for the management, disbursement,
                                utilisation and accountability of the CDF.{' '}
                                <i
                                  class='fas fa-quote-right'
                                  aria-hidden='true'
                                ></i>
                              </h6>
                            </center>
                          </div>
                          {/* <!-- Grid row -->*/}

                          {/*<!-- Grid row -->*/}
                          <div class='row mb-2 mt-4'>
                            <p class='dark-grey-text article'>
                              The Government of the Republic of Zambia has
                              prioritised Decentralisation as a major driver for
                              attaining development, reducing poverty and
                              supporting job creation through citizen engagement
                              and participation at the local level. To actualise
                              decentralisation in line with the Constitution,
                              Government has decided to take significant
                              resources from the National to the Local
                              Government level. In this regard, the Constituency
                              Development Fund (CDF) has been identified as a
                              major tool to take resources closer to the people.
                              The CDF was first established in 1995 to support
                              micro-community projects as part of the wider
                              decentralization and local development policy. The
                              fundamental objective was to provide resources to
                              bridge the financing gap arising from
                              non-existence sources of funds to finance
                              micro-community led projects in all the
                              Constituencies across the Country. The first
                              appropriation and disbursement of this fund was
                              done in 1995 by Parliament. From 1995 to 2016, the
                              Fund was provided for under Section 45 of the
                              Local Government Act Cap 281 of the Laws of
                              Zambia. In 2016, the Constitution established the
                              Constituency Development Fund. Subsequently, the
                              Local Government Act was repealed and the
                              Constituency Development Fund Act was enacted in
                              2018 to provide for the management, disbursement,
                              utilisation and accountability of the Fund.
                              Government developed the first guidelines in 2006
                              commonly known as the “CDF Guidelines to guide on
                              the Management and Utilisation of the Fund”. The
                              Fund has since evolved with an expanded scope and
                              increased budget allocation with increased
                              emphasis on enhanced community participation in
                              determining local development priorities. In 2021,
                              Government increased the amount of the CDF from
                              K1.6 million to K25.7 million per Constituency.
                              The expanded scope of the CDF covers three (3)
                              specific areas namely; Community Projects; Youth,
                              Women and Community Empowerment and Secondary
                              Boarding School and Skills Development Bursaries.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*<!-- Grid row -->*/}
        </div>
      </div>
    </>
  )
}

export default Main
