import { useRef, useState, useEffect, useContext } from 'react'
import '../App.css'
import {
  faCheck,
  faTimes,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Axios from '../api/axios'
import $, { nodeName } from 'jquery'
import 'jquery/dist/jquery.min.js'
import { useNavigate } from 'react-router-dom'
import 'jquery/dist/jquery.min.js'
import 'datatables.net-dt/js/dataTables.dataTables'
import 'datatables.net-dt/css/jquery.dataTables.min.css'
import 'datatables.net-buttons/js/dataTables.buttons.js'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.flash.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'
//const USER_REGEX = /^[a-zAZ][a-zA-Z0-9_]{3,23}$/;
const QUANTITY_REGEX = /^\d{1,9}$/
//const PRICE_REGEX = /(?:^[1-9]([0-9]+)?(?:\.[0-9]{1,2})?$)|(?:^(?:0)$)|(?:^[0-9]\.[0-9](?:[0-9])?$)/ ;
const PRICE_REGEX = /^\d+(,\d{3})*(\.\d{2,2})?$/
//const PRICE_REGEX = /^(?!0+$)*[,\d{3}]*{0,10}$/;
//const EMAIL_REGEX = /^[^@]+@[^@]+\.[^@]{2,4}$/;

const ApplyForCommunityProject = () => {
  const server_app_api = window.SERVER_APP_API
  const server_app_api_php = window.SERVER_APP_API_PHP

  const [
    nameOfCommunityMakingTheProposal,
    setnameOfCommunityMakingTheProposal
  ] = useState('')
  const [province, setprovince] = useState('')
  const [provinceDropDown, setprovinceDropDown] = useState([])
  const [council, setcouncil] = useState('')
  const [councilDropDown, setcouncilDropDown] = useState([])
  const [constituency, setconstituency] = useState('')
  const [constituencyDropDown, setconstituencyDropDown] = useState([])
  const [ward, setward] = useState('')
  const [wardDropDown, setwardDropDown] = useState([])
  const [zone, setzone] = useState('')
  const [landTitle, setlandTitle] = useState('')
  const [typeOfProject, settypeOfProject] = useState('')
  const [
    estimatedPopulationBenefitingFromTheProject,
    setestimatedPopulationBenefitingFromTheProject
  ] = useState('')
  const [communityBasedProjectBefore, setcommunityBasedProjectBefore] =
    useState('')
  const [
    communityBasedProjectBeforeYesReason,
    setcommunityBasedProjectBeforeYesReason
  ] = useState('')
  const [fundingFromAnyOrganization, setfundingFromAnyOrganization] =
    useState('')
  const [
    fundingFromAnyOrganizationYesReason,
    setfundingFromAnyOrganizationYesReason
  ] = useState('')
  const [mainProblemsInTheArea, setmainProblemsInTheArea] = useState('')
  const [
    problemsIsTheProposedProjectTryingToAddress,
    setproblemsIsTheProposedProjectTryingToAddress
  ] = useState('')
  const [
    howDidTheCommunityIdentifyTheProject,
    sethowDidTheCommunityIdentifyTheProject
  ] = useState('')
  const [
    doYouWantToPasteTheMinutesHereOrYouWantToUploadThemOnAfile,
    setdoYouWantToPasteTheMinutesHereOrYouWantToUploadThemOnAfile
  ] = useState('')
  const [
    projectIdentificationMinutesFile,
    setProjectIdentificationMinutesFile
  ] = useState('')
  const [projectIdentificationMinutes, setprojectIdentificationMinutes] =
    useState('')
  const [
    hasAnythingBeenDoneBeforeToAddressTheProblemAndIfAny,
    sethasAnythingBeenDoneBeforeToAddressTheProblemAndIfAny
  ] = useState('')
  const [
    hasAnythingBeenDoneBeforeToAddressTheProblemExplanation,
    sethasAnythingBeenDoneBeforeToAddressTheProblemExplanation
  ] = useState('')
  const [
    stateTheBeneficiariesOfTheProposedProject,
    setstateTheBeneficiariesOfTheProposedProject
  ] = useState('')
  const [
    whatIsTheEstimatedCostOfTheProject,
    setwhatIsTheEstimatedCostOfTheProject
  ] = useState('')
  const [
    howWillTheCommunityContributeTowardsTheCostOfTheProject,
    sethowWillTheCommunityContributeTowardsTheCostOfTheProject
  ] = useState('')
  const [
    typeOrUploadProjectCommitteemembers,
    settypeOrUploadProjectCommitteemembers
  ] = useState('')
  //const [typeOrUploadProjectCommitteemembers, settypeOrUploadProjectCommitteemembers] = useState('');
  const [name, setname] = useState([{}])
  const [position, setposition] = useState([{}])
  const [sex, setsex] = useState([{}])
  const [nrc, setnrc] = useState([{}])
  const [inputList, setInputList] = useState([
    { name: '', position: '', sex: '', nrc: '' }
  ])
  var nameArrCommunityProjects = []
  var positionArrCommunityProjects = []
  var sexArrCommunityProjects = []
  var nrcArrCommunityProjects = []
  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target
    const list = [...inputList]
    list[index][name] = value
    setInputList(list)

    list.map(val => {
      nameArrCommunityProjects.push(val.name)
      positionArrCommunityProjects.push(val.position)
      sexArrCommunityProjects.push(val.sex)
      nrcArrCommunityProjects.push(val.nrc)
    })
    localStorage.setItem('name', JSON.stringify(nameArrCommunityProjects))
    localStorage.setItem(
      'position',
      JSON.stringify(positionArrCommunityProjects)
    )
    localStorage.setItem('sex', JSON.stringify(sexArrCommunityProjects))
    localStorage.setItem('nrc', JSON.stringify(nrcArrCommunityProjects))
    //setItemID(JSON.stringify(rodeen))
    setname(JSON.stringify(nameArrCommunityProjects))
    setposition(JSON.stringify(positionArrCommunityProjects))
    setsex(JSON.stringify(sexArrCommunityProjects))
    setnrc(JSON.stringify(nrcArrCommunityProjects))
  }
  // handle click event of the Remove button
  const handleRemoveClick = index => {
    const list = [...inputList]
    list.splice(index, 1)
    setInputList(list)

    ///var rodeens = []
    list.map(val => {
      nameArrCommunityProjects.push(val.name)
      positionArrCommunityProjects.push(val.position)
      sexArrCommunityProjects.push(val.sex)
      nrcArrCommunityProjects.push(val.nrc)
    })
    localStorage.setItem('name', JSON.stringify(nameArrCommunityProjects))
    localStorage.setItem(
      'position',
      JSON.stringify(positionArrCommunityProjects)
    )
    localStorage.setItem('sex', JSON.stringify(sexArrCommunityProjects))
    localStorage.setItem('nrc', JSON.stringify(nrcArrCommunityProjects))
  }

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { name: '', position: '', sex: '', nrc: '' }])
  }

  const [projectCommitteeMembersFile, setProjectCommitteeMembersFile] =
    useState('')
  const [
    howWillTheCommunityEnsureProjectSustainability,
    sethowWillTheCommunityEnsureProjectSustainability
  ] = useState('')
  const [ProjectProposerName, setProjectProposerName] = useState('')
  const [ProjectProposerNRC, setProjectProposerNRC] = useState('')
  const [ProjectProposerAddress, setProjectProposerAddress] = useState('')
  const [ProjectProposerPhone, setProjectProposerPhone] = useState('')
  const [ProjectProposerDate, setProjectProposerDate] = useState('')
  const [SeconderName, setSeconderName] = useState('')
  const [SeconderNRC, setSeconderNRC] = useState('')
  const [SeconderAddress, setSeconderAddress] = useState('')
  const [SeconderPhone, setSeconderPhone] = useState('')
  const [SeconderDate, setSeconderDate] = useState('')

  const saveProjectIdentificationMinutesFile = e => {
    setProjectIdentificationMinutesFile(e.target.files[0])
  }
  const saveProjectCommitteeMembersFile = e => {
    setProjectCommitteeMembersFile(e.target.files[0])
  }

  useEffect(() => {
    localStorage.removeItem('userID')
  }, [])
  useEffect(() => {
    var formDataViewProvinces = new FormData(this)
    formDataViewProvinces.append('apiType', 'getViewProvincesDropDown')
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataViewProvinces,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setprovinceDropDown(response.data)
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [])

  useEffect(() => {
    var formDataViewCouncils = new FormData(this)
    formDataViewCouncils.append('apiType', 'getViewCouncilsDropDown')
    formDataViewCouncils.append('provincesID', province)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataViewCouncils,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(res => {
      setcouncilDropDown(res.data)
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [province]) //councilDropDown

  useEffect(() => {
    var formDataViewConstituencies = new FormData(this)
    formDataViewConstituencies.append(
      'apiType',
      'getViewConstituenciesDropDown'
    )
    formDataViewConstituencies.append('councilsID', council)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataViewConstituencies,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(res => {
      setconstituencyDropDown(res.data)
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [province, council])

  useEffect(() => {
    var formDataViewWards = new FormData(this)
    formDataViewWards.append('apiType', 'getViewWardsDropDown')
    formDataViewWards.append('constituenciesID', constituency)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataViewWards,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(res => {
      setwardDropDown(res.data)
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [province, council, constituency])

  const handleSubmit = async e => {
    var formData = new FormData(this)
    //formData.append('apiType', 'getViewWardsDropDown')
    //formData.append('constituenciesID', constituency)

    //Axios.post('http://localhost:3001/api/uploadApplyForCommunityProject', {
    formData.append('apiType', 'uploadApplyForCommunityProject')
    formData.append(
      'nameOfCommunityMakingTheProposal',
      nameOfCommunityMakingTheProposal
    )
    formData.append('province', province)
    formData.append('council', council)
    formData.append('constituency', constituency)
    formData.append('ward', ward)
    formData.append('zone', zone)
    formData.append('landTitle', landTitle)
    formData.append('typeOfProject', typeOfProject)
    formData.append(
      'estimatedPopulationBenefitingFromTheProject',
      estimatedPopulationBenefitingFromTheProject
    )
    formData.append('communityBasedProjectBefore', communityBasedProjectBefore)
    formData.append(
      'communityBasedProjectBeforeYesReason',
      communityBasedProjectBeforeYesReason
    )
    formData.append('fundingFromAnyOrganization', fundingFromAnyOrganization)
    formData.append(
      'fundingFromAnyOrganizationYesReason',
      fundingFromAnyOrganizationYesReason
    )
    formData.append('mainProblemsInTheArea', mainProblemsInTheArea)
    formData.append(
      'problemsIsTheProposedProjectTryingToAddress',
      problemsIsTheProposedProjectTryingToAddress
    )
    formData.append(
      'howDidTheCommunityIdentifyTheProject',
      howDidTheCommunityIdentifyTheProject
    )
    formData.append(
      'doYouWantToPasteTheMinutesHereOrYouWantToUploadThemOnAfile',
      doYouWantToPasteTheMinutesHereOrYouWantToUploadThemOnAfile
    )
    formData.append(
      'projectIdentificationMinutesFile',
      projectIdentificationMinutesFile
    )
    formData.append(
      'projectIdentificationMinutes',
      projectIdentificationMinutes
    )
    formData.append(
      'hasAnythingBeenDoneBeforeToAddressTheProblemAndIfAny',
      hasAnythingBeenDoneBeforeToAddressTheProblemAndIfAny
    )
    formData.append(
      'hasAnythingBeenDoneBeforeToAddressTheProblemExplanation',
      hasAnythingBeenDoneBeforeToAddressTheProblemExplanation
    )
    formData.append(
      'stateTheBeneficiariesOfTheProposedProject',
      stateTheBeneficiariesOfTheProposedProject
    )
    formData.append(
      'whatIsTheEstimatedCostOfTheProject',
      whatIsTheEstimatedCostOfTheProject
    )
    formData.append(
      'howWillTheCommunityContributeTowardsTheCostOfTheProject',
      howWillTheCommunityContributeTowardsTheCostOfTheProject
    )
    formData.append(
      'typeOrUploadProjectCommitteemembers',
      typeOrUploadProjectCommitteemembers
    )
    formData.append('name', name)
    formData.append('position', position)
    formData.append('sex', sex)
    formData.append('nrc', nrc)
    formData.append('projectCommitteeMembersFile', projectCommitteeMembersFile)
    formData.append(
      'howWillTheCommunityEnsureProjectSustainability',
      howWillTheCommunityEnsureProjectSustainability
    )
    formData.append('ProjectProposerName', ProjectProposerName)
    formData.append('ProjectProposerNRC', ProjectProposerNRC)
    formData.append('ProjectProposerAddress', ProjectProposerAddress)
    formData.append('ProjectProposerPhone', ProjectProposerPhone)
    formData.append('ProjectProposerDate', ProjectProposerDate)
    formData.append('SeconderName', SeconderName)
    formData.append('SeconderNRC', SeconderNRC)
    formData.append('SeconderAddress', SeconderAddress)
    formData.append('SeconderPhone', SeconderPhone)
    formData.append('SeconderDate', SeconderDate)

    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      //setPosTableTotal(response.data);
      alert(response.data)
    })
  }

  const currentDate = new Date()
  const dateToday = `${currentDate.getDate()}/${
    currentDate.getMonth() + 1
  }/${currentDate.getFullYear()}`
  const timeNow =
    currentDate.getHours() +
    ':' +
    currentDate.getMinutes() +
    ':' +
    currentDate.getSeconds()

  return (
    <>
      {/*<!-- Main layout -->*/}
      <div>
        <div class='container-fluid '>
          <div class='row ' style={{ paddingTop: '5%' }}>
            {/* <!--Grid column-->*/}
            <div class='col-md-12 mb-0.00001'>
              {/* <!--Card-->*/}
              <div class='card'>
                {/*<!--Card content-->*/}
                <div class='card-body'>
                  <div
                    class='form-header white-text'
                    style={{ backgroundColor: 'green' }}
                  >
                    <center>
                      CONSTITUENCY DEVELOPMENT FUND - INFORMATION MANAGEMENT
                      SYSTEM
                    </center>
                  </div>
                  <div
                    style={{
                      top: '0',
                      left: '0',
                      marginBottom: '2%',
                      float: 'left'
                    }}
                  >
                    <i class='fas fa-home'></i>
                    <a href='/' style={{ color: 'blue' }}>
                      Home
                    </a>
                  </div>
                  <br /> <br />
                  <section>
                    <div class='text-left'>
                      <center>
                        {' '}
                        <img src={require('./img/coat of arms.jpg')} />
                        <strong>
                          <br />
                          CONSTITUENCY DEVELOPMENT FUND (CDF) <br /> APPLICATION
                          FORM FOR COMMUNITY PROJECTS
                        </strong>
                      </center>
                      <br />
                      <strong>Instructions:</strong> This application form
                      should be completed by the applicant and Uploaded together
                      with supporting documents. <br />
                      <u>
                        <b>NOTE:</b>
                      </u>{' '}
                      This form is not for sale.
                      <br />
                      <i>
                        Disclaimer: Completion of the form does not guarantee
                        the approval of the Project.
                      </i>
                    </div>

                    <br />
                    <div
                      style={{ border: '1px solid black' }}
                      class='text-left'
                    >
                      Types of projects: Construction and rehabilitation of
                      flush toilets or water borne sanitation system,
                      Construction of Police Posts, Construction of foot
                      bridges, Community libraries, sports centres, feeder
                      roads, dip tanks, rehabilitation of markets,
                      rehabilitation and construction of community halls,
                      construction and rehabilitation of health posts, and
                      others with community benefit and job creation.
                      <br />
                    </div>

                    <br />
                  </section>
                  {/*<form
                    name='photo'
                    id='imageUploadForm'
                    enctype='multipart/form-data'
                    action=''
                    method='post'
                  >*/}
                  <br />
                  <div class='col-lg-3 col-md-12 mb-1 text-left'>
                    {/*<!--<div class="md-form">-->*/}
                    <label for='registerFullName'>
                      Application Date<span class='red-text'>*</span>{' '}
                    </label>
                    <input
                      type='text'
                      class='form-control'
                      placeholder={dateToday}
                      disabled
                    />
                    {/*<!--</div>-->*/}
                  </div>
                  <br />
                  {/*<!--\form heading -->*/}
                  <div class='card-header' style={{ backgroundColor: 'green' }}>
                    <center>
                      <h6 class='white-text'>
                        SECTION A: GENERAL PROPOSAL DETAILS
                        <span class='yellow-text'>
                          (To be filled by the applicant)
                        </span>{' '}
                      </h6>
                    </center>
                  </div>
                  <br />
                  <div id='divShowMessageFromAjax'></div>
                  {/*<!--Grid row-->			*/}
                  <div class='row'>
                    {/*<!--Grid column-->*/}
                    <div class='col-md-12 mb-1 text-left'>
                      {/*<!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Name of Community making the proposal{' '}
                        <span class='red-text'>*</span>
                      </label>
                      <input
                        type='text'
                        class='form-control'
                        Placeholder=''
                        value={nameOfCommunityMakingTheProposal}
                        onChange={e =>
                          setnameOfCommunityMakingTheProposal(e.target.value)
                        }
                        id='nameOfCommunityMakingTheProposal'
                      />

                      {/*<!--</div>-->*/}
                    </div>
                    {/*  <!--\Grid column-->	*/}
                  </div>
                  {/*<!--Grid row .-->				*/}
                  <center>
                    <h4>
                      <u>PROJECT LOCATION </u>
                    </h4>
                  </center>
                  {/*<!--Grid row-->				*/}
                  <div class='row'>
                    {/* <!--Grid column -->*/}
                    <div class='col-lg-6 col-md-12 mb-1  text-left'>
                      <label for='registerFullName'>
                        Provinces<span class='red-text'>*</span>
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        onChange={e => setprovince(e.target.value)}
                        id='province'
                      >
                        <option value=''>Choose ....</option>
                        {provinceDropDown.map(valProvince => {
                          return (
                            <option value={valProvince.provincesID}>
                              {valProvince.province}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                    {/* <!--\Grid column-->*/}

                    {/* <!--Grid column-->*/}
                    <div
                      class='col-lg-6 col-md-12 mb-0.01  text-left'
                      id='hideLocalAuthority'
                    >
                      {/* <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Council<span class='red-text'>*</span>{' '}
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        onChange={e => setcouncil(e.target.value)}
                        id='province'
                        searchable='Search here..'
                      >
                        <option value=''>Choose ....</option>
                        {councilDropDown.map(valCouncil => {
                          return (
                            <option value={valCouncil.councilsID}>
                              {valCouncil.council}
                            </option>
                          )
                        })}
                      </select>
                      {/*<!--</div>-->*/}
                    </div>
                    {/*  <!--Grid column -->*/}
                    <div
                      class='col-lg-6 col-md-12 mb-0.01'
                      style={{ display: 'none' }}
                      id='divShowlocalAuthority'
                    ></div>
                    {/*<!--\Grid column-->*/}

                    {/*<!--\Grid column-->*/}
                    <div
                      class='col-lg-6 col-md-12 mb-1  text-left'
                      id='hideConstituency'
                    >
                      {/* <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Constituency<span class='red-text'>*</span>{' '}
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        onChange={e => setconstituency(e.target.value)}
                        id='constituency'
                        searchable='Search here..'
                      >
                        <option value=''>Choose ....</option>
                        {constituencyDropDown.map(valConstituency => {
                          return (
                            <option value={valConstituency.constituenciesID}>
                              {valConstituency.constituency}
                            </option>
                          )
                        })}
                      </select>
                      {/*<!--</div>-->*/}
                    </div>
                    {/*<!--Grid column -->*/}
                    <div
                      class='col-lg-6 col-md-12 mb-0.01'
                      style={{ display: 'none' }}
                      id='divShowConstituency'
                    ></div>
                    {/*<!--\Grid column-->*/}

                    {/* <!--\Grid column-->*/}
                    <div
                      class='col-lg-3 col-md-12 mb-1  text-left'
                      id='hideWard'
                    >
                      {/*<!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Ward<span class='red-text'>*</span>{' '}
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        onChange={e => setward(e.target.value)}
                        id='ward'
                        searchable='Search here..'
                      >
                        <option value=''>Choose ....</option>
                        {wardDropDown.map(valWard => {
                          return (
                            <option value={valWard.wardsID}>
                              {valWard.ward}
                            </option>
                          )
                        })}
                      </select>
                      {/*<!--</div>-->*/}
                    </div>
                    {/* <!--Grid column -->*/}
                    <div
                      class='col-lg-3 col-md-12 mb-0.01'
                      style={{ display: 'none' }}
                      id='divShowWard'
                    ></div>
                    {/*<!--\Grid column-->*/}

                    {/* <!--Grid column-->	*/}
                    <div class='col-lg-3 col-md-12 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Zone<span class='red-text'>*</span>
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          Placeholder=''
                          value={zone}
                          onChange={e => setzone(e.target.value)}
                          id='zone'
                        />
                      </div>
                    </div>
                    {/*<!--\Grid column-->	*/}
                  </div>
                  {/*<!--Grid row .-->*/}
                  {/*<!--Grid row-->				*/}
                  <div class='row'>
                    {/* <!--Grid column-->	*/}
                    <div class='col-lg-4 col-md-12 mb-1  text-left'>
                      {/*<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Land Title/Ownership (where applicable
                        <span class='red-text'></span>
                      </label>
                      <input
                        type='text'
                        class='form-control'
                        Placeholder=''
                        value={landTitle}
                        onChange={e => setlandTitle(e.target.value)}
                        id='landTitle'
                      />
                      {/*</div>-->*/}
                    </div>
                    {/*<!--\Grid column-->		*/}
                    {/*<!--Grid column-->*/}
                    <div class='col-lg-3 col-md-2 mb-1  text-left'>
                      <label for='registerFullName'>
                        Type of Project<span class='red-text'>*</span>
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        value={typeOfProject}
                        onChange={e => settypeOfProject(e.target.value)}
                        id='typeOfProject'
                      >
                        <option value=''>Choose...</option>
                        <option>Water</option>
                        <option>Education</option>
                        <option>Health</option>
                      </select>
                    </div>
                    {/*<!--\Grid column-->*/}
                    {/*<!--Grid column-->	*/}
                    <div class='col-md-5 mb-1  text-left'>
                      {/*<!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Estimated population benefiting from the project{' '}
                        <span class='red-text'>*</span>
                      </label>
                      <input
                        type='text'
                        class='form-control'
                        Placeholder=''
                        value={estimatedPopulationBenefitingFromTheProject}
                        onChange={e =>
                          setestimatedPopulationBenefitingFromTheProject(
                            e.target.value
                          )
                        }
                        id='estimatedPopulationBenefitingFromTheProject'
                      />
                      {/*<--</div>-->*/}
                    </div>
                    {/*<!--\Grid column-->	*/}
                  </div>
                  {/* <!--\Grid row-->*/}
                  {/*<!--Grid row-->			*/}
                  <div class='row'>
                    {/*<!--Grid column-->*/}
                    <div class='col-lg-12 col-md-2 mb-0.001  text-left'>
                      <label for='registerFullName'>
                        Has the community been involved in any community based
                        project before? <span class='red-text'>*</span>
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        value={communityBasedProjectBefore}
                        onChange={e =>
                          setcommunityBasedProjectBefore(e.target.value)
                        }
                        id='communityBasedProjectBefore'
                      >
                        <option value=''>Choose...</option>
                        <option>Yes</option>
                        <option>No</option>
                      </select>
                    </div>
                    {/*<!--\Grid column-->*/}
                    {/*<!--\Grid column-->*/}
                    {communityBasedProjectBefore === 'No' ||
                    communityBasedProjectBefore === '' ? (
                      <>
                        <div
                          class='col-lg-12 col-md-12 mb-0.001'
                          id='hideCommunityBasedProjectBeforeYesReason'
                        >
                          {/* <!--<div class="md-form">-->*/}
                          <label for='registerFullName'>
                            <span class='red-text'></span>{' '}
                          </label>
                          <input
                            type='text'
                            class='form-control'
                            placeholder='(If yes please give details)'
                            disabled
                          />
                          {/*	<!--</div>-->*/}
                        </div>{' '}
                      </>
                    ) : (
                      <></>
                    )}
                    {/*	<!--Grid column -->*/}

                    {communityBasedProjectBefore === 'Yes' ? (
                      <>
                        <div
                          class='col-lg-12 col-md-12 mb-3  text-left'
                          id='divShowCommunityBasedProjectBeforeYesReason'
                        >
                          <label for='registerFullName'>
                            (If yes please give details)
                            <span class='red-text'>*</span>{' '}
                          </label>
                          <textarea
                            class='form-control'
                            placeholder=''
                            value={communityBasedProjectBeforeYesReason}
                            onChange={e =>
                              setcommunityBasedProjectBeforeYesReason(
                                e.target.value
                              )
                            }
                            id='communityBasedProjectBeforeYesReason'
                          ></textarea>
                        </div>{' '}
                      </>
                    ) : (
                      <></>
                    )}
                    {/*<!--\Grid column-->*/}
                  </div>
                  {/*<!--Grid row-->*/}
                  {/* <!--Grid row-->		*/}
                  <div class='row'>
                    {/* <!--Grid column-->*/}
                    <div class='col-lg-12 col-md-2 mb-0.001 text-left'>
                      <label for='registerFullName'>
                        Has the Community received any funding from any
                        organization <span class='red-text'>*</span>
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        value={fundingFromAnyOrganization}
                        onChange={e =>
                          setfundingFromAnyOrganization(e.target.value)
                        }
                        id='fundingFromAnyOrganization'
                      >
                        <option value=''>Choose...</option>
                        <option>Yes</option>
                        <option>No</option>
                      </select>
                    </div>
                    {/* <!--\Grid column-->*/}
                    {/* <!--\Grid column-->*/}
                    {fundingFromAnyOrganization === 'No' ||
                    fundingFromAnyOrganization === '' ? (
                      <>
                        {' '}
                        <div
                          class='col-lg-12 col-md-12 mb-3'
                          id='hideFundingFromAnyOrganizationYesReason'
                        >
                          {/*  <!--<div class="md-form">-->*/}
                          <label for='registerFullName'>
                            <span class='red-text'></span>{' '}
                          </label>
                          <input
                            type='text'
                            class='form-control'
                            placeholder='If yes which organization and when and for what activities?'
                            disabled
                          />
                          {/* <!--</div>-->*/}
                        </div>{' '}
                      </>
                    ) : (
                      <></>
                    )}
                    {/* <!--Grid column -->*/}
                    {fundingFromAnyOrganization === 'Yes' ? (
                      <>
                        <div
                          class='col-lg-12 col-md-12 mb-3 text-left'
                          id='divShowFundingFromAnyOrganizationYesReason'
                        >
                          <label for='registerFullName'>
                            If yes which organization and when and for what
                            activities?<span class='red-text'>*</span>{' '}
                          </label>
                          <textarea
                            class='form-control'
                            placeholder=''
                            value={fundingFromAnyOrganizationYesReason}
                            onChange={e =>
                              setfundingFromAnyOrganizationYesReason(
                                e.target.value
                              )
                            }
                            id='fundingFromAnyOrganizationYesReason'
                          ></textarea>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    {/* <!--\Grid column-->*/}
                  </div>
                  {/* <!--Grid row-->*/}
                  <div class='card-header' style={{ backgroundColor: 'green' }}>
                    <center>
                      <h6 class='white-text'>
                        SECTION B: PROJECT IDENTIFICATION{' '}
                      </h6>
                    </center>
                  </div>
                  {/*      <!--Grid row-->*/}
                  <div class='row'>
                    {/*<!--Grid column-->	*/}
                    <div class='col-md-12 mb-1 text-left'>
                      {/*<!--<div class="md-form">-->	*/}
                      <label for='registerFullName'>
                        What are the main problems in the area?{' '}
                        <span class='red-text'>*</span>
                      </label>
                      <textarea
                        class='form-control'
                        Placeholder=''
                        value={mainProblemsInTheArea}
                        onChange={e => setmainProblemsInTheArea(e.target.value)}
                        id='mainProblemsInTheArea'
                      ></textarea>
                    </div>
                    {/*<!--</div>-->	*/}
                    {/*  <!--\Grid column-->	*/}
                  </div>
                  {/*<!--\Grid row-->*/}
                  {/*<!--Grid row-->*/}
                  <div class='row'>
                    {/* <!--Grid column-->	*/}
                    <div class='col-md-12 mb-1 text-left'>
                      {/*   <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Which of these problems is the proposed project trying
                        to address and how?<span class='red-text'>*</span>
                      </label>
                      <textarea
                        class='form-control'
                        Placeholder=''
                        value={problemsIsTheProposedProjectTryingToAddress}
                        onChange={e =>
                          setproblemsIsTheProposedProjectTryingToAddress(
                            e.target.value
                          )
                        }
                        id='problemsIsTheProposedProjectTryingToAddress'
                      ></textarea>
                      {/*<!--</div>-->*/}
                    </div>
                    {/*  <!--\Grid column-->	*/}
                  </div>
                  {/* <!--\Grid row-->*/}
                  {/* <!--Grid row-->*/}
                  <div class='row'>
                    {/* <!--Grid column-->	*/}
                    <div class='col-md-12 mb-1 text-left'>
                      {/*  <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        How did the community identify the project? (Attach
                        minutes where applicable){' '}
                        <span class='red-text'>*</span>
                      </label>
                      <textarea
                        class='form-control'
                        Placeholder=''
                        value={howDidTheCommunityIdentifyTheProject}
                        onChange={e =>
                          sethowDidTheCommunityIdentifyTheProject(
                            e.target.value
                          )
                        }
                        id='howDidTheCommunityIdentifyTheProject'
                      ></textarea>
                      {/*<!--</div>-->*/}
                    </div>
                    {/*   <!--\Grid column-->	*/}
                  </div>
                  {/* <!--\Grid row-->*/}
                  {/*<!--Grid row-->*/}
                  <div class='row'>
                    {/*  <!--Grid column-->*/}
                    <div class='col-lg-12 col-md-2 mb-2 text-left'>
                      <label for='registerFullName'>
                        {' '}
                        Do You Want To Paste The Minutes Here Or You Want To
                        Upload Them On A file <span class='red-text'>*</span>
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        value={
                          doYouWantToPasteTheMinutesHereOrYouWantToUploadThemOnAfile
                        }
                        onChange={e =>
                          setdoYouWantToPasteTheMinutesHereOrYouWantToUploadThemOnAfile(
                            e.target.value
                          )
                        }
                        id='doYouWantToPasteTheMinutesHereOrYouWantToUploadThemOnAfile'
                      >
                        <option value=''>Choose...</option>
                        <option>Paste</option>
                        <option>Upload File</option>
                      </select>
                    </div>
                    {/* <!--\Grid column-->*/}
                  </div>
                  {/*<!--\Grid row-->*/}
                  {/*<!--Grid row-->*/}
                  <div class='row'>
                    {doYouWantToPasteTheMinutesHereOrYouWantToUploadThemOnAfile ===
                    'Upload File' ? (
                      <>
                        <div
                          class='col-md-12 mb-1 text-left'
                          id='projectIdentificationUploadFile'
                        >
                          {/*/<div class="md-form">*/}
                          <label for='registerFullName'>
                            Upload The Miniutes File{' '}
                            <span class='red-text'>*</span>
                          </label>
                          <input
                            type='file'
                            class='form-control'
                            /*value={projectIdentificationMinutesFile}
                            onChange={e =>
                              setprojectIdentificationMinutesFile(
                                e.target.value
                              )
                            }*/
                            onChange={saveProjectIdentificationMinutesFile}
                            id='projectIdentificationMinutesFile'
                          />
                          {/*</div>*/}
                        </div>{' '}
                      </>
                    ) : (
                      <></>
                    )}
                    {/*  <!--\Grid column-->	*/}

                    {/* <!--Grid column-->	*/}
                    {doYouWantToPasteTheMinutesHereOrYouWantToUploadThemOnAfile ===
                    'Paste' ? (
                      <>
                        {' '}
                        <div
                          class='col-md-12 mb-1'
                          id='projectIdentificationPaste'
                        >
                          <div class='md-form'>
                            <label for='registerFullName'>
                              Paste The Miniutes Here{' '}
                              <span class='red-text'>*</span>
                            </label>{' '}
                            <br />
                            <textarea
                              class='form-control'
                              Placeholder=''
                              value={projectIdentificationMinutes}
                              onChange={e =>
                                setprojectIdentificationMinutes(e.target.value)
                              }
                              id='projectIdentificationMinutes'
                            ></textarea>
                          </div>
                        </div>{' '}
                      </>
                    ) : (
                      <></>
                    )}
                    {/* <!--\Grid column-->	*/}
                  </div>
                  {/* <!--\Grid row-->   */}
                  {/*<!--Grid row-->*/}
                  <div class='row'>
                    {/* <!--Grid column-->*/}
                    <div class='col-lg-12 col-md-2 mb-2 text-left'>
                      <label for='registerFullName'>
                        {' '}
                        Has anything been done before to address the problem and
                        if any, was it successfully implemented?{' '}
                        <span class='red-text'>*</span>
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        value={
                          hasAnythingBeenDoneBeforeToAddressTheProblemAndIfAny
                        }
                        onChange={e =>
                          sethasAnythingBeenDoneBeforeToAddressTheProblemAndIfAny(
                            e.target.value
                          )
                        }
                        id='hasAnythingBeenDoneBeforeToAddressTheProblemAndIfAny'
                        required
                      >
                        <option value=''>Choose...</option>
                        <option>Yes</option>
                        <option>No</option>
                      </select>
                    </div>
                    {/*<!--\Grid column-->		*/}
                    {/* <!--Grid column-->	*/}
                    {hasAnythingBeenDoneBeforeToAddressTheProblemAndIfAny ===
                    'Yes' ? (
                      <>
                        <div
                          class='col-md-12 mb-1 text-left'
                          id='hasAnythingBeenDoneBeforeToAddressTheProblemAndIfAnyYesExplain'
                        >
                          {/*   <!--<div class="md-form">-->*/}
                          <label for='registerFullName'>
                            Explain if it was successfully implemented{' '}
                            <span class='red-text'>*</span>
                          </label>
                          <textarea
                            class='form-control'
                            Placeholder=''
                            value={
                              hasAnythingBeenDoneBeforeToAddressTheProblemExplanation
                            }
                            onChange={e =>
                              sethasAnythingBeenDoneBeforeToAddressTheProblemExplanation(
                                e.target.value
                              )
                            }
                            id='hasAnythingBeenDoneBeforeToAddressTheProblemExplanation'
                            required
                          ></textarea>
                          {/*	<!--</div>-->*/}
                        </div>{' '}
                      </>
                    ) : (
                      <></>
                    )}
                    {/*   <!--\Grid column-->			*/}
                  </div>
                  {/*  <!--\Grid row-->*/}
                  {/* <!--Grid row-->*/}
                  <div class='row'>
                    {/*<!--Grid column-->	*/}
                    <div class='col-md-12 mb-1 text-left'>
                      {/* <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        State the beneficiaries of the proposed project{' '}
                        <span class='red-text'>*</span>
                      </label>
                      <textarea
                        class='form-control'
                        Placeholder=''
                        value={stateTheBeneficiariesOfTheProposedProject}
                        onChange={e =>
                          setstateTheBeneficiariesOfTheProposedProject(
                            e.target.value
                          )
                        }
                        id='stateTheBeneficiariesOfTheProposedProject'
                        required
                      ></textarea>
                      {/*	<!--</div>-->*/}
                    </div>
                    {/* <!--\Grid column-->		*/}
                  </div>
                  {/* <!--\Grid row-->*/}
                  {/* <!--Grid row-->*/}
                  <div class='row'>
                    {/*	<!--Grid column-->	*/}
                    <div class='col-md-3 mb-1 text-left'>
                      {/*	<!--<div class="md-form">-->	*/}
                      <label for='registerFullName'>
                        What Is The Project Estimated Cost:
                        <span class='red-text'>*</span>
                      </label>
                      <input
                        type='text'
                        class='form-control'
                        placeholder=' ZMW'
                        value={whatIsTheEstimatedCostOfTheProject}
                        onChange={e =>
                          setwhatIsTheEstimatedCostOfTheProject(e.target.value)
                        }
                        id='whatIsTheEstimatedCostOfTheProject'
                        required
                      />
                      {/*	<!--</div>-->	*/}
                    </div>
                    {/*  <!--\Grid column-->*/}

                    {/*<!--Grid column-->	*/}
                    <div class='col-md-9 mb-1 text-left'>
                      {/* <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        How Will The Community Contribute Towards The Cost Of
                        The Project? (In terms of maintenance fees, water
                        supply, labor etc.) <span class='red-text'>*</span>
                      </label>
                      <textarea
                        class='form-control'
                        Placeholder=''
                        value={
                          howWillTheCommunityContributeTowardsTheCostOfTheProject
                        }
                        onChange={e =>
                          sethowWillTheCommunityContributeTowardsTheCostOfTheProject(
                            e.target.value
                          )
                        }
                        id='howWillTheCommunityContributeTowardsTheCostOfTheProject'
                        required
                      ></textarea>
                      {/*	<!--</div>-->*/}
                    </div>
                    {/* <!--\Grid column-->	*/}
                  </div>
                  {/* <!--\Grid row--> */}
                  {/*<!--Grid row-->*/}
                  <div class='row'>
                    {/* <!--Grid column-->*/}
                    <div class='col-lg-12 col-md-2 mb-1 text-left'>
                      <label for='registerFullName'>
                        {' '}
                        Are You Going To Type or Upload The List Of Project
                        Committee Members <span class='red-text'>*</span>
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        value={typeOrUploadProjectCommitteemembers}
                        onChange={e =>
                          settypeOrUploadProjectCommitteemembers(e.target.value)
                        }
                        id='typeOrUploadProjectCommitteemembers'
                        required
                      >
                        <option value=''>Choose...</option>
                        <option>Type</option>
                        <option>Upload</option>
                      </select>
                    </div>
                    {/*<!--\Grid column-->	*/}
                  </div>
                  {/*<!--\Grid row-->*/}
                  {/* <!--Grid row-->*/}
                  {typeOrUploadProjectCommitteemembers === 'Type' ? (
                    <>
                      {' '}
                      <div id='projectCommitteeMembersType'>
                        {/*<!--Grid column-->*/}
                        <div class='col-md-12 mb-0.001'>
                          <br />
                          <center>
                            <i>
                              List Of Membership In The
                              Club/Group/Enterprise/Cooperative:
                            </i>
                          </center>
                        </div>
                        {/*<!--\Grid column--> */}

                        {/* for($projectCommitteeMembersTypeCount = 1 ; $projectCommitteeMembersTypeCount <= 10 ; $projectCommitteeMembersTypeCount ++ )
                          {*/}

                        {/*<!--Grid column-->*/}
                        <div class='col-md-1 mb-1'>
                          <div class='md-form'>
                            <label for='registerFullName'>
                              {/* $projectCommitteeMembersTypeCount; */}
                              <span class='red-text'></span>
                            </label>
                          </div>
                        </div>
                        {/* <!--\Grid column--> */}
                        {/* <!--Grid column-->*/}
                        {inputList.map((x, i) => {
                          return (
                            <>
                              <div class='row'>
                                <div class='col-md-4 mb-1'>
                                  <div class='md-form'>
                                    <label for='registerFullName'>
                                      Name
                                      <span class='red-text'>*</span>
                                    </label>{' '}
                                    <br /> <br />
                                    <input
                                      type='Text'
                                      class='form-control'
                                      placeholder=''
                                      value={x.name}
                                      onChange={e => handleInputChange(e, i)}
                                      name='name'
                                    />
                                  </div>
                                </div>

                                <div class='col-md-3 mb-1'>
                                  <div class='md-form'>
                                    <label for='registerFullName'>
                                      Position
                                      <span class='red-text'>*</span>
                                    </label>{' '}
                                    <br /> <br />
                                    <input
                                      type='Text'
                                      class='form-control'
                                      placeholder=''
                                      value={x.position}
                                      onChange={e => handleInputChange(e, i)}
                                      name='position'
                                    />
                                  </div>
                                </div>

                                <div class='col-lg-2 col-md-2 mb-2 text-left'>
                                  <div class='md-form'>
                                    <label for='registerFullName'>
                                      {' '}
                                      Sex <span class='red-text'>*</span>
                                    </label>{' '}
                                    <br /> <br /> <br />
                                    <select
                                      class='custom-select d-block w-100'
                                      value={x.sex}
                                      onChange={e => handleInputChange(e, i)}
                                      name='sex'
                                    >
                                      <option value=''>Choose...</option>
                                      <option>Male</option>
                                      <option>Female</option>
                                    </select>
                                  </div>
                                </div>

                                <div class='col-md-2 mb-1'>
                                  <div class='md-form'>
                                    <label for='registerFullName'>
                                      NRC
                                      <span class='red-text'>*</span>
                                    </label>{' '}
                                    <br /> <br />
                                    <input
                                      type='Text'
                                      class='form-control'
                                      placeholder=''
                                      value={x.nrc}
                                      onChange={e => handleInputChange(e, i)}
                                      name='nrc'
                                    />
                                  </div>
                                </div>
                                {inputList.length !== 1 && (
                                  <div class='col-lg-1 col-md-2 mb-2'>
                                    <div class='md-form'>
                                      <br /> <br />
                                      <input
                                        type='button'
                                        class='form-control btn white-text red accent-2'
                                        value='Remove'
                                        onClick={() => handleRemoveClick(i)}
                                      />
                                    </div>
                                  </div>
                                )}
                                {/*<!--\Grid column-->	*/}

                                {/*<!--Grid column-->*/}
                                {inputList.length - 1 === i && (
                                  <div class='col-lg-12 col-md-2 mb-2'>
                                    <div class='md-form'>
                                      <input
                                        type='button'
                                        class='form-control btn white-text blue accent-2'
                                        value='Add'
                                        onClick={handleAddClick}
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </>
                          )
                        })}
                      </div>{' '}
                    </>
                  ) : (
                    <></>
                  )}
                  {/* <!--Grid row-->*/}
                  {typeOrUploadProjectCommitteemembers === 'Upload' ? (
                    <div class='row'>
                      {/*<!--Grid column-->	*/}
                      <div
                        class='col-md-12 mb-1 text-left'
                        id='projectCommitteeMembersUpload'
                      >
                        {/* <!--<div class="md-form">-->	*/}
                        <label for='registerFullName'>
                          Upload The project Committee Members File{' '}
                          <span class='red-text'>*</span>
                        </label>
                        <br />
                        <br />
                        <input
                          type='file'
                          class='form-control'
                          placeholder=''
                          /*value={projectCommitteeMembersFile}
                          onChange={e =>
                            setprojectCommitteeMembersFile(e.target.value)
                          }*/
                          onChange={saveProjectCommitteeMembersFile}
                          id='projectCommitteeMembersFile'
                          required
                        />
                        {/* <!--   </div>-->	*/}
                      </div>
                      {/*  <!--\Grid column-->	*/}
                    </div>
                  ) : (
                    <></>
                  )}
                  {/* <!--\Grid row-->*/}
                  {/*   <!--Grid row-->*/}
                  <div class='row'>
                    {/*  <!--Grid column-->	*/}
                    <div class='col-md-12 mb-2 text-left'>
                      {/*   <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        How Will The Community Ensure Project Sustainability?{' '}
                        <span class='red-text'>*</span>
                      </label>
                      <textarea
                        class='form-control'
                        value={howWillTheCommunityEnsureProjectSustainability}
                        onChange={e =>
                          sethowWillTheCommunityEnsureProjectSustainability(
                            e.target.value
                          )
                        }
                        id='howWillTheCommunityEnsureProjectSustainability'
                        required
                      >
                        {' '}
                      </textarea>
                      {/* 	<!--</div>-->*/}
                    </div>
                    {/* <!--\Grid column-->	*/}
                  </div>
                  {/*  <!--\Grid row-->*/}
                  <br />
                  <h3>
                    <center>CONTACT PERSON(s): </center>
                  </h3>
                  <br />
                  <center>
                    <h4 class='red-text'>(Project Proposer)</h4>
                  </center>
                  {/* <!--Grid row-->*/}
                  <div class='row'>
                    {/* <!--Grid column-->*/}
                    <div class='col-md-3 mb-1 text-left'>
                      {/*     <!--<div class="md-form">--->*/}
                      <label for='registerFullName'>
                        Name <span class='red-text'>*</span>
                      </label>
                      <input
                        type='Text'
                        class='form-control'
                        placeholder=''
                        value={ProjectProposerName}
                        onChange={e => setProjectProposerName(e.target.value)}
                        id='ProjectProposerName'
                      />
                      {/* <!--</div>-->*/}
                    </div>
                    {/*<!--\Grid column--> 				*/}
                    {/*  <!--Grid column-->*/}
                    <div class='col-md-2 mb-1 text-left'>
                      {/*       <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        NRC <span class='red-text'>*</span>
                      </label>
                      <input
                        type='Text'
                        class='form-control'
                        placeholder=''
                        value={ProjectProposerNRC}
                        onChange={e => setProjectProposerNRC(e.target.value)}
                        id='ProjectProposerNRC'
                      />
                      {/* <!--</div>-->*/}
                    </div>
                    {/*<!--\Grid column--> 		*/}
                    {/*  <!--Grid column-->*/}
                    <div class='col-md-3 mb-1 text-left'>
                      {/*       <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Address <span class='red-text'>*</span>
                      </label>
                      <input
                        type='text'
                        class='form-control'
                        placeholder=''
                        value={ProjectProposerAddress}
                        onChange={e =>
                          setProjectProposerAddress(e.target.value)
                        }
                        id='ProjectProposerAddress'
                      />
                      {/* <!--</div>-->*/}
                    </div>
                    {/*<!--\Grid column--> 		*/}
                    {/*   <!--Grid column-->*/}
                    <div class='col-md-2 mb-1 text-left'>
                      {/*      <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Phone <span class='red-text'>*</span>
                      </label>
                      <input
                        type='Text'
                        class='form-control'
                        placeholder=''
                        value={ProjectProposerPhone}
                        onChange={e => setProjectProposerPhone(e.target.value)}
                        id='ProjectProposerPhone'
                      />
                      {/* <!--</div>-->*/}
                    </div>
                    {/* <!--\Grid column--> */}
                    {/* <!--Grid column-->*/}
                    <div class='col-md-2 mb-1 text-left'>
                      {/*      <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Date <span class='red-text'>*</span>
                      </label>
                      <input
                        type='date'
                        class='form-control datepicker'
                        placeholder=''
                        value={ProjectProposerDate}
                        onChange={e => setProjectProposerDate(e.target.value)}
                        id='ProjectProposerDate'
                      />
                      {/* <!--</div>-->*/}
                    </div>
                    {/* <!--\Grid column-->			*/}
                  </div>
                  {/* <!--\Grid row-->*/}
                  <br />
                  <center>
                    <h4 class='red-text'>(Project Seconder)</h4>
                  </center>
                  {/* <!--Grid row-->*/}
                  <div class='row'>
                    {/*  <!--Grid column-->*/}
                    <div class='col-md-3 mb-1 text-left'>
                      {/*      <!--<div class="md-form">--->*/}
                      <label for='registerFullName'>
                        Name <span class='red-text'>*</span>
                      </label>
                      <input
                        type='Text'
                        class='form-control'
                        placeholder=''
                        value={SeconderName}
                        onChange={e => setSeconderName(e.target.value)}
                        id='SeconderName'
                      />
                      {/* <!--</div>-->*/}
                    </div>
                    {/* <!--\Grid column--> 		*/}
                    {/*  <!--Grid column-->*/}
                    <div class='col-md-2 mb-1 text-left'>
                      {/*       <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        NRC <span class='red-text'>*</span>
                      </label>
                      <input
                        type='Text'
                        class='form-control'
                        placeholder=''
                        value={SeconderNRC}
                        onChange={e => setSeconderNRC(e.target.value)}
                        id='SeconderNRC'
                      />
                      {/* <!--</div>-->*/}
                    </div>
                    {/* <!--\Grid column--> 		*/}
                    {/*  <!--Grid column-->*/}
                    <div class='col-md-3 mb-1 text-left'>
                      {/*       <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Address <span class='red-text'>*</span>
                      </label>
                      <input
                        type='text'
                        class='form-control'
                        placeholder=''
                        value={SeconderAddress}
                        onChange={e => setSeconderAddress(e.target.value)}
                        id='SeconderAddress'
                      />
                      {/*  <!--</div>-->*/}
                    </div>
                    {/*<!--\Grid column--> 			*/}
                    {/*   <!--Grid column-->*/}
                    <div class='col-md-2 mb-1 text-left'>
                      {/*      <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Phone <span class='red-text'>*</span>
                      </label>
                      <input
                        type='Text'
                        class='form-control'
                        placeholder=''
                        value={SeconderPhone}
                        onChange={e => setSeconderPhone(e.target.value)}
                        id='SeconderPhone'
                      />
                      {/* <!--</div>-->*/}
                    </div>
                    {/* <!--\Grid column--> */}
                    {/* <!--Grid column-->*/}
                    <div class='col-md-2 mb-1 text-left'>
                      {/*       <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Date <span class='red-text'>*</span>
                      </label>
                      <input
                        type='date'
                        class='form-control datepicker'
                        placeholder=''
                        value={SeconderDate}
                        onChange={e => setSeconderDate(e.target.value)}
                        id='SeconderDate'
                      />
                      {/* <!--</div>-->*/}
                    </div>
                    {/* <!--\Grid column-->			*/}
                  </div>
                  {/* <!--\Grid row--> */}
                  <div
                    class='card-header'
                    style={{ backgroundColor: 'green', display: 'none' }}
                  >
                    <center>
                      <h6 class='white-text'>
                        {' '}
                        SECTION C: RECOMMENDATION BY THE WARD DEVELOPMENT
                        COMMITTEE{' '}
                      </h6>
                    </center>
                  </div>
                  {/*  <!--Grid row--> */}
                  <div class='row' style={{ display: 'none' }}>
                    {/* <!--Grid column--> */}
                    <div class='col-lg-5 col-md-2 mb-2'>
                      <label for='registerFullName'>
                        {' '}
                        Recommendation By The Ward Development Committee{' '}
                        <span class='red-text'>*</span>
                      </label>
                      <select
                        disabled
                        class='custom-select d-block w-100'
                        name='recommendationByTheWardDevelopmentCommittee'
                        id='recommendationByTheWardDevelopmentCommittee'
                      >
                        <option value=''>Choose...</option>
                        <option>Recommended</option>
                        <option>Not Recommended</option>
                        <option>Deferred</option>
                      </select>
                    </div>
                    {/*   <!--\Grid column-->				 */}
                    {/*     <!--Grid column-->	*/}
                    <div class='col-md-7 mb-2'>
                      {/*       <!--<div class="md-form">--> */}
                      <label for='registerFullName'>
                        Reasons For Recommendation{' '}
                        <span class='red-text'>*</span>
                      </label>
                      <textarea
                        disabled
                        class='form-control'
                        name='recommendationByTheWardDevelopmentCommitteeReason'
                        id='recommendationByTheWardDevelopmentCommitteeReason'
                        required
                      >
                        {' '}
                      </textarea>
                      {/* <!--</div>--> */}
                    </div>
                    {/*     <!--\Grid column-->				*/}
                  </div>
                  {/* <!--\Grid row--> */}
                  {/* <!--Grid row--> */}
                  <div class='row' style={{ display: 'none' }}>
                    {/* <!--Grid column--> */}
                    <div class='col-md-4 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Name (WDC Chairperson): <span class='red-text'></span>
                        </label>
                        <input
                          disabled
                          type='text'
                          class='form-control'
                          placeholder=''
                          name='wardDevelopmentCommitteeChairpersonName'
                          id='wardDevelopmentCommitteeChairpersonName'
                        />
                      </div>
                    </div>
                    {/*  <!--\Grid column-->*/}
                    {/*  <!--Grid column-->*/}
                    <div class='col-md-4 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Sign: <span class='red-text'></span>
                        </label>
                        <input
                          disabled
                          type='text'
                          class='form-control'
                          placeholder=''
                          name='wardDevelopmentCommitteeChairpersonSign'
                          id='wardDevelopmentCommitteeChairpersonSign'
                        />
                      </div>
                    </div>
                    {/* <!--\Grid column-->*/}
                    {/*  <!--Grid column-->*/}
                    <div class='col-md-4 mb-1'>
                      {/*     <!--<div class="md-form">--> */}
                      <label for='registerFullName'>
                        Date: <span class='red-text'></span>
                      </label>
                      <input
                        disabled
                        type='date'
                        class='form-control'
                        placeholder=''
                        name='wardDevelopmentCommitteeChairpersonDate'
                        id='wardDevelopmentCommitteeChairpersonDate'
                      />
                      {/* <!--</div>--> */}
                    </div>
                    {/* <!--\Grid column-->		*/}
                  </div>
                  {/* <!--\Grid row--> */}
                  <div
                    class='card-header'
                    style={{ backgroundColor: 'green', display: 'none' }}
                  >
                    <center>
                      <h6 class='white-text'>
                        {' '}
                        SECTION D: DECISION BY THE CONSTITUENCY DEVELOPMENT FUND
                        COMMITTEE{' '}
                      </h6>
                    </center>
                  </div>
                  {/* <!--Grid row--> */}
                  <div class='row' style={{ display: 'none' }}>
                    {/* <!--Grid column--> */}
                    <div class='col-lg-5 col-md-2 mb-2'>
                      <label for='registerFullName'>
                        {' '}
                        Recommendation By The Constituency Development Committee{' '}
                        <span class='red-text'>*</span>
                      </label>
                      <select
                        disabled
                        class='custom-select d-block w-100'
                        name='recommendationByTheConstituencyDevelopmentCommittee'
                        id='recommendationByTheConstituencyDevelopmentCommittee'
                      >
                        <option value=''>Choose...</option>
                        <option>Approved</option>
                        <option>Not Approved</option>
                      </select>
                    </div>
                    {/*  <!--\Grid column-->		*/}
                    {/*      <!--Grid column-->	*/}
                    <div class='col-md-7 mb-2'>
                      {/*      <!--<div class="md-form">--> */}
                      <label for='registerFullName'>
                        Reasons For Recommendation{' '}
                        <span class='red-text'>*</span>
                      </label>
                      <textarea
                        disabled
                        class='form-control'
                        name='recommendationByTheConstituencyDevelopmentCommitteeReason'
                        id='recommendationByTheConstituencyDevelopmentCommitteeReason'
                        required
                      >
                        {' '}
                      </textarea>
                      {/* <!--</div>--> */}
                    </div>
                    {/*     <!--\Grid column-->		*/}
                  </div>
                  {/* <!--\Grid row-->*/}
                  {/* <!--Grid row-->*/}
                  <div class='row' style={{ display: 'none' }}>
                    {/*<!--Grid column--> */}
                    <div class='col-md-4 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Name (CDC Chairperson): <span class='red-text'></span>
                        </label>
                        <input
                          disabled
                          type='text'
                          class='form-control'
                          placeholder=''
                          name='constituencyDevelopmentCommitteeChairpersonName'
                          id='constituencyDevelopmentCommitteeChairpersonName'
                        />
                      </div>
                    </div>
                    {/* <!--\Grid column-->*/}
                    {/* <!--Grid column--> */}
                    <div class='col-md-4 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Sign: <span class='red-text'></span>
                        </label>
                        <input
                          disabled
                          type='text'
                          class='form-control'
                          placeholder=''
                          name='constituencyDevelopmentCommitteeChairpersonSign'
                          id='constituencyDevelopmentCommitteeChairpersonSign'
                        />
                      </div>
                    </div>
                    {/* <!--\Grid column--> */}
                    {/* <!--Grid column-->*/}
                    <div class='col-md-4 mb-1'>
                      {/*      <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Date: <span class='red-text'></span>
                      </label>
                      <input
                        disabled
                        type='date'
                        class='form-control'
                        placeholder=''
                        name='constituencyDevelopmentCommitteeChairpersonDate'
                        id='constituencyDevelopmentCommitteeChairpersonDate'
                      />
                      {/* <!--</div>-->*/}
                    </div>
                    {/* <!--\Grid column--> */}
                  </div>
                  {/* <!--\Grid row-->*/}
                  <button
                    class='btn btn-success  btn-md btn-block mt-2 mb-5'
                    type='submit'
                    onClick={handleSubmit}
                    id='submit'
                  >
                    Submit
                  </button>
                  {/*</form>*/}
                </div>
                {/* <!--/.Card-- br >*/}
              </div>
            </div>
            {/* <!--Grid row-->*/}
          </div>
        </div>
      </div>
      {/*<!-- Main layout -->*/}
    </>
  )
}

export default ApplyForCommunityProject
