import { useRef, useState, useEffect, useContext } from 'react'
import '../App.css'
import {
  faCheck,
  faTimes,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Axios from '../api/axios'
import $, { nodeName } from 'jquery'
import 'jquery/dist/jquery.min.js'
import { useNavigate } from 'react-router-dom'
import 'jquery/dist/jquery.min.js'
import 'datatables.net-dt/js/dataTables.dataTables'
import 'datatables.net-dt/css/jquery.dataTables.min.css'
import 'datatables.net-buttons/js/dataTables.buttons.js'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.flash.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'
//const USER_REGEX = /^[a-zAZ][a-zA-Z0-9_]{3,23}$/;
const QUANTITY_REGEX = /^\d{1,9}$/
//const PRICE_REGEX = /(?:^[1-9]([0-9]+)?(?:\.[0-9]{1,2})?$)|(?:^(?:0)$)|(?:^[0-9]\.[0-9](?:[0-9])?$)/ ;
const PRICE_REGEX = /^\d+(,\d{3})*(\.\d{2,2})?$/
//const PRICE_REGEX = /^(?!0+$)*[,\d{3}]*{0,10}$/;
//const EMAIL_REGEX = /^[^@]+@[^@]+\.[^@]{2,4}$/;

const Loan = () => {
  const server_app_api = window.SERVER_APP_API
  const server_app_api_php = window.SERVER_APP_API_PHP

  const [loanTenure, setLoanTenure] = useState('')
  const [loanAmount, setLoanAmount] = useState('')
  const [typeOfBusiness, setTypeOfBusiness] = useState('')
  const [nameOfBusinessMakingApplication, setNameOfBusinessMakingApplication] =
    useState('')
  const [province, setprovince] = useState('')
  const [provinceDropDown, setprovinceDropDown] = useState([])
  const [council, setcouncil] = useState('')
  const [councilDropDown, setcouncilDropDown] = useState([])
  const [constituency, setconstituency] = useState('')
  const [constituencyDropDown, setconstituencyDropDown] = useState([])
  const [ward, setward] = useState('')
  const [wardDropDown, setwardDropDown] = useState([])
  const [zone, setzone] = useState('')
  const [physicalAddress, setphysicalAddress] = useState('')
  const [
    descriptionOfCurrentBusinessActivities,
    setDescriptionOfCurrentBusinessActivities
  ] = useState('')
  const [legalFormOfApplicant, setlegalFormOfApplicant] = useState('')
  const [certificateOfRegistration, setCertificateOfRegistration] = useState('')
  const [isYourBusinessRegistered, setIsYourBusinessRegistered] = useState('')
  const [contactNumbersForApplicant, setContactNumbersForApplicant] =
    useState('')
  const [
    doYouHaveCopyOfBusinessRegistrationCertificate,
    setDoYouHaveCopyOfBusinessRegistrationCertificate
  ] = useState('')
  const [dateWhenBusinessWasRegistered, setDateWhenBusinessWasRegistered] =
    useState('')
  const [nextOfKinName, setNextOfKinName] = useState('')
  const [nextOfKinPhysicalAddress, setNextOfKinPhysicalAddress] = useState('')
  const [nextOfKinNrc, setNextOfKinNrc] = useState('')
  const [nextOfKinPhoneNumber, setNextOfKinPhoneNumber] = useState('')
  const [
    shareholdersOfTheBusinessFullNames,
    setShareholdersOfTheBusinessFullNames
  ] = useState([{}])
  const [
    shareholdersOfTheBusinessNationality,
    setShareholdersOfTheBusinessNationality
  ] = useState([{}])
  const [shareholdersOfTheBusinessNrc, setShareholdersOfTheBusinessNrc] =
    useState([{}])

  const [
    shareholdersOfTheBusinessNrcCopys,
    setShareholdersOfTheBusinessNrcCopys
  ] = useState([{}])

  const [
    shareholdersOfTheBusinessNrcCopy,
    setShareholdersOfTheBusinessNrcCopy
  ] = useState([{}])
  /*const saveShareholdersNRCs = e => {
    setShareholdersNRCs(e.target.files[0])
  }*/
  /*const handleUploadFiles = e => {
    var uploaded = []
    files.some(file => {
      uploaded.push(file)
    })
    setShareholdersOfTheBusinessNrcCopy(uploaded)
  }*/
  //const saveShareholdersNRCs = e => {
  //var uploaded = []
  //const chosenFiles = Array.prototype.slice.call(e.target.files[0])
  //uploaded.push(chosenFiles)
  //handleUploadFiles(chosenFiles)
  //setShareholdersOfTheBusinessNrcCopy(uploaded)
  //setShareholdersOfTheBusinessNrcCopy(e.target.files[0])
  //}

  const [
    shareholdersOfTheBusinessPositionInTheBusiness,
    setShareholdersOfTheBusinessPositionInTheBusiness
  ] = useState([{}])
  const [shareholdersOfTheBusinessShares, setShareholdersOfTheBusinessShares] =
    useState([{}])
  const [
    shareholdersOfTheBusinessDisabled,
    setShareholdersOfTheBusinessDisabled
  ] = useState([{}])
  const [
    shareholdersOfTheBusinessInputList,
    setShareholdersOfTheBusinessInputList
  ] = useState([
    {
      shareholdersOfTheBusinessFullNames: '',
      shareholdersOfTheBusinessNationality: '',
      shareholdersOfTheBusinessNrc: '',
      shareholdersOfTheBusinessNrcCopy: '',
      shareholdersOfTheBusinessPositionInTheBusiness: '',
      shareholdersOfTheBusinessShares: '',
      shareholdersOfTheBusinessDisabled: ''
    }
  ])
  var shareholdersOfTheBusinessFullNamesArr = []
  var shareholdersOfTheBusinessNationalityArr = []
  var shareholdersOfTheBusinessNrcArr = []
  var shareholdersOfTheBusinessNrcCopyArr = []
  var shareholdersOfTheBusinessPositionInTheBusinessArr = []
  var shareholdersOfTheBusinessSharesArr = []
  var shareholdersOfTheBusinessDisabledArr = []

  const handleShareholdersOfTheBusinessInputChange = (
    e,
    shareholdersOfTheBusinessIndex
  ) => {
    const { name, value } = e.target
    const shareholdersOfTheBusinessList = [
      ...shareholdersOfTheBusinessInputList
    ]
    shareholdersOfTheBusinessList[shareholdersOfTheBusinessIndex][name] = value
    setShareholdersOfTheBusinessInputList(shareholdersOfTheBusinessList)

    shareholdersOfTheBusinessList.map(shareholdersOfTheBusinessVal => {
      shareholdersOfTheBusinessFullNamesArr.push(
        shareholdersOfTheBusinessVal.shareholdersOfTheBusinessFullNames
      )
      shareholdersOfTheBusinessNationalityArr.push(
        shareholdersOfTheBusinessVal.shareholdersOfTheBusinessNationality
      )
      shareholdersOfTheBusinessNrcArr.push(
        shareholdersOfTheBusinessVal.shareholdersOfTheBusinessNrc
      )
      shareholdersOfTheBusinessNrcCopyArr.push(
        shareholdersOfTheBusinessVal.shareholdersOfTheBusinessNrcCopy
      )

      shareholdersOfTheBusinessPositionInTheBusinessArr.push(
        shareholdersOfTheBusinessVal.shareholdersOfTheBusinessPositionInTheBusiness
      )
      shareholdersOfTheBusinessSharesArr.push(
        shareholdersOfTheBusinessVal.shareholdersOfTheBusinessShares
      )
      shareholdersOfTheBusinessDisabledArr.push(
        shareholdersOfTheBusinessVal.shareholdersOfTheBusinessDisabled
      )
    })
    localStorage.setItem(
      'shareholdersOfTheBusinessFullNames',
      JSON.stringify(shareholdersOfTheBusinessFullNamesArr)
    )
    localStorage.setItem(
      'shareholdersOfTheBusinessNationality',
      JSON.stringify(shareholdersOfTheBusinessNationalityArr)
    )
    localStorage.setItem(
      'shareholdersOfTheBusinessNrc',
      JSON.stringify(shareholdersOfTheBusinessNrcArr)
    )
    localStorage.setItem(
      'shareholdersOfTheBusinessNrcCopy',
      JSON.stringify(shareholdersOfTheBusinessNrcCopyArr)
    )
    localStorage.setItem(
      'shareholdersOfTheBusinessPositionInTheBusiness',
      JSON.stringify(shareholdersOfTheBusinessPositionInTheBusinessArr)
    )
    localStorage.setItem(
      'shareholdersOfTheBusinessShares',
      JSON.stringify(shareholdersOfTheBusinessSharesArr)
    )
    localStorage.setItem(
      'shareholdersOfTheBusinessDisabled',
      JSON.stringify(shareholdersOfTheBusinessDisabledArr)
    )
    //setItemID(JSON.stringify(rodeen))
    setShareholdersOfTheBusinessFullNames(
      JSON.stringify(shareholdersOfTheBusinessFullNamesArr)
    )
    setShareholdersOfTheBusinessNationality(
      JSON.stringify(shareholdersOfTheBusinessNationalityArr)
    )
    setShareholdersOfTheBusinessNrc(
      JSON.stringify(shareholdersOfTheBusinessNrcArr)
    )
    setShareholdersOfTheBusinessNrcCopy(
      JSON.stringify(shareholdersOfTheBusinessNrcCopyArr)
    )
    setShareholdersOfTheBusinessPositionInTheBusiness(
      JSON.stringify(shareholdersOfTheBusinessPositionInTheBusinessArr)
    )
    setShareholdersOfTheBusinessShares(
      JSON.stringify(shareholdersOfTheBusinessSharesArr)
    )
    setShareholdersOfTheBusinessDisabled(
      JSON.stringify(shareholdersOfTheBusinessDisabledArr)
    )
  }

  // handle click event of the Remove button
  const handleRemoveClickShareholdersOfTheBusiness =
    shareholdersOfTheBusinessIndex => {
      const shareholdersOfTheBusinessList = [
        ...shareholdersOfTheBusinessInputList
      ]
      shareholdersOfTheBusinessList.splice(shareholdersOfTheBusinessIndex, 1)
      setShareholdersOfTheBusinessInputList(shareholdersOfTheBusinessList)

      ///var rodeens = []
      shareholdersOfTheBusinessList.map(shareholdersOfTheBusinessVal => {
        shareholdersOfTheBusinessFullNamesArr.push(
          shareholdersOfTheBusinessVal.shareholdersOfTheBusinessFullNames
        )
        shareholdersOfTheBusinessNationalityArr.push(
          shareholdersOfTheBusinessVal.shareholdersOfTheBusinessNationality
        )
        shareholdersOfTheBusinessNrcArr.push(
          shareholdersOfTheBusinessVal.shareholdersOfTheBusinessNrc
        )
        shareholdersOfTheBusinessNrcCopyArr.push(
          shareholdersOfTheBusinessVal.shareholdersOfTheBusinessNrcCopy
        )
        shareholdersOfTheBusinessPositionInTheBusinessArr.push(
          shareholdersOfTheBusinessVal.shareholdersOfTheBusinessPositionInTheBusiness
        )
        shareholdersOfTheBusinessSharesArr.push(
          shareholdersOfTheBusinessVal.shareholdersOfTheBusinessShares
        )
        shareholdersOfTheBusinessDisabledArr.push(
          shareholdersOfTheBusinessVal.shareholdersOfTheBusinessDisabled
        )
      })
      localStorage.setItem(
        'shareholdersOfTheBusinessFullNames',
        JSON.stringify(shareholdersOfTheBusinessFullNamesArr)
      )
      localStorage.setItem(
        'shareholdersOfTheBusinessNationality',
        JSON.stringify(shareholdersOfTheBusinessNationalityArr)
      )
      localStorage.setItem(
        'shareholdersOfTheBusinessNrc',
        JSON.stringify(shareholdersOfTheBusinessNrcArr)
      )
      localStorage.setItem(
        'shareholdersOfTheBusinessNrcCopy',
        JSON.stringify(shareholdersOfTheBusinessNrcCopyArr)
      )
      localStorage.setItem(
        'shareholdersOfTheBusinessPositionInTheBusiness',
        JSON.stringify(shareholdersOfTheBusinessPositionInTheBusinessArr)
      )
      localStorage.setItem(
        'shareholdersOfTheBusinessShares',
        JSON.stringify(shareholdersOfTheBusinessSharesArr)
      )
      localStorage.setItem(
        'shareholdersOfTheBusinessDisabled',
        JSON.stringify(shareholdersOfTheBusinessDisabledArr)
      )
      //setItemID(JSON.stringify(rodeen))
      setShareholdersOfTheBusinessFullNames(
        JSON.stringify(shareholdersOfTheBusinessFullNamesArr)
      )
      setShareholdersOfTheBusinessNationality(
        JSON.stringify(shareholdersOfTheBusinessNationalityArr)
      )
      setShareholdersOfTheBusinessNrc(
        JSON.stringify(shareholdersOfTheBusinessNrcArr)
      )
      setShareholdersOfTheBusinessNrcCopy(
        JSON.stringify(shareholdersOfTheBusinessNrcCopyArr)
      )
      setShareholdersOfTheBusinessPositionInTheBusiness(
        JSON.stringify(shareholdersOfTheBusinessPositionInTheBusinessArr)
      )
      setShareholdersOfTheBusinessShares(
        JSON.stringify(shareholdersOfTheBusinessSharesArr)
      )
      setShareholdersOfTheBusinessDisabled(
        JSON.stringify(shareholdersOfTheBusinessDisabledArr)
      )
    }
  // handle click event of the Add button
  const handleAddClickShareholdersOfTheBusiness = () => {
    setShareholdersOfTheBusinessInputList([
      ...shareholdersOfTheBusinessInputList,
      {
        shareholdersOfTheBusinessFullNames: '',
        shareholdersOfTheBusinessNationality: '',
        shareholdersOfTheBusinessNrc: '',
        shareholdersOfTheBusinessNrcCopy: '',
        shareholdersOfTheBusinessPositionInTheBusiness: '',
        shareholdersOfTheBusinessShares: '',
        shareholdersOfTheBusinessDisabled: ''
      }
    ])
  }

  const [
    authorizedRepresentativeNameOfContactPerson,
    setAuthorizedRepresentativeNameOfContactPerson
  ] = useState('')
  const [
    authorizedRepresentativePositionInTheBusiness,
    setAuthorizedRepresentativePositionInTheBusiness
  ] = useState('')
  const [authorizedRepresentativeCell, setAuthorizedRepresentativeCell] =
    useState('')
  const [
    authorizedRepresentativeTelephone,
    setAuthorizedRepresentativeTelephone
  ] = useState('')
  const [authorizedRepresentativeEmail, setAuthorizedRepresentativeEmail] =
    useState('')
  const [previousCDFFunding, setPreviousCDFFunding] = useState('')
  const [previousCDFFundingStatusOfLoan, setPreviousCDFFundingStatusOfLoan] =
    useState('')
  const [
    previousCDFFundingNameOfBusiness,
    setPreviousCDFFundingNameOfBusiness
  ] = useState('')
  const [
    previousCDFFundingTypeOfRegistrationOfBusiness,
    setPreviousCDFFundingTypeOfRegistrationOfBusiness
  ] = useState('')
  const [bankDetailsBankName, setBankDetailsBankName] = useState('')
  const [bankDetailsBranch, setBankDetailsBranch] = useState('')
  const [bankDetailsBranchCode, setBankDetailsBranchCode] = useState('')
  const [bankDetailsSwiftCode, setbankDetailsSwiftCode] = useState('')
  const [bankDetailsAccountNumber, setbankDetailsAccountNumber] = useState('')
  const [bankDetailsTpin, setbankDetailsTpin] = useState('')
  const [
    bankDetailsMobileMoneyNameAndNumber,
    setbankDetailsMobileMoneyNameAndNumber
  ] = useState('')
  const [
    financialDataWhatIsTheMonthlyIncomeOfTheCurrentBusiness,
    setFinancialDataWhatIsTheMonthlyIncomeOfTheCurrentBusiness
  ] = useState('')
  const [
    financialDataWhatIsTheMonthlyExpensesOfTheCurrenbusiness,
    setFinancialDataWhatIsTheMonthlyExpensesOfTheCurrenbusiness
  ] = useState('')
  const [
    financialDataHowMuchHaveYouInvestedInTheCurrentBusiness,
    setFinancialDataHowMuchHaveYouInvestedInTheCurrentBusiness
  ] = useState('')
  const [marketOverviewTrendsInDemand, setMarketOverviewTrendsInDemand] =
    useState('')
  const [marketOverviewTrendsInSupply, setMarketOverviewTrendsInSupply] =
    useState('')
  const [
    businessOperationsWhatProductsAndOrWervicesWillYouBeSellingToYourCustomers,
    setBusinessOperationsWhatProductsAndOrWervicesWillYouBeSellingToYourCustomers
  ] = useState('')
  const [businessOperationsLocation, setBusinessOperationsLocation] =
    useState('')
  const [
    businessOperationsWhatkindOfEquipmentWillBeNeededForTheOperations,
    setBusinessOperationsWhatkindOfEquipmentWillBeNeededForTheOperations
  ] = useState('')
  const [
    businessOperationsWhatWillSuchTypesOfEquipmentBeUsedForInTheOperations,
    setBusinessOperationsWhatWillSuchTypesOfEquipmentBeUsedForInTheOperations
  ] = useState('')
  const [
    businessOperationsWhereWillYouSecureRawMaterialsFromAndHow,
    setBusinessOperationsWhereWillYouSecureRawMaterialsFromAndHow
  ] = useState('')
  const [
    businessOperationsWhatWillBeYourCostOfProductionPerMonth,
    setBusinessOperationsWhatWillBeYourCostOfProductionPerMonth
  ] = useState('')
  const [
    businessOperationsWhatWillBeThePriceOfYourProductsOrServices,
    setBusinessOperationsWhatWillBeThePriceOfYourProductsOrServices
  ] = useState('')
  const [
    businessOperationsWhatWillBeYourIncomePerMonthFromTheBusinessOperations,
    setBusinessOperationsWhatWillBeYourIncomePerMonthFromTheBusinessOperations
  ] = useState('')
  const [
    businessOperationsWhatWillBeYourLevelOfProductionPerMonthOrYear,
    setBusinessOperationsWhatWillBeYourLevelOfProductionPerMonthOrYear
  ] = useState('')
  const [
    businessOperationsWhoWillBeYourMajorCompetitors,
    setBusinessOperationsWhoWillBeYourMajorCompetitors
  ] = useState('')

  const [proposedManagementTeamFullName, setProposedManagementTeamFullName] =
    useState([{}])
  const [
    proposedManagementTeamManagementPosition,
    setProposedManagementTeamManagementPosition
  ] = useState([{}])
  const [
    proposedManagementTeamManagementQualificationAndExperience,
    setProposedManagementTeamManagementQualificationAndExperience
  ] = useState([{}])
  const [proposedManagementTeamInputList, setProposedManagementTeamInputList] =
    useState([
      {
        proposedManagementTeamFullName: '',
        proposedManagementTeamManagementPosition: '',
        proposedManagementTeamManagementQualificationAndExperience: ''
      }
    ])
  var proposedManagementTeamFullNameArr = []
  var proposedManagementTeamManagementPositionArr = []
  var proposedManagementTeamManagementQualificationAndExperienceArr = []

  const handleProposedManagementTeamInputChange = (
    e,
    proposedManagementTeamIndex
  ) => {
    const { name, value } = e.target
    const proposedManagementTeamList = [...proposedManagementTeamInputList]
    proposedManagementTeamList[proposedManagementTeamIndex][name] = value
    setProposedManagementTeamInputList(proposedManagementTeamList)

    proposedManagementTeamList.map(proposedManagementTeamVal => {
      proposedManagementTeamFullNameArr.push(
        proposedManagementTeamVal.proposedManagementTeamFullName
      )
      proposedManagementTeamManagementPositionArr.push(
        proposedManagementTeamVal.proposedManagementTeamManagementPosition
      )
      proposedManagementTeamManagementQualificationAndExperienceArr.push(
        proposedManagementTeamVal.proposedManagementTeamManagementQualificationAndExperience
      )
    })
    localStorage.setItem(
      'proposedManagementTeamFullName',
      JSON.stringify(proposedManagementTeamFullNameArr)
    )
    localStorage.setItem(
      'proposedManagementTeamManagementPosition',
      JSON.stringify(proposedManagementTeamManagementPositionArr)
    )
    localStorage.setItem(
      'proposedManagementTeamManagementQualificationAndExperience',
      JSON.stringify(
        proposedManagementTeamManagementQualificationAndExperienceArr
      )
    )
    //setItemID(JSON.stringify(rodeen))
    setProposedManagementTeamFullName(
      JSON.stringify(proposedManagementTeamFullNameArr)
    )
    setProposedManagementTeamManagementPosition(
      JSON.stringify(proposedManagementTeamManagementPositionArr)
    )
    setProposedManagementTeamManagementQualificationAndExperience(
      JSON.stringify(
        proposedManagementTeamManagementQualificationAndExperienceArr
      )
    )
  }

  // handle click event of the Remove button
  const handleRemoveClickProposedManagementTeam =
    proposedManagementTeamIndex => {
      const proposedManagementTeamList = [...proposedManagementTeamInputList]
      proposedManagementTeamList.splice(proposedManagementTeamIndex, 1)
      setProposedManagementTeamInputList(proposedManagementTeamList)

      ///var rodeens = []
      proposedManagementTeamList.map(proposedManagementTeamVal => {
        proposedManagementTeamFullNameArr.push(
          proposedManagementTeamVal.proposedManagementTeamFullName
        )
        proposedManagementTeamManagementPositionArr.push(
          proposedManagementTeamVal.proposedManagementTeamManagementPosition
        )
        proposedManagementTeamManagementQualificationAndExperienceArr.push(
          proposedManagementTeamVal.proposedManagementTeamManagementQualificationAndExperience
        )
      })
      localStorage.setItem(
        'proposedManagementTeamFullName',
        JSON.stringify(proposedManagementTeamFullNameArr)
      )
      localStorage.setItem(
        'proposedManagementTeamManagementPosition',
        JSON.stringify(proposedManagementTeamManagementPositionArr)
      )
      localStorage.setItem(
        'proposedManagementTeamManagementQualificationAndExperience',
        JSON.stringify(
          proposedManagementTeamManagementQualificationAndExperienceArr
        )
      )
      //setItemID(JSON.stringify(rodeen))
      setProposedManagementTeamFullName(
        JSON.stringify(shareholdersOfTheBusinessFullNamesArr)
      )
      setProposedManagementTeamManagementPosition(
        JSON.stringify(proposedManagementTeamManagementPositionArr)
      )
      setProposedManagementTeamManagementQualificationAndExperience(
        JSON.stringify(
          proposedManagementTeamManagementQualificationAndExperienceArr
        )
      )
    }
  // handle click event of the Add button
  const handleAddClickProposedManagementTeam = () => {
    setProposedManagementTeamInputList([
      ...proposedManagementTeamInputList,
      {
        proposedManagementTeamFullName: '',
        proposedManagementTeamManagementPosition: '',
        proposedManagementTeamManagementQualificationAndExperience: ''
      }
    ])
  }

  const [
    howWillYourCustomersBenefitFromYourBusiness,
    setHowWillYourCustomersBenefitFromYourBusiness
  ] = useState('')
  const [
    howWillYourSuppliersBenefitFromYourBusiness,
    setHowWillYourSuppliersBenefitFromYourBusiness
  ] = useState('')
  const [
    numberOfWorkersBeforeCDFFundingWomen,
    setNumberOfWorkersBeforeCDFFundingWomen
  ] = useState('')
  const [
    numberOfWorkersBeforeCDFFundingMen,
    setNumberOfWorkersBeforeCDFFundingMen
  ] = useState('')
  const [
    numberOfWorkersBeforeCDFFundingYouth,
    setNumberOfWorkersBeforeCDFFundingYouth
  ] = useState('')
  const [
    numberOfWorkersBeforeCDFFundingDisabled,
    setNumberOfWorkersBeforeCDFFundingDisabled
  ] = useState('')

  const [
    numberOfWorkersAfterCDFFundingWomen,
    setNumberOfWorkersAfterCDFFundingWomen
  ] = useState('')
  const [
    numberOfWorkersAfterCDFFundingMen,
    setNumberOfWorkersAfterCDFFundingMen
  ] = useState('')
  const [
    numberOfWorkersAfterCDFFundingYouth,
    setNumberOfWorkersAfterCDFFundingYouth
  ] = useState('')
  const [
    numberOfWorkersAfterCDFFundingDisabled,
    setNumberOfWorkersAfterCDFFundingDisabled
  ] = useState('')

  const [projectBudgetItem, setProjectBudgetItem] = useState([{}])
  const [projectBudgetQuantity, setProjectBudgetQuantity] = useState([{}])
  const [projectBudgetCost, setProjectBudgetCost] = useState([{}])
  const [projectBudgetTotal, setProjectBudgetTotal] = useState([{}])

  const [projectBudgetInputList, setProjectBudgetInputList] = useState([
    {
      projectBudgetItem: '',
      projectBudgetQuantity: '',
      projectBudgetCost: '',
      projectBudgetTotal: ''
    }
  ])
  var projectBudgetItemArr = []
  var projectBudgetQuantityArr = []
  var projectBudgetCostArr = []
  var projectBudgetTotalArr = []

  const handleProjectBudgetInputChange = (e, projectBudgetIndex) => {
    const { name, value } = e.target
    const projectBudgetList = [...projectBudgetInputList]
    projectBudgetList[projectBudgetIndex][name] = value
    setProjectBudgetInputList(projectBudgetList)

    projectBudgetList.map(projectBudgetVal => {
      projectBudgetItemArr.push(projectBudgetVal.projectBudgetItem)
      projectBudgetQuantityArr.push(projectBudgetVal.projectBudgetQuantity)
      projectBudgetCostArr.push(projectBudgetVal.projectBudgetCost)
      projectBudgetTotalArr.push(projectBudgetVal.projectBudgetTotal)
    })
    localStorage.setItem(
      'projectBudgetItem',
      JSON.stringify(projectBudgetItemArr)
    )
    localStorage.setItem(
      'projectBudgetQuantity',
      JSON.stringify(projectBudgetQuantityArr)
    )
    localStorage.setItem(
      'projectBudgetCost',
      JSON.stringify(projectBudgetCostArr)
    )
    localStorage.setItem(
      'projectBudgetTotal',
      JSON.stringify(projectBudgetTotalArr)
    )
    //setItemID(JSON.stringify(rodeen))
    setProjectBudgetItem(JSON.stringify(projectBudgetItemArr))
    setProjectBudgetQuantity(JSON.stringify(projectBudgetQuantityArr))
    setProjectBudgetCost(JSON.stringify(projectBudgetCostArr))
    setProjectBudgetTotal(JSON.stringify(projectBudgetTotalArr))
  }

  // handle click event of the Remove button
  const handleRemoveClickProjectBudget = projectBudgetIndex => {
    const projectBudgetList = [...projectBudgetInputList]
    projectBudgetList.splice(projectBudgetIndex, 1)
    setProjectBudgetInputList(projectBudgetList)

    ///var rodeens = []
    projectBudgetList.map(projectBudgetVal => {
      projectBudgetItemArr.push(projectBudgetVal.projectBudgetItem)
      projectBudgetQuantityArr.push(projectBudgetVal.projectBudgetQuantity)
      projectBudgetCostArr.push(projectBudgetVal.projectBudgetCost)
      projectBudgetTotalArr.push(projectBudgetVal.projectBudgetTotal)
    })
    localStorage.setItem(
      'projectBudgetItem',
      JSON.stringify(projectBudgetItemArr)
    )
    localStorage.setItem(
      'projectBudgetQuantity',
      JSON.stringify(projectBudgetQuantityArr)
    )
    localStorage.setItem(
      'projectBudgetCost',
      JSON.stringify(projectBudgetCostArr)
    )
    localStorage.setItem(
      'projectBudgetTotal',
      JSON.stringify(projectBudgetTotalArr)
    )
    //setItemID(JSON.stringify(rodeen))
    setProjectBudgetItem(JSON.stringify(projectBudgetItemArr))
    setProjectBudgetQuantity(JSON.stringify(projectBudgetQuantityArr))
    setProjectBudgetCost(JSON.stringify(projectBudgetCostArr))
    setProjectBudgetTotal(JSON.stringify(projectBudgetTotalArr))
  }
  // handle click event of the Add button
  const handleAddClickProjectBudget = () => {
    setProjectBudgetInputList([
      ...projectBudgetInputList,
      {
        projectBudgetItem: '',
        projectBudgetQuantity: '',
        projectBudgetCost: '',
        projectBudgetTotal: ''
      }
    ])
  }

  const [clientContributionItem, setClientContributionItem] = useState([{}])
  const [clientContributionQuantity, setClientContributionQuantity] = useState([
    {}
  ])
  const [clientContributionCost, setClientContributionCost] = useState([{}])
  const [clientContributionTotal, setClientContributionTotal] = useState([{}])

  const [clientContributionInputList, setClientContributionInputList] =
    useState([
      {
        clientContributionItem: '',
        clientContributionQuantity: '',
        clientContributionCost: '',
        clientContributionTotal: ''
      }
    ])
  var clientContributionItemArr = []
  var clientContributionQuantityArr = []
  var clientContributionCostArr = []
  var clientContributionTotalArr = []

  const handleClientContributionInputChange = (e, clientContributionIndex) => {
    const { name, value } = e.target
    const clientContributionList = [...clientContributionInputList]
    clientContributionList[clientContributionIndex][name] = value
    setClientContributionInputList(clientContributionList)

    clientContributionList.map(clientContributionVal => {
      clientContributionItemArr.push(
        clientContributionVal.clientContributionItem
      )
      clientContributionQuantityArr.push(
        clientContributionVal.clientContributionQuantity
      )
      clientContributionCostArr.push(
        clientContributionVal.clientContributionCost
      )
      clientContributionTotalArr.push(
        clientContributionVal.clientContributionTotal
      )
    })
    localStorage.setItem(
      'clientContributionItem',
      JSON.stringify(clientContributionItemArr)
    )
    localStorage.setItem(
      'clientContributionQuantity',
      JSON.stringify(clientContributionQuantityArr)
    )
    localStorage.setItem(
      'clientContributionCost',
      JSON.stringify(clientContributionCostArr)
    )
    localStorage.setItem(
      'clientContributionTotal',
      JSON.stringify(clientContributionTotalArr)
    )
    //setItemID(JSON.stringify(rodeen))
    setClientContributionItem(JSON.stringify(clientContributionItemArr))
    setClientContributionQuantity(JSON.stringify(clientContributionQuantityArr))
    setClientContributionCost(JSON.stringify(clientContributionCostArr))
    setClientContributionTotal(JSON.stringify(clientContributionTotalArr))
  }

  // handle click event of the Remove button
  const handleRemoveClickClientContribution = clientContributionIndex => {
    const clientContributionList = [...clientContributionInputList]
    clientContributionList.splice(clientContributionIndex, 1)
    setClientContributionInputList(clientContributionList)

    ///var rodeens = []
    clientContributionList.map(clientContributionVal => {
      clientContributionItemArr.push(
        clientContributionVal.clientContributionItem
      )
      clientContributionQuantityArr.push(
        clientContributionVal.clientContributionQuantity
      )
      clientContributionCostArr.push(
        clientContributionVal.clientContributionCost
      )
      clientContributionTotalArr.push(
        clientContributionVal.clientContributionTotal
      )
    })
    localStorage.setItem(
      'clientContributionItem',
      JSON.stringify(clientContributionItemArr)
    )
    localStorage.setItem(
      'clientContributionQuantity',
      JSON.stringify(clientContributionQuantityArr)
    )
    localStorage.setItem(
      'clientContributionCost',
      JSON.stringify(clientContributionCostArr)
    )
    localStorage.setItem(
      'clientContributionTotal',
      JSON.stringify(clientContributionTotalArr)
    )
    //setItemID(JSON.stringify(rodeen))
    setClientContributionItem(JSON.stringify(clientContributionItemArr))
    setClientContributionQuantity(JSON.stringify(clientContributionQuantityArr))
    setClientContributionCost(JSON.stringify(clientContributionCostArr))
    setClientContributionTotal(JSON.stringify(clientContributionTotalArr))
  }
  // handle click event of the Add button
  const handleAddClickClientContribution = () => {
    setClientContributionInputList([
      ...clientContributionInputList,
      {
        clientContributionItem: '',
        clientContributionQuantity: '',
        clientContributionCost: '',
        clientContributionTotal: ''
      }
    ])
  }

  const [capitalThresholdRepaymentPeriod, setCapitalThresholdRepaymentPeriod] =
    useState('')
  const [
    ifYouAreFundedWhenWouldOperationsStart,
    setIfYouAreFundedWhenWouldOperationsStart
  ] = useState('')

  const [declarationApplicantDate, setDeclarationApplicantDate] = useState('')
  const [declarationApplicantDay, setDeclarationApplicantDay] = useState('')
  //
  const saveCertificateOfRegistration = e => {
    setCertificateOfRegistration(e.target.files[0])
  }
  /*const saveShareholdersNRCs = e => {
    setShareholdersOfTheBusinessNrcCopy(e.target.files[0])
  }*/
  useEffect(() => {
    var formDataViewProvinces = new FormData(this)
    formDataViewProvinces.append('apiType', 'getViewProvincesDropDown')
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataViewProvinces,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setprovinceDropDown(response.data)
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [])

  useEffect(() => {
    var formDataViewCouncils = new FormData(this)
    formDataViewCouncils.append('apiType', 'getViewCouncilsDropDown')
    formDataViewCouncils.append('provincesID', province)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataViewCouncils,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(res => {
      setcouncilDropDown(res.data)
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [province]) //councilDropDown

  useEffect(() => {
    var formDataViewConstituencies = new FormData(this)
    formDataViewConstituencies.append(
      'apiType',
      'getViewConstituenciesDropDown'
    )
    formDataViewConstituencies.append('councilsID', council)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataViewConstituencies,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(res => {
      setconstituencyDropDown(res.data)
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [province, council])

  useEffect(() => {
    var formDataViewWards = new FormData(this)
    formDataViewWards.append('apiType', 'getViewWardsDropDown')
    formDataViewWards.append('constituenciesID', constituency)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataViewWards,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(res => {
      setwardDropDown(res.data)
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [province, council, constituency])

  const handleSubmit = async e => {
    var formData = new FormData(this)
    formData.append('apiType', 'uploadLoanApplicationForm')
    formData.append('loanTenure', loanTenure)
    formData.append('loanAmount', loanAmount)
    formData.append('typeOfBusiness', typeOfBusiness)
    formData.append(
      'nameOfBusinessMakingApplication',
      nameOfBusinessMakingApplication
    )
    formData.append('province', province)
    formData.append('council', council)
    formData.append('constituency', constituency)
    formData.append('ward', ward)
    formData.append('zone', zone)
    formData.append('physicalAddress', physicalAddress)
    formData.append(
      'descriptionOfCurrentBusinessActivities',
      descriptionOfCurrentBusinessActivities
    )
    formData.append('legalFormOfApplicant', legalFormOfApplicant)
    formData.append('certificateOfRegistration', certificateOfRegistration)
    formData.append('isYourBusinessRegistered', isYourBusinessRegistered)
    formData.append('contactNumbersForApplicant', contactNumbersForApplicant)
    formData.append(
      'doYouHaveCopyOfBusinessRegistrationCertificate',
      doYouHaveCopyOfBusinessRegistrationCertificate
    )
    formData.append(
      'dateWhenBusinessWasRegistered',
      dateWhenBusinessWasRegistered
    )
    formData.append('nextOfKinName', nextOfKinName)
    formData.append('nextOfKinPhysicalAddress', nextOfKinPhysicalAddress)
    formData.append('nextOfKinNrc', nextOfKinNrc)
    formData.append('nextOfKinPhoneNumber', nextOfKinPhoneNumber)
    formData.append(
      'shareholdersOfTheBusinessFullNames',
      shareholdersOfTheBusinessFullNames
    )
    formData.append(
      'shareholdersOfTheBusinessNationality',
      shareholdersOfTheBusinessNationality
    )
    formData.append(
      'shareholdersOfTheBusinessNrc',
      shareholdersOfTheBusinessNrc
    )
    formData.append(
      'shareholdersOfTheBusinessNrcCopy',
      shareholdersOfTheBusinessNrcCopy
    )
    formData.append(
      'shareholdersOfTheBusinessPositionInTheBusiness',
      shareholdersOfTheBusinessPositionInTheBusiness
    )
    formData.append(
      'shareholdersOfTheBusinessShares',
      shareholdersOfTheBusinessShares
    )
    formData.append(
      'shareholdersOfTheBusinessDisabled',
      shareholdersOfTheBusinessDisabled
    )
    formData.append(
      'authorizedRepresentativeNameOfContactPerson',
      authorizedRepresentativeNameOfContactPerson
    )
    formData.append(
      'authorizedRepresentativePositionInTheBusiness',
      authorizedRepresentativePositionInTheBusiness
    )
    formData.append(
      'authorizedRepresentativeCell',
      authorizedRepresentativeCell
    )
    formData.append(
      'authorizedRepresentativeTelephone',
      authorizedRepresentativeTelephone
    )
    formData.append(
      'authorizedRepresentativeEmail',
      authorizedRepresentativeEmail
    )
    formData.append('previousCDFFunding', previousCDFFunding)
    formData.append(
      'previousCDFFundingStatusOfLoan',
      previousCDFFundingStatusOfLoan
    )
    formData.append(
      'previousCDFFundingNameOfBusiness',
      previousCDFFundingNameOfBusiness
    )
    formData.append(
      'previousCDFFundingTypeOfRegistrationOfBusiness',
      previousCDFFundingTypeOfRegistrationOfBusiness
    )
    formData.append('bankDetailsBankName', bankDetailsBankName)
    formData.append('bankDetailsBranch', bankDetailsBranch)
    formData.append('bankDetailsBranchCode', bankDetailsBranchCode)
    formData.append('bankDetailsSwiftCode', bankDetailsSwiftCode)
    formData.append('bankDetailsAccountNumber', bankDetailsAccountNumber)
    formData.append('bankDetailsTpin', bankDetailsTpin)
    formData.append(
      'bankDetailsMobileMoneyNameAndNumber',
      bankDetailsMobileMoneyNameAndNumber
    )
    formData.append(
      'financialDataWhatIsTheMonthlyIncomeOfTheCurrentBusiness',
      financialDataWhatIsTheMonthlyIncomeOfTheCurrentBusiness
    )
    formData.append(
      'financialDataWhatIsTheMonthlyExpensesOfTheCurrenbusiness',
      financialDataWhatIsTheMonthlyExpensesOfTheCurrenbusiness
    )
    formData.append(
      'financialDataHowMuchHaveYouInvestedInTheCurrentBusiness',
      financialDataHowMuchHaveYouInvestedInTheCurrentBusiness
    )
    formData.append(
      'marketOverviewTrendsInDemand',
      marketOverviewTrendsInDemand
    )
    formData.append(
      'marketOverviewTrendsInSupply',
      marketOverviewTrendsInSupply
    )
    formData.append(
      'businessOperationsWhatProductsAndOrWervicesWillYouBeSellingToYourCustomers',
      businessOperationsWhatProductsAndOrWervicesWillYouBeSellingToYourCustomers
    )
    formData.append('businessOperationsLocation', businessOperationsLocation)
    formData.append(
      'businessOperationsWhatkindOfEquipmentWillBeNeededForTheOperations',
      businessOperationsWhatkindOfEquipmentWillBeNeededForTheOperations
    )
    formData.append(
      'businessOperationsWhatWillSuchTypesOfEquipmentBeUsedForInTheOperations',
      businessOperationsWhatWillSuchTypesOfEquipmentBeUsedForInTheOperations
    )
    formData.append(
      'businessOperationsWhereWillYouSecureRawMaterialsFromAndHow',
      businessOperationsWhereWillYouSecureRawMaterialsFromAndHow
    )
    formData.append(
      'businessOperationsWhatWillBeYourCostOfProductionPerMonth',
      businessOperationsWhatWillBeYourCostOfProductionPerMonth
    )
    formData.append(
      'businessOperationsWhatWillBeThePriceOfYourProductsOrServices',
      businessOperationsWhatWillBeThePriceOfYourProductsOrServices
    )
    formData.append(
      'businessOperationsWhatWillBeYourIncomePerMonthFromTheBusinessOperations',
      businessOperationsWhatWillBeYourIncomePerMonthFromTheBusinessOperations
    )
    formData.append(
      'businessOperationsWhatWillBeYourLevelOfProductionPerMonthOrYear',
      businessOperationsWhatWillBeYourLevelOfProductionPerMonthOrYear
    )
    formData.append(
      'businessOperationsWhoWillBeYourMajorCompetitors',
      businessOperationsWhoWillBeYourMajorCompetitors
    )
    formData.append(
      'proposedManagementTeamFullName',
      proposedManagementTeamFullName
    )
    formData.append(
      'proposedManagementTeamManagementPosition',
      proposedManagementTeamManagementPosition
    )
    formData.append(
      'proposedManagementTeamManagementQualificationAndExperience',
      proposedManagementTeamManagementQualificationAndExperience
    )
    formData.append(
      'howWillYourCustomersBenefitFromYourBusiness',
      howWillYourCustomersBenefitFromYourBusiness
    )
    formData.append(
      'howWillYourSuppliersBenefitFromYourBusiness',
      howWillYourSuppliersBenefitFromYourBusiness
    )
    formData.append(
      'numberOfWorkersBeforeCDFFundingWomen',
      numberOfWorkersBeforeCDFFundingWomen
    )
    formData.append(
      'numberOfWorkersBeforeCDFFundingMen',
      numberOfWorkersBeforeCDFFundingMen
    )
    formData.append(
      'numberOfWorkersBeforeCDFFundingYouth',
      numberOfWorkersBeforeCDFFundingYouth
    )
    formData.append(
      'numberOfWorkersBeforeCDFFundingDisabled',
      numberOfWorkersBeforeCDFFundingDisabled
    )
    formData.append(
      'numberOfWorkersAfterCDFFundingWomen',
      numberOfWorkersAfterCDFFundingWomen
    )
    formData.append(
      'numberOfWorkersAfterCDFFundingMen',
      numberOfWorkersAfterCDFFundingMen
    )
    formData.append(
      'numberOfWorkersAfterCDFFundingYouth',
      numberOfWorkersAfterCDFFundingYouth
    )
    formData.append(
      'numberOfWorkersAfterCDFFundingDisabled',
      numberOfWorkersAfterCDFFundingDisabled
    )
    formData.append('projectBudgetItem', projectBudgetItem)
    formData.append('projectBudgetQuantity', projectBudgetQuantity)
    formData.append('projectBudgetCost', projectBudgetCost)
    formData.append('projectBudgetTotal', projectBudgetTotal)
    formData.append('clientContributionItem', clientContributionItem)
    formData.append('clientContributionQuantity', clientContributionQuantity)
    formData.append('clientContributionCost', clientContributionCost)
    formData.append('clientContributionTotal', clientContributionTotal)
    formData.append(
      'capitalThresholdRepaymentPeriod',
      capitalThresholdRepaymentPeriod
    )
    formData.append(
      'ifYouAreFundedWhenWouldOperationsStart',
      ifYouAreFundedWhenWouldOperationsStart
    )
    formData.append('declarationApplicantDate', declarationApplicantDate)
    formData.append('declarationApplicantDay', declarationApplicantDay)

    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/uploadCDFApplication.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      //setPosTableTotal(response.data)
      alert(response.data)
    })
  }

  const currentDate = new Date()
  const dateToday = `${currentDate.getDate()}/${
    currentDate.getMonth() + 1
  }/${currentDate.getFullYear()}`
  const timeNow =
    currentDate.getHours() +
    ':' +
    currentDate.getMinutes() +
    ':' +
    currentDate.getSeconds()
  return (
    <>
      {/*<!-- Main layout -->*/}
      <div>
        <div class='container-fluid '>
          <div class='row ' style={{ paddingTop: '5%' }}>
            {/* <!--Grid column-->*/}
            <div class='col-md-12 mb-0.00001'>
              {/* <!--Card-->*/}
              <div class='card'>
                {/*<!--Card content-->*/}
                <div class='card-body'>
                  <div
                    class='form-header white-text'
                    style={{ backgroundColor: 'green' }}
                  >
                    <center>
                      CONSTITUENCY DEVELOPMENT FUND - INFORMATION MANAGEMENT
                      SYSTEM
                    </center>
                  </div>
                  <div
                    style={{
                      top: '0',
                      left: '0',
                      marginBottom: '2%',
                      float: 'left'
                    }}
                  >
                    <i class='fas fa-home'></i>
                    <a href='/' style={{ color: 'blue' }}>
                      Home
                    </a>
                  </div>
                  <br /> <br />
                  <section>
                    <div class='text-left'>
                      <center>
                        {' '}
                        <img src={require('./img/coat of arms.jpg')} />
                        <strong>
                          <br />
                          CONSTITUENCY DEVELOPMENT FUND (CDF) <br /> LOAN
                          APPLICATION FORM
                        </strong>
                      </center>
                      <br />
                      <strong>Instructions:</strong> This application form
                      should be completed by the applicant and sent together
                      with supporting documents to the Chairperson, Ward
                      Development Committee. <br />
                      <u>
                        <b>NOTE:</b>
                      </u>{' '}
                      This form is not for sale.
                      <br />
                      <i>
                        Disclaimer: Completion of the form does not guarantee
                        the award of the Loan.
                      </i>
                    </div>

                    <br />
                    <div class='row' style={{ border: '1px solid black' }}>
                      <div class='col-lg-3 col-md-12 mb-1'>
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Loan Amount (ZMW) <span class='red-text'>*</span>{' '}
                          </label>
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            placeholder=''
                            value={loanAmount}
                            onChange={e => setLoanAmount(e.target.value)}
                            id='loanAmount'
                          />
                        </div>
                      </div>
                      {/*</div>
                    <br />
                    <div style={{ border: '1px solid black' }}>
                      <br />*/}
                      <div class='col-lg-2 col-md-12 mb-1'>
                        <div class='md text-left'>
                          <label for='registerFullName'>
                            Loan Tenure <span class='red-text '>*</span>{' '}
                          </label>
                          <br />
                          <select
                            class='custom-select d-block w-100'
                            value={loanTenure}
                            onChange={e => setLoanTenure(e.target.value)}
                            id='loanTenure'
                          >
                            <option value=''>Choose ....</option>
                            <option>12 months</option>
                            <option>18 months</option>
                            <option>24 months</option>
                            <option>36 months</option>
                            <option>48 months</option>
                          </select>
                        </div>
                      </div>
                      <div class='col-lg-2 col-md-12 mb-1 text-left'>
                        {/*<!--<div class="md-form">-->*/}
                        <label for='registerFullName'>
                          Application Date<span class='red-text'>*</span>{' '}
                        </label>
                        <input
                          type='text'
                          class='form-control'
                          placeholder={dateToday}
                          disabled
                        />
                        {/*<!--</div>-->*/}
                      </div>
                      <div class='col-lg-5 col-md-12 mb-1 text-left'>
                        <div class='md'>
                          <label for='registerFullName'>
                            Type Of Business{' '}
                            <span class='red-text'>
                              e.g Poultry, Piggery, Carpentry, Tailoring etc
                            </span>{' '}
                          </label>{' '}
                          <br />
                          <input
                            type='text'
                            class='form-control'
                            placeholder=''
                            value={typeOfBusiness}
                            onChange={e => setTypeOfBusiness(e.target.value)}
                            id='TypeOfBusiness'
                          />
                        </div>
                      </div>
                    </div>

                    <br />
                  </section>
                  {/*<!--\form heading -->*/}
                  <div class='card-header' style={{ backgroundColor: 'green' }}>
                    <center>
                      <h6 class='white-text'>
                        SECTION A: GENERAL DETAILS{' '}
                        <span class='yellow-text'>
                          (To be filled by the applicant)
                        </span>{' '}
                      </h6>
                    </center>
                  </div>
                  <br />
                  <div id='divShowMessageFromAjax'></div>
                  {/*<!--Grid row-->			*/}
                  <div class='row text-left'>
                    {/*<!--Grid column-->*/}
                    <div class='col-md-12 mb-1'>
                      {/*<!--<div class="md-form">--> */}{' '}
                      <label for='registerFullName'>
                        1. Name of Business/Enterprise/Company/Cooperative
                        making application. <span class='red-text'>*</span>
                      </label>
                      <input
                        type='text'
                        class='form-control'
                        Placeholder=''
                        value={nameOfBusinessMakingApplication}
                        onChange={e =>
                          setNameOfBusinessMakingApplication(e.target.value)
                        }
                        id='nameOfBusinessMakingApplication'
                        required
                      />
                      {/*<!--</div>-->*/}
                    </div>
                    {/*  <!--\Grid column-->	*/}
                  </div>
                  {/*<!--Grid row .-->				*/}
                  <div class='text-left mt-5'>
                    2. <u>PROJECT LOCATION </u>
                  </div>
                  {/*<!--Grid row-->				*/}
                  <div class='row text-left'>
                    {/* <!--Grid column -->*/}
                    <div class='col-lg-4 col-md-12 mb-1'>
                      <label for='registerFullName'>
                        Provinces<span class='red-text'>*</span>
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        onChange={e => setprovince(e.target.value)}
                        id='province'
                        required
                      >
                        <option value=''>Choose ....</option>
                        {provinceDropDown.map(valProvince => {
                          return (
                            <option value={valProvince.provincesID}>
                              {valProvince.province}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                    {/* <!--\Grid column-->*/}

                    {/* <!--Grid column-->*/}
                    <div
                      class='col-lg-4 col-md-12 mb-0.01'
                      id='hideLocalAuthority'
                    >
                      {/* <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Council<span class='red-text'>*</span>{' '}
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        onChange={e => setcouncil(e.target.value)}
                        id='province'
                        required
                        searchable='Search here..'
                      >
                        <option value=''>Choose ....</option>
                        {councilDropDown.map(valCouncil => {
                          return (
                            <option value={valCouncil.councilsID}>
                              {valCouncil.council}
                            </option>
                          )
                        })}
                      </select>
                      {/*<!--</div>-->*/}
                    </div>
                    {/*  <!--Grid column -->*/}
                    <div
                      class='col-lg-4 col-md-12 mb-0.01'
                      style={{ display: 'none' }}
                      id='divShowlocalAuthority'
                    ></div>
                    {/*<!--\Grid column-->*/}

                    {/*<!--\Grid column-->*/}
                    <div class='col-lg-4 col-md-12 mb-1' id='hideConstituency'>
                      {/* <!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Constituency<span class='red-text'>*</span>{' '}
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        onChange={e => setconstituency(e.target.value)}
                        id='constituency'
                        required
                        searchable='Search here..'
                      >
                        <option value=''>Choose ....</option>
                        {constituencyDropDown.map(valConstituency => {
                          return (
                            <option value={valConstituency.constituenciesID}>
                              {valConstituency.constituency}
                            </option>
                          )
                        })}
                      </select>
                      {/*<!--</div>-->*/}
                    </div>
                    {/*<!--Grid column -->*/}
                    <div
                      class='col-lg-4 col-md-12 mb-0.01'
                      style={{ display: 'none' }}
                      id='divShowConstituency'
                    ></div>
                    {/*<!--\Grid column-->*/}

                    {/* <!--\Grid column-->*/}
                    <div class='col-lg-4 col-md-12 mb-1' id='hideWard'>
                      {/*<!--<div class="md-form">-->*/}
                      <label for='registerFullName'>
                        Ward<span class='red-text'>*</span>{' '}
                      </label>
                      <select
                        class='custom-select d-block w-100'
                        onChange={e => setward(e.target.value)}
                        id='ward'
                        required
                        searchable='Search here..'
                      >
                        <option value=''>Choose ....</option>
                        {wardDropDown.map(valWard => {
                          return (
                            <option value={valWard.wardsID}>
                              {valWard.ward}
                            </option>
                          )
                        })}
                      </select>
                      {/*<!--</div>-->*/}
                    </div>
                    {/* <!--Grid column -->*/}
                    <div
                      class='col-lg-4 col-md-12 mb-0.01'
                      style={{ display: 'none' }}
                      id='divShowWard'
                    ></div>
                    {/*<!--\Grid column-->*/}

                    {/* <!--Grid column-->	*/}
                    <div class='col-lg-3 col-md-12 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Zone<span class='red-text'>*</span>
                        </label>
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          Placeholder=''
                          value={zone}
                          onChange={e => setzone(e.target.value)}
                          id='zone'
                          required
                        />
                      </div>
                    </div>
                    {/*<!--\Grid column-->	*/}
                    {/* <!--Grid column-->	*/}
                    <div class='col-lg-5 col-md-12 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Business Physical Address
                          <span class='red-text'>*</span>
                        </label>
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          Placeholder=''
                          value={physicalAddress}
                          onChange={e => setphysicalAddress(e.target.value)}
                          id='physicalAddress'
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div class='row text-left'>
                    <div class='col-lg-12 col-md-12 mb-1'>
                      <div class='md-form'>
                        {' '}
                        <label for='registerFullName'>
                          3. a. Description of Current Business Activities, if
                          any <span class='red-text'></span>{' '}
                        </label>
                        <br />
                        <br />
                        <br />
                        <textarea
                          class='form-control'
                          placeholder=''
                          value={descriptionOfCurrentBusinessActivities}
                          onChange={e =>
                            setDescriptionOfCurrentBusinessActivities(
                              e.target.value
                            )
                          }
                          id='descriptionOfCurrentBusinessActivities'
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div class='row text-left'>
                    <div class='col-lg-7 col-md-12 mb-1'>
                      <div class='md'>
                        {' '}
                        <label for='registerFullName'>
                          b. Legal Form of Applicant (e.g. Limited Company,
                          Cooperative, etc.) Attach copy of certificate if
                          registered. <span class='red-text'></span>{' '}
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          placeholder=''
                          value={legalFormOfApplicant}
                          onChange={e =>
                            setlegalFormOfApplicant(e.target.value)
                          }
                          id='legalFormOfApplicant'
                        />
                      </div>
                    </div>
                    <div class='col-lg-3 col-md-12 mb-1'>
                      <div class='md-form text-left'>
                        <label for='registerFullName'>
                          Is Your Business Registered ?{' '}
                          <span class='red-text '>*</span>{' '}
                        </label>
                        <br />
                        <br />
                        <select
                          class='custom-select d-block w-100'
                          value={isYourBusinessRegistered}
                          onChange={e =>
                            setIsYourBusinessRegistered(e.target.value)
                          }
                          id='isYourBusinessRegistered'
                        >
                          <option value=''>Choose ....</option>
                          <option>Yes</option>
                          <option>No</option>
                        </select>
                      </div>
                    </div>
                    <div class='col-lg-2 col-md-12 mb-1'>
                      <div class='md-form'>
                        {' '}
                        <label for='registerFullName'>
                          Certificate Of Registration{' '}
                          <span class='red-text'></span>{' '}
                        </label>{' '}
                        <br /> <br />
                        <input
                          disabled={isYourBusinessRegistered != 'Yes'}
                          type='file'
                          class='form-control'
                          placeholder=''
                          /*value={certificateOfRegistration}
                          onChange={e =>
                            setCertificateOfRegistration(e.target.value)
                          }*/
                          onChange={saveCertificateOfRegistration}
                          id='certificateOfRegistration'
                        />
                      </div>
                    </div>
                  </div>
                  <div class='row text-left'>
                    <div class='col-lg-3 col-md-12 mb-1'>
                      <div class='md-form'>
                        {' '}
                        <label for='registerFullName'>
                          c. Date when Business was registered{' '}
                          <span class='red-text'></span>{' '}
                        </label>{' '}
                        <br /> <br /> <br />
                        <input
                          type='date'
                          class='form-control'
                          placeholder=''
                          value={dateWhenBusinessWasRegistered}
                          onChange={e =>
                            setDateWhenBusinessWasRegistered(e.target.value)
                          }
                          id='dateWhenBusinessWasRegistered'
                        />
                      </div>
                    </div>
                    <div class='col-lg-4 col-md-12 mb-1'>
                      <div class='md-form text-left'>
                        <label for='registerFullName'>
                          d. Do You Have Copy of business registration
                          certificate ? <span class='red-text '>*</span>{' '}
                        </label>
                        <br />
                        <br />
                        <br />
                        <select
                          class='custom-select d-block w-100'
                          value={doYouHaveCopyOfBusinessRegistrationCertificate}
                          onChange={e =>
                            setDoYouHaveCopyOfBusinessRegistrationCertificate(
                              e.target.value
                            )
                          }
                          id='doYouHaveCopyOfBusinessRegistrationCertificate'
                        >
                          <option value=''>Choose ....</option>
                          <option>Yes</option>
                          <option>No</option>
                        </select>
                      </div>
                    </div>
                    <div class='col-lg-5 col-md-12 mb-1'>
                      <div class='md-form'>
                        {' '}
                        <label for='registerFullName'>
                          e. Contact Number(s) for Applicant{' '}
                          <span class='red-text'>*</span>{' '}
                        </label>{' '}
                        <br /> <br />
                        <input
                          type='text'
                          class='form-control'
                          placeholder=''
                          value={contactNumbersForApplicant}
                          onChange={e =>
                            setContactNumbersForApplicant(e.target.value)
                          }
                          id='contactNumbersForApplicant'
                        />
                      </div>
                    </div>
                  </div>
                  <div class='row'>
                    <div class='col-lg-5 col-md-12 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          f. Next of Kin and their physical address and Phone
                        </label>
                        <br />
                      </div>
                    </div>
                  </div>
                  <br /> <br />
                  <div class='row' style={{ border: '1px solid black' }}>
                    <div class='col-lg-3 col-md-12 mb-1'>
                      <div class='md-form'>
                        {' '}
                        <label for='registerFullName'>
                          Name
                          <span class='red-text'>*</span>{' '}
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          placeholder=''
                          value={nextOfKinName}
                          onChange={e => setNextOfKinName(e.target.value)}
                          id='nextOfKinName'
                        />
                      </div>
                    </div>
                    <div class='col-lg-3 col-md-12 mb-1'>
                      <div class='md-form'>
                        {' '}
                        <label for='registerFullName'>
                          Physical Address
                          <span class='red-text'>*</span>{' '}
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          placeholder=''
                          value={nextOfKinPhysicalAddress}
                          onChange={e =>
                            setNextOfKinPhysicalAddress(e.target.value)
                          }
                          id='nextOfKinPhysicalAddress'
                        />
                      </div>
                    </div>
                    <div class='col-lg-3 col-md-12 mb-1'>
                      <div class='md-form'>
                        {' '}
                        <label for='registerFullName'>
                          NRC
                          <span class='red-text'>*</span>{' '}
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          placeholder=''
                          value={nextOfKinNrc}
                          onChange={e => setNextOfKinNrc(e.target.value)}
                          id='nextOfKinNrc'
                        />
                      </div>
                    </div>
                    <div class='col-lg-3 col-md-12 mb-1'>
                      <div class='md-form'>
                        {' '}
                        <label for='registerFullName'>
                          Phone Number
                          <span class='red-text'>*</span>{' '}
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          placeholder=''
                          value={nextOfKinPhoneNumber}
                          onChange={e =>
                            setNextOfKinPhoneNumber(e.target.value)
                          }
                          id='nextOfKinPhoneNumber'
                        />
                      </div>
                    </div>
                  </div>
                  <div class='mt-5 text-left'>
                    4. Shareholders of the Business
                  </div>
                  {shareholdersOfTheBusinessInputList.map(
                    (
                      shareholdersOfTheBusinessSingleInputList,
                      shareholdersOfTheBusinessIndex
                    ) => {
                      return (
                        <>
                          <div class='row text-left'>
                            <div class='col-lg-4 col-md-12 mb-1'>
                              <div class='md-form'>
                                {' '}
                                <label for='registerFullName'>
                                  {shareholdersOfTheBusinessIndex + 1} . Full
                                  Names
                                  <span class='red-text'>*</span>{' '}
                                </label>{' '}
                                <br />
                                <input
                                  type='text'
                                  class='form-control'
                                  placeholder=''
                                  value={
                                    shareholdersOfTheBusinessSingleInputList.shareholdersOfTheBusinessFullNames
                                  }
                                  onChange={e =>
                                    handleShareholdersOfTheBusinessInputChange(
                                      e,
                                      shareholdersOfTheBusinessIndex
                                    )
                                  }
                                  name='shareholdersOfTheBusinessFullNames'
                                />
                              </div>
                            </div>

                            <div class='col-lg-3 col-md-12 mb-1'>
                              <div class='md-form'>
                                {' '}
                                <label for='registerFullName'>
                                  Nationality
                                  <span class='red-text'>*</span>{' '}
                                </label>{' '}
                                <br />
                                <input
                                  type='text'
                                  class='form-control'
                                  placeholder=''
                                  value={
                                    shareholdersOfTheBusinessSingleInputList.shareholdersOfTheBusinessNationality
                                  }
                                  onChange={e =>
                                    handleShareholdersOfTheBusinessInputChange(
                                      e,
                                      shareholdersOfTheBusinessIndex
                                    )
                                  }
                                  name='shareholdersOfTheBusinessNationality'
                                />
                              </div>
                            </div>

                            <div class='col-lg-2 col-md-12 mb-1'>
                              <div class='md-form'>
                                {' '}
                                <label for='registerFullName'>
                                  NRC/ Passport No
                                  <span class='red-text'>*</span>{' '}
                                </label>{' '}
                                <br />
                                <input
                                  type='text'
                                  class='form-control'
                                  placeholder=''
                                  value={
                                    shareholdersOfTheBusinessSingleInputList.shareholdersOfTheBusinessNrc
                                  }
                                  onChange={e =>
                                    handleShareholdersOfTheBusinessInputChange(
                                      e,
                                      shareholdersOfTheBusinessIndex
                                    )
                                  }
                                  name='shareholdersOfTheBusinessNrc'
                                />
                              </div>
                            </div>
                            <div class='col-lg-3 col-md-12 mb-1'>
                              <div class='md'>
                                {' '}
                                <label for='registerFullName'>
                                  Attach Copy of ID(NRC/Passport)
                                  <span class='red-text'>*</span>{' '}
                                </label>{' '}
                                <input
                                  type='file'
                                  class='form-control'
                                  placeholder=''
                                  value={
                                    shareholdersOfTheBusinessSingleInputList.shareholdersOfTheBusinessNrcCopy
                                  }
                                  onChange={e =>
                                    handleShareholdersOfTheBusinessInputChange(
                                      e,
                                      shareholdersOfTheBusinessIndex
                                    )
                                  }
                                  name='shareholdersOfTheBusinessNrcCopy'
                                />
                              </div>
                            </div>

                            <div class='col-lg-3 col-md-12 mb-1'>
                              <div class='md-form'>
                                {' '}
                                <label for='registerFullName'>
                                  % share holding
                                  <span class='red-text'>*</span>{' '}
                                </label>{' '}
                                <br />
                                <input
                                  type='text'
                                  class='form-control'
                                  placeholder=''
                                  value={
                                    shareholdersOfTheBusinessSingleInputList.shareholdersOfTheBusinessShares
                                  }
                                  onChange={e =>
                                    handleShareholdersOfTheBusinessInputChange(
                                      e,
                                      shareholdersOfTheBusinessIndex
                                    )
                                  }
                                  name='shareholdersOfTheBusinessShares'
                                />
                              </div>
                            </div>
                            <div class='col-lg-4 col-md-12 mb-1'>
                              <div class='md-form'>
                                {' '}
                                <label for='registerFullName'>
                                  Position in the Business
                                  <span class='red-text'>*</span>{' '}
                                </label>{' '}
                                <br />
                                <input
                                  type='text'
                                  class='form-control'
                                  placeholder=''
                                  value={
                                    shareholdersOfTheBusinessSingleInputList.shareholdersOfTheBusinessPositionInTheBusiness
                                  }
                                  onChange={e =>
                                    handleShareholdersOfTheBusinessInputChange(
                                      e,
                                      shareholdersOfTheBusinessIndex
                                    )
                                  }
                                  name='shareholdersOfTheBusinessPositionInTheBusiness'
                                />
                              </div>
                            </div>
                            <div class='col-lg-4 col-md-12 mb-1'>
                              <div class='md'>
                                {' '}
                                <label for='registerFullName'>
                                  Disabled ?<span class='red-text'>*</span>{' '}
                                </label>{' '}
                                <select
                                  class='custom-select d-block w-100'
                                  value={
                                    shareholdersOfTheBusinessSingleInputList.shareholdersOfTheBusinessDisabled
                                  }
                                  onChange={e =>
                                    handleShareholdersOfTheBusinessInputChange(
                                      e,
                                      shareholdersOfTheBusinessIndex
                                    )
                                  }
                                  name='shareholdersOfTheBusinessDisabled'
                                >
                                  <option value=''>Choose ....</option>
                                  <option>Yes</option>
                                  <option>No</option>
                                </select>
                              </div>
                            </div>
                            {shareholdersOfTheBusinessInputList.length !==
                              1 && (
                              <div class='col-lg-1 col-md-2 mb-2'>
                                <div class='md-form'>
                                  <br />
                                  <input
                                    type='button'
                                    class='form-control btn white-text red accent-2'
                                    value='Remove'
                                    onClick={() =>
                                      handleRemoveClickShareholdersOfTheBusiness(
                                        shareholdersOfTheBusinessIndex
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            )}
                            {/*<!--\Grid column-->	*/}

                            {/*<!--Grid column-->*/}
                            {shareholdersOfTheBusinessInputList.length - 1 ===
                              shareholdersOfTheBusinessIndex && (
                              <div class='col-lg-12 col-md-12'>
                                <div class='md-form'>
                                  <input
                                    type='button'
                                    class='form-control btn white-text blue accent-2'
                                    value='Add'
                                    onClick={
                                      handleAddClickShareholdersOfTheBusiness
                                    }
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </>
                      )
                    }
                  )}
                  <div class='row' style={{ display: 'none' }}>
                    <div class='col-lg-12 col-md-12 mb-1 text-left'>
                      <div class='md'>
                        {' '}
                        <label for='registerFullName'>
                          Attach Copies of ID(NRC/Passport)
                          <br /> select multiple files in order according to the
                          way the Share Holders are appearing above from top to
                          bottom
                          <span class='red-text'>*</span>{' '}
                        </label>{' '}
                        <input
                          type='file'
                          class='form-control'
                          placeholder=''
                          //onChange={saveShareholdersNRCs}
                          name='shareholdersNRCs[]'
                          multiple
                        />
                      </div>
                    </div>
                  </div>
                  <div class='row text-left'>
                    <div class='col-lg-12 col-md-12'>
                      <div class='md'>
                        <label for='registerFullName'>
                          5. Authorized Representative (to act on behalf of the
                          Applicant and respond to any questions regarding the
                          Concept Note)
                        </label>{' '}
                        <br />
                      </div>
                    </div>
                  </div>
                  <div class='row'>
                    <div class='col-lg-3 col-md-12 mb-1'>
                      <div class='md-form'>
                        {' '}
                        <label for='registerFullName'>
                          Name of Contact Person
                          <span class='red-text'>*</span>{' '}
                        </label>
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          placeholder=''
                          value={authorizedRepresentativeNameOfContactPerson}
                          onChange={e =>
                            setAuthorizedRepresentativeNameOfContactPerson(
                              e.target.value
                            )
                          }
                          id='authorizedRepresentativeNameOfContactPerson'
                        />
                      </div>
                    </div>
                    <div class='col-lg-2 col-md-12 mb-1'>
                      <div class='md-form'>
                        {' '}
                        <label for='registerFullName'>
                          Position in the Business
                          <span class='red-text'>*</span>{' '}
                        </label>
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          placeholder=''
                          value={authorizedRepresentativePositionInTheBusiness}
                          onChange={e =>
                            setAuthorizedRepresentativePositionInTheBusiness(
                              e.target.value
                            )
                          }
                          id='authorizedRepresentativePositionInTheBusiness'
                        />
                      </div>
                    </div>
                    <div class='col-lg-2 col-md-12 mb-1'>
                      <div class='md-form'>
                        {' '}
                        <label for='registerFullName'>
                          Cell
                          <span class='red-text'>*</span>{' '}
                        </label>
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          placeholder=''
                          value={authorizedRepresentativeCell}
                          onChange={e =>
                            setAuthorizedRepresentativeCell(e.target.value)
                          }
                          id='authorizedRepresentativeCell'
                        />
                      </div>
                    </div>
                    <div class='col-lg-2 col-md-12 mb-1'>
                      <div class='md-form'>
                        {' '}
                        <label for='registerFullName'>
                          Telephone
                          <span class='red-text'></span>{' '}
                        </label>
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          placeholder=''
                          value={authorizedRepresentativeTelephone}
                          onChange={e =>
                            setAuthorizedRepresentativeTelephone(e.target.value)
                          }
                          id='authorizedRepresentativeTelephone'
                        />
                      </div>
                    </div>
                    <div class='col-lg-3 col-md-12 mb-1'>
                      <div class='md-form'>
                        {' '}
                        <label for='registerFullName'>
                          Email
                          <span class='red-text'>*</span>{' '}
                        </label>
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          placeholder=''
                          value={authorizedRepresentativeEmail}
                          onChange={e =>
                            setAuthorizedRepresentativeEmail(e.target.value)
                          }
                          id='authorizedRepresentativeEmail'
                        />
                      </div>
                    </div>
                  </div>
                  <br /> <br />
                  <div
                    class='row text-left'
                    style={{ border: '1px solid black' }}
                  >
                    <div class='col-lg-12 col-md-12 mb-2'>
                      <div class='md'>
                        <label for='registerFullName'>
                          6. Previous CDF Funding <br />
                          <i>
                            Is Applicant or any of its shareholders already a
                            beneficiary of Loan or Grant Empowerment Fund?{' '}
                          </i>
                          <span class='red-text'>*</span>{' '}
                        </label>{' '}
                        <br /> <br />
                        <select
                          class='custom-select d-block w-100'
                          value={previousCDFFunding}
                          onChange={e => setPreviousCDFFunding(e.target.value)}
                          id='previousCDFFunding'
                        >
                          <option value=''>Choose ....</option>
                          <option>Yes</option>
                          <option>No</option>
                        </select>
                      </div>
                    </div>

                    <div class='col-lg-12 col-md-12 mb-2'>
                      <input
                        type='text'
                        disabled
                        class='form-control'
                        placeholder='If Yes Give Details'
                        hidden={previousCDFFunding === 'Yes'}
                      />
                    </div>

                    <div
                      class='col-lg-4 col-md-12 mb-1'
                      hidden={previousCDFFunding != 'Yes'}
                    >
                      <div class='md-form'>
                        {' '}
                        <label for='registerFullName'>
                          Status of Loan (outstanding/Repaid)
                          <span class='red-text'></span>{' '}
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          placeholder=''
                          value={previousCDFFundingStatusOfLoan}
                          onChange={e =>
                            setPreviousCDFFundingStatusOfLoan(e.target.value)
                          }
                          id='previousCDFFundingStatusOfLoan'
                        />
                      </div>
                    </div>
                    <div
                      class='col-lg-4 col-md-12 mb-1'
                      hidden={previousCDFFunding != 'Yes'}
                    >
                      <div class='md-form'>
                        {' '}
                        <label for='registerFullName'>
                          Name of Business
                          <span class='red-text'>*</span>{' '}
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          placeholder=''
                          value={previousCDFFundingNameOfBusiness}
                          onChange={e =>
                            setPreviousCDFFundingNameOfBusiness(e.target.value)
                          }
                          id='previousCDFFundingNameOfBusiness'
                        />
                      </div>
                    </div>
                    <div
                      class='col-lg-4 col-md-12 mb-1'
                      hidden={previousCDFFunding != 'Yes'}
                    >
                      <div class='md-form'>
                        {' '}
                        <label for='registerFullName'>
                          Type of Registration of Business
                          <span class='red-text'>*</span>{' '}
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          placeholder=''
                          value={previousCDFFundingTypeOfRegistrationOfBusiness}
                          onChange={e =>
                            setPreviousCDFFundingTypeOfRegistrationOfBusiness(
                              e.target.value
                            )
                          }
                          id='previousCDFFundingTypeOfRegistrationOfBusiness'
                        />
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                  <div class='row text-left'>
                    <div class='col-lg-12 col-md-12'>
                      <div class='md'>
                        <label for='registerFullName'>
                          7.0 BANK DETAILS
                          <br />
                          Provide Bank account or mobile money wallet registered
                          for your Business/ Club/Group/Enterprise/Cooperative
                        </label>{' '}
                      </div>
                    </div>
                  </div>
                  <div class='row'>
                    <div class='col-lg-4 col-md-12 mb-1'>
                      <div class='md-form'>
                        {' '}
                        <label for='registerFullName'>
                          BANK NAME
                          <span class='red-text'>*</span>{' '}
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          placeholder=''
                          value={bankDetailsBankName}
                          onChange={e => setBankDetailsBankName(e.target.value)}
                          id='bankDetailsBankName'
                        />
                      </div>
                    </div>
                    <div class='col-lg-4 col-md-12 mb-1'>
                      <div class='md-form'>
                        {' '}
                        <label for='registerFullName'>
                          BRANCH
                          <span class='red-text'>*</span>{' '}
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          placeholder=''
                          value={bankDetailsBranch}
                          onChange={e => setBankDetailsBranch(e.target.value)}
                          id='bankDetailsBranch'
                        />
                      </div>
                    </div>
                    <div class='col-lg-4 col-md-12 mb-1'>
                      <div class='md-form'>
                        {' '}
                        <label for='registerFullName'>
                          SORT/BRANCH CODE
                          <span class='red-text'>*</span>{' '}
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          placeholder=''
                          value={bankDetailsBranchCode}
                          onChange={e =>
                            setBankDetailsBranchCode(e.target.value)
                          }
                          id='bankDetailsBranchCode'
                        />
                      </div>
                    </div>
                    <div class='col-lg-2 col-md-12 mb-1'>
                      <div class='md-form'>
                        {' '}
                        <label for='registerFullName'>
                          SWIFT CODE
                          <span class='red-text'></span>{' '}
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          placeholder=''
                          value={bankDetailsSwiftCode}
                          onChange={e =>
                            setbankDetailsSwiftCode(e.target.value)
                          }
                          id='bankDetailsSwiftCode'
                        />
                      </div>
                    </div>
                    <div class='col-lg-2 col-md-12 mb-1'>
                      <div class='md-form'>
                        {' '}
                        <label for='registerFullName'>
                          ACCOUNT NUMBER
                          <span class='red-text'>*</span>{' '}
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          placeholder=''
                          value={bankDetailsAccountNumber}
                          onChange={e =>
                            setbankDetailsAccountNumber(e.target.value)
                          }
                          id='bankDetailsAccountNumber'
                        />
                      </div>
                    </div>
                    <div class='col-lg-4 col-md-12 mb-1'>
                      <div class='md-form'>
                        {' '}
                        <label for='registerFullName'>
                          TPIN
                          <span class='red-text'>*</span>{' '}
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          placeholder=''
                          value={bankDetailsTpin}
                          onChange={e => setbankDetailsTpin(e.target.value)}
                          id='bankDetailsTpin'
                        />
                      </div>
                    </div>
                    <div class='col-lg-4 col-md-12 mb-1'>
                      <div class='md-form'>
                        {' '}
                        <label for='registerFullName'>
                          MOBILE MONEY WALLET NAME AND NUMBER
                          <span class='red-text'></span>{' '}
                        </label>{' '}
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          placeholder=''
                          value={bankDetailsMobileMoneyNameAndNumber}
                          onChange={e =>
                            setbankDetailsMobileMoneyNameAndNumber(
                              e.target.value
                            )
                          }
                          id='bankDetailsMobileMoneyNameAndNumber'
                        />
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                  <div class='row text-left'>
                    <div class='col-lg-12 col-md-12'>
                      <div class='md'>
                        <label for='registerFullName'>
                          8.0 Financial Data (
                          <i>Please provide Financial data for your business</i>
                          ).
                        </label>{' '}
                      </div>
                    </div>
                  </div>
                  <div class='row'>
                    <div class='col-lg-4 col-md-12 mb-1'>
                      <div class='md-form'>
                        {' '}
                        <label for='registerFullName'>
                          What is the Monthly Income of the current business? If
                          any
                          <span class='red-text'></span>{' '}
                        </label>{' '}
                        <br /> <br />
                        <input
                          type='text'
                          class='form-control'
                          placeholder=''
                          value={
                            financialDataWhatIsTheMonthlyIncomeOfTheCurrentBusiness
                          }
                          onChange={e =>
                            setFinancialDataWhatIsTheMonthlyIncomeOfTheCurrentBusiness(
                              e.target.value
                            )
                          }
                          id='financialDataWhatIsTheMonthlyIncomeOfTheCurrentBusiness'
                        />
                      </div>
                    </div>
                    <div class='col-lg-4 col-md-12 mb-1'>
                      <div class='md-form'>
                        {' '}
                        <label for='registerFullName'>
                          What is the Monthly Expenses of the current business?
                          <span class='red-text'></span>{' '}
                        </label>{' '}
                        <br /> <br />
                        <input
                          type='text'
                          class='form-control'
                          placeholder=''
                          value={
                            financialDataWhatIsTheMonthlyExpensesOfTheCurrenbusiness
                          }
                          onChange={e =>
                            setFinancialDataWhatIsTheMonthlyExpensesOfTheCurrenbusiness(
                              e.target.value
                            )
                          }
                          id='financialDataWhatIsTheMonthlyExpensesOfTheCurrenbusiness'
                        />
                      </div>
                    </div>
                    <div class='col-lg-4 col-md-12 mb-1'>
                      <div class='md-form'>
                        {' '}
                        <label for='registerFullName'>
                          How much have you invested in the current business?
                          <span class='red-text'></span>{' '}
                        </label>{' '}
                        <br /> <br />
                        <input
                          type='text'
                          class='form-control'
                          placeholder=''
                          value={
                            financialDataHowMuchHaveYouInvestedInTheCurrentBusiness
                          }
                          onChange={e =>
                            setFinancialDataHowMuchHaveYouInvestedInTheCurrentBusiness(
                              e.target.value
                            )
                          }
                          id='financialDataHowMuchHaveYouInvestedInTheCurrentBusiness'
                        />
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                  <div class='row text-left'>
                    <div class='col-lg-12 col-md-12'>
                      <div class='md'>
                        <label for='registerFullName'>
                          9.0 MARKET OVERVIEW
                        </label>{' '}
                      </div>
                    </div>
                  </div>
                  <div class='row'>
                    <div class='col-lg-12 col-md-12 mb-1'>
                      <div class='md-form'>
                        {' '}
                        <label for='registerFullName'>
                          9.1 Trends in Demand (Explain with regards to the
                          Proposed Product)
                          <span class='red-text'>*</span>{' '}
                        </label>{' '}
                        <br /> <br />
                        <br /> <br />
                        <textarea
                          class='form-control'
                          value={marketOverviewTrendsInDemand}
                          onChange={e =>
                            setMarketOverviewTrendsInDemand(e.target.value)
                          }
                          id='marketOverviewTrendsInDemand'
                        ></textarea>
                      </div>
                    </div>
                    <div class='col-lg-12 col-md-12 mb-1'>
                      <div class='md-form'>
                        {' '}
                        <label for='registerFullName'>
                          9.2 Trends in Supply (Explain with regards to the
                          Proposed Product)
                          <span class='red-text'>*</span>{' '}
                        </label>{' '}
                        <br /> <br /> <br /> <br />
                        <textarea
                          class='form-control'
                          value={marketOverviewTrendsInSupply}
                          onChange={e =>
                            setMarketOverviewTrendsInSupply(e.target.value)
                          }
                          id='marketOverviewTrendsInSupply'
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <br /> <br />
                  <div class='row text-left'>
                    <div class='col-lg-12 col-md-12'>
                      <div class='md'>
                        <label for='registerFullName'>
                          10.0 DESCRIPTION OF PROPOSED BUSINESS PROJECT
                          <br />
                          10.1 Business Operations
                          <br />
                          How is your business going to compete in terms of the
                          following:
                        </label>{' '}
                      </div>
                    </div>
                  </div>
                  <div class='row'>
                    <div class='col-lg-12 col-md-12 mb-1'>
                      <div class='md-form'>
                        {' '}
                        <label for='registerFullName'>
                          (a) What products and/or services will you be selling
                          to your customers?
                          <span class='red-text'>*</span>{' '}
                        </label>{' '}
                        <br /> <br />
                        <br /> <br />
                        <textarea
                          class='form-control'
                          value={
                            businessOperationsWhatProductsAndOrWervicesWillYouBeSellingToYourCustomers
                          }
                          onChange={e =>
                            setBusinessOperationsWhatProductsAndOrWervicesWillYouBeSellingToYourCustomers(
                              e.target.value
                            )
                          }
                          id='businessOperationsWhatProductsAndOrWervicesWillYouBeSellingToYourCustomers'
                        ></textarea>
                      </div>
                    </div>
                    <div class='col-lg-12 col-md-12 mb-1'>
                      <div class='md-form'>
                        {' '}
                        <label for='registerFullName'>
                          (b) (i) Location (State the advantage of the location
                          of the project)
                          <span class='red-text'>*</span>{' '}
                        </label>{' '}
                        <br /> <br />
                        <br /> <br />
                        <textarea
                          class='form-control'
                          value={businessOperationsLocation}
                          onChange={e =>
                            setBusinessOperationsLocation(e.target.value)
                          }
                          id='businessOperationsLocation'
                        ></textarea>
                      </div>
                    </div>

                    <div class='col-lg-12 col-md-12 mb-1'>
                      <div class='md-form'>
                        {' '}
                        <label for='registerFullName'>
                          (ii) What kind of equipment will be needed for the
                          operations?
                          <span class='red-text'>*</span>{' '}
                        </label>{' '}
                        <br /> <br /> <br /> <br />
                        <textarea
                          class='form-control'
                          value={
                            businessOperationsWhatkindOfEquipmentWillBeNeededForTheOperations
                          }
                          onChange={e =>
                            setBusinessOperationsWhatkindOfEquipmentWillBeNeededForTheOperations(
                              e.target.value
                            )
                          }
                          id='businessOperationsWhatkindOfEquipmentWillBeNeededForTheOperations'
                        ></textarea>
                      </div>
                    </div>
                    <div class='col-lg-12 col-md-12 mb-1'>
                      <div class='md-form'>
                        {' '}
                        <label for='registerFullName'>
                          (iii) What will such types of equipment be used for in
                          the operations?
                          <span class='red-text'>*</span>{' '}
                        </label>{' '}
                        <br /> <br /> <br /> <br />
                        <textarea
                          class='form-control'
                          value={
                            businessOperationsWhatWillSuchTypesOfEquipmentBeUsedForInTheOperations
                          }
                          onChange={e =>
                            setBusinessOperationsWhatWillSuchTypesOfEquipmentBeUsedForInTheOperations(
                              e.target.value
                            )
                          }
                          id='businessOperationsWhatWillSuchTypesOfEquipmentBeUsedForInTheOperations'
                        ></textarea>
                      </div>
                    </div>

                    <div class='col-lg-12 col-md-12 mb-1'>
                      <div class='md-form'>
                        {' '}
                        <label for='registerFullName'>
                          (iv) Where will you secure raw materials from and how?
                          <span class='red-text'>*</span>{' '}
                        </label>{' '}
                        <br /> <br /> <br /> <br />
                        <textarea
                          class='form-control'
                          value={
                            businessOperationsWhereWillYouSecureRawMaterialsFromAndHow
                          }
                          onChange={e =>
                            setBusinessOperationsWhereWillYouSecureRawMaterialsFromAndHow(
                              e.target.value
                            )
                          }
                          id='businessOperationsWhereWillYouSecureRawMaterialsFromAndHow'
                        ></textarea>
                      </div>
                    </div>
                    <div class='col-lg-12 col-md-12 mb-1'>
                      <div class='md-form'>
                        {' '}
                        <label for='registerFullName'>
                          (v) What will be your cost of production per month
                          <span class='red-text'>*</span>{' '}
                        </label>{' '}
                        <br /> <br /> <br /> <br />
                        <textarea
                          class='form-control'
                          value={
                            businessOperationsWhatWillBeYourCostOfProductionPerMonth
                          }
                          onChange={e =>
                            setBusinessOperationsWhatWillBeYourCostOfProductionPerMonth(
                              e.target.value
                            )
                          }
                          id='businessOperationsWhatWillBeYourCostOfProductionPerMonth'
                        ></textarea>
                      </div>
                    </div>

                    <div class='col-lg-12 col-md-12 mb-1'>
                      <div class='md-form'>
                        {' '}
                        <label for='registerFullName'>
                          (a) What products and/or services will you be selling
                          to your customers?
                          <span class='red-text'>*</span>{' '}
                        </label>{' '}
                        <br /> <br /> <br /> <br />
                        <textarea
                          class='form-control'
                          value={
                            businessOperationsWhatProductsAndOrWervicesWillYouBeSellingToYourCustomers
                          }
                          onChange={e =>
                            setBusinessOperationsWhatProductsAndOrWervicesWillYouBeSellingToYourCustomers(
                              e.target.value
                            )
                          }
                          id='businessOperationsWhatProductsAndOrWervicesWillYouBeSellingToYourCustomers'
                        ></textarea>
                      </div>
                    </div>
                    <div class='col-lg-12 col-md-12 mb-1 text-left'>
                      <div class='md-form'>
                        {' '}
                        <label for='registerFullName'>
                          (c) Price of products and income:
                          <br /> (i) What will be the price of your
                          products/services?
                          <span class='red-text'>*</span>{' '}
                        </label>{' '}
                        <br /> <br /> <br /> <br />
                        <textarea
                          class='form-control'
                          value={
                            businessOperationsWhatWillBeThePriceOfYourProductsOrServices
                          }
                          onChange={e =>
                            setBusinessOperationsWhatWillBeThePriceOfYourProductsOrServices(
                              e.target.value
                            )
                          }
                          id='businessOperationsWhatWillBeThePriceOfYourProductsOrServices'
                        ></textarea>
                      </div>
                    </div>

                    <div class='col-lg-12 col-md-12 mb-1'>
                      <div class='md-form'>
                        {' '}
                        <label for='registerFullName'>
                          (ii) What will be your income per month from the
                          business operations
                          <span class='red-text'>*</span>{' '}
                        </label>{' '}
                        <br /> <br /> <br /> <br />
                        <textarea
                          class='form-control'
                          value={
                            businessOperationsWhatWillBeYourIncomePerMonthFromTheBusinessOperations
                          }
                          onChange={e =>
                            setBusinessOperationsWhatWillBeYourIncomePerMonthFromTheBusinessOperations(
                              e.target.value
                            )
                          }
                          id='businessOperationsWhatWillBeYourIncomePerMonthFromTheBusinessOperations'
                        ></textarea>
                      </div>
                    </div>

                    <div class='col-lg-12 col-md-12 mb-1 text-left'>
                      <div class='md-form'>
                        {' '}
                        <label for='registerFullName'>
                          (d) Sales Volumes
                          <br /> (i) What will be your level of production per
                          month or year
                          <span class='red-text'>*</span>{' '}
                        </label>{' '}
                        <br /> <br /> <br /> <br />
                        <textarea
                          class='form-control'
                          value={
                            businessOperationsWhatWillBeYourLevelOfProductionPerMonthOrYear
                          }
                          onChange={e =>
                            setBusinessOperationsWhatWillBeYourLevelOfProductionPerMonthOrYear(
                              e.target.value
                            )
                          }
                          id='businessOperationsWhatWillBeYourLevelOfProductionPerMonthOrYear'
                        ></textarea>
                      </div>
                    </div>

                    <div class='col-lg-12 col-md-12 mb-1'>
                      <div class='md-form'>
                        {' '}
                        <label for='registerFullName'>
                          (ii) Who will be your major competitors in terms of
                          supplying the same products/services and why do you
                          think you will survive the competition
                          <span class='red-text'>*</span>{' '}
                        </label>{' '}
                        <br /> <br /> <br /> <br />
                        <textarea
                          class='form-control'
                          value={
                            businessOperationsWhoWillBeYourMajorCompetitors
                          }
                          onChange={e =>
                            setBusinessOperationsWhoWillBeYourMajorCompetitors(
                              e.target.value
                            )
                          }
                          id='businessOperationsWhoWillBeYourMajorCompetitors'
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div class='row text-left'>
                    <div class='col-lg-12 col-md-12 mb-1'>
                      <div class='md'>
                        {' '}
                        <label for='registerFullName'>
                          11.0 Proposed Management Team
                          <br /> [Please indicate names, positions,
                          qualification and relevant experience of key members
                          of the management team that will implement and run the
                          proposed business. Startup businesses may only include
                          positions]
                        </label>
                      </div>
                    </div>
                  </div>
                  {proposedManagementTeamInputList.map(
                    (
                      proposedManagementTeamSingleInputList,
                      proposedManagementTeamIndex
                    ) => {
                      return (
                        <>
                          <div class='row text-left'>
                            <div class='col-lg-3 col-md-12 mb-1'>
                              <div class='md-form'>
                                {' '}
                                <label for='registerFullName'>
                                  {proposedManagementTeamIndex + 1} . Full Name
                                  <span class='red-text'>*</span>{' '}
                                </label>{' '}
                                <br />
                                <input
                                  type='text'
                                  class='form-control'
                                  placeholder=''
                                  value={
                                    proposedManagementTeamSingleInputList.proposedManagementTeamFullName
                                  }
                                  onChange={e =>
                                    handleProposedManagementTeamInputChange(
                                      e,
                                      proposedManagementTeamIndex
                                    )
                                  }
                                  name='proposedManagementTeamFullName'
                                />
                              </div>
                            </div>

                            <div class='col-lg-4 col-md-12 mb-1'>
                              <div class='md-form'>
                                {' '}
                                <label for='registerFullName'>
                                  Management Position
                                  <span class='red-text'>*</span>{' '}
                                </label>{' '}
                                <br />
                                <input
                                  type='text'
                                  class='form-control'
                                  placeholder=''
                                  value={
                                    proposedManagementTeamSingleInputList.proposedManagementTeamManagementPosition
                                  }
                                  onChange={e =>
                                    handleProposedManagementTeamInputChange(
                                      e,
                                      proposedManagementTeamIndex
                                    )
                                  }
                                  name='proposedManagementTeamManagementPosition'
                                />
                              </div>
                            </div>

                            <div class='col-lg-4 col-md-12 mb-1'>
                              <div class='md-form'>
                                {' '}
                                <label for='registerFullName'>
                                  Management Qualification and Experience
                                  <span class='red-text'>*</span>{' '}
                                </label>{' '}
                                <br />
                                <input
                                  type='text'
                                  class='form-control'
                                  placeholder=''
                                  value={
                                    proposedManagementTeamSingleInputList.proposedManagementTeamManagementQualificationAndExperience
                                  }
                                  onChange={e =>
                                    handleProposedManagementTeamInputChange(
                                      e,
                                      proposedManagementTeamIndex
                                    )
                                  }
                                  name='proposedManagementTeamManagementQualificationAndExperience'
                                />
                              </div>
                            </div>

                            {proposedManagementTeamInputList.length !== 1 && (
                              <div class='col-lg-1 col-md-2 mb-2'>
                                <div class='md-form'>
                                  <br />
                                  <input
                                    type='button'
                                    class='form-control btn white-text red accent-2'
                                    value='Remove'
                                    onClick={() =>
                                      handleRemoveClickProposedManagementTeam(
                                        proposedManagementTeamIndex
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            )}
                            {/*<!--\Grid column-->	*/}

                            {/*<!--Grid column-->*/}
                            {proposedManagementTeamInputList.length - 1 ===
                              proposedManagementTeamIndex && (
                              <div class='col-lg-12 col-md-12'>
                                <div class='md-form'>
                                  <input
                                    type='button'
                                    class='form-control btn white-text blue accent-2'
                                    value='Add'
                                    onClick={
                                      handleAddClickProposedManagementTeam
                                    }
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </>
                      )
                    }
                  )}
                  <div class='row text-left'>
                    <div class='col-lg-12 col-md-12 mb-1'>
                      <div class='md'>
                        {' '}
                        <label for='registerFullName'>
                          <b>12.0 What will be the Business Impact?</b> In not
                          more than a sentence on each, please indicate the
                          positive impact this business will have on the
                          following:
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class='row text-left'>
                    <div class='col-lg-12 col-md-12 mb-1'>
                      <div class='md-form'>
                        {' '}
                        <label for='registerFullName'>
                          (a) How will your <b>customers</b> benefit from your
                          business?
                          <span class='red-text'>*</span>{' '}
                        </label>{' '}
                        <br /> <br /> <br /> <br />
                        <textarea
                          class='form-control'
                          value={howWillYourCustomersBenefitFromYourBusiness}
                          onChange={e =>
                            setHowWillYourCustomersBenefitFromYourBusiness(
                              e.target.value
                            )
                          }
                          id='howWillYourCustomersBenefitFromYourBusiness'
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div class='row text-left'>
                    <div class='col-lg-12 col-md-12 mb-1'>
                      <div class='md-form'>
                        {' '}
                        <label for='registerFullName'>
                          (b) How will your <b>suppliers</b> benefit from your
                          business?
                          <span class='red-text'>*</span>{' '}
                        </label>{' '}
                        <br /> <br /> <br /> <br />
                        <textarea
                          class='form-control'
                          value={howWillYourSuppliersBenefitFromYourBusiness}
                          onChange={e =>
                            setHowWillYourSuppliersBenefitFromYourBusiness(
                              e.target.value
                            )
                          }
                          id='howWillYourSuppliersBenefitFromYourBusiness'
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div class='row text-left'>
                    <div class='col-lg-12 col-md-12'>
                      <div class='md'>
                        {' '}
                        <label for='registerFullName'>
                          (c) Number of Workers:
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class='row text-center '>
                    <div class='col-lg-12 col-md-12 mb-1'>
                      <div class='md'>
                        {' '}
                        <label for='registerFullName'>
                          <center>BEFORE CDF FUNDING </center>
                          <span class='red-text'></span>{' '}
                        </label>{' '}
                      </div>
                    </div>
                  </div>
                  <div class='row text-left'>
                    <div class='col-lg-3 col-md-12 mb-1'>
                      <div class='md-form'>
                        {' '}
                        <label for='registerFullName'>
                          Women
                          <span class='red-text'></span>{' '}
                        </label>{' '}
                        <br /> <br />
                        <input
                          type='text'
                          class='form-control'
                          value={numberOfWorkersBeforeCDFFundingWomen}
                          onChange={e =>
                            setNumberOfWorkersBeforeCDFFundingWomen(
                              e.target.value
                            )
                          }
                          id='numberOfWorkersBeforeCDFFundingWomen'
                        />
                      </div>
                    </div>

                    <div class='col-lg-3 col-md-12 mb-1'>
                      <div class='md-form'>
                        {' '}
                        <label for='registerFullName'>
                          Men
                          <span class='red-text'></span>{' '}
                        </label>{' '}
                        <br /> <br />
                        <input
                          type='text'
                          class='form-control'
                          value={numberOfWorkersBeforeCDFFundingMen}
                          onChange={e =>
                            setNumberOfWorkersBeforeCDFFundingMen(
                              e.target.value
                            )
                          }
                          id='numberOfWorkersBeforeCDFFundingMen'
                        />
                      </div>
                    </div>

                    <div class='col-lg-3 col-md-12 mb-1'>
                      <div class='md-form'>
                        {' '}
                        <label for='registerFullName'>
                          Youth
                          <span class='red-text'></span>{' '}
                        </label>{' '}
                        <br /> <br />
                        <input
                          type='text'
                          class='form-control'
                          value={numberOfWorkersBeforeCDFFundingYouth}
                          onChange={e =>
                            setNumberOfWorkersBeforeCDFFundingYouth(
                              e.target.value
                            )
                          }
                          id='numberOfWorkersBeforeCDFFundingYouth'
                        />
                      </div>
                    </div>

                    <div class='col-lg-3 col-md-12 mb-1'>
                      <div class='md-form'>
                        {' '}
                        <label for='registerFullName'>
                          Physically Challenged (Disabled)
                          <span class='red-text'></span>{' '}
                        </label>{' '}
                        <br /> <br />
                        <input
                          type='text'
                          class='form-control'
                          value={numberOfWorkersBeforeCDFFundingDisabled}
                          onChange={e =>
                            setNumberOfWorkersBeforeCDFFundingDisabled(
                              e.target.value
                            )
                          }
                          id='numberOfWorkersBeforeCDFFundingDisabled'
                        />
                      </div>
                    </div>
                  </div>
                  <div class='row text-center '>
                    <div class='col-lg-12 col-md-12 mb-1'>
                      <div class='md'>
                        {' '}
                        <label for='registerFullName'>
                          <center>AFTER CDF FUNDING </center>
                          <span class='red-text'></span>{' '}
                        </label>{' '}
                      </div>
                    </div>
                  </div>
                  <div class='row text-left'>
                    <div class='col-lg-3 col-md-12 mb-1'>
                      <div class='md-form'>
                        {' '}
                        <label for='registerFullName'>
                          Women
                          <span class='red-text'>*</span>{' '}
                        </label>{' '}
                        <br /> <br />
                        <input
                          type='text'
                          class='form-control'
                          value={numberOfWorkersAfterCDFFundingWomen}
                          onChange={e =>
                            setNumberOfWorkersAfterCDFFundingWomen(
                              e.target.value
                            )
                          }
                          id='numberOfWorkersAfterCDFFundingWomen'
                        />
                      </div>
                    </div>

                    <div class='col-lg-3 col-md-12 mb-1'>
                      <div class='md-form'>
                        {' '}
                        <label for='registerFullName'>
                          Men
                          <span class='red-text'>*</span>{' '}
                        </label>{' '}
                        <br /> <br />
                        <input
                          type='text'
                          class='form-control'
                          value={numberOfWorkersAfterCDFFundingMen}
                          onChange={e =>
                            setNumberOfWorkersAfterCDFFundingMen(e.target.value)
                          }
                          id='numberOfWorkersAfterCDFFundingMen'
                        />
                      </div>
                    </div>

                    <div class='col-lg-3 col-md-12 mb-1'>
                      <div class='md-form'>
                        {' '}
                        <label for='registerFullName'>
                          Youth
                          <span class='red-text'>*</span>{' '}
                        </label>{' '}
                        <br /> <br />
                        <input
                          type='text'
                          class='form-control'
                          value={numberOfWorkersAfterCDFFundingYouth}
                          onChange={e =>
                            setNumberOfWorkersAfterCDFFundingYouth(
                              e.target.value
                            )
                          }
                          id='numberOfWorkersAfterCDFFundingYouth'
                        />
                      </div>
                    </div>

                    <div class='col-lg-3 col-md-12 mb-1'>
                      <div class='md-form'>
                        {' '}
                        <label for='registerFullName'>
                          Physically Challenged (Disabled)
                          <span class='red-text'>*</span>{' '}
                        </label>{' '}
                        <br /> <br />
                        <input
                          type='text'
                          class='form-control'
                          value={numberOfWorkersAfterCDFFundingDisabled}
                          onChange={e =>
                            setNumberOfWorkersAfterCDFFundingDisabled(
                              e.target.value
                            )
                          }
                          id='numberOfWorkersAfterCDFFundingDisabled'
                        />
                      </div>
                    </div>
                  </div>
                  <div class='row text-left'>
                    <div class='col-lg-12 col-md-12 mb-1'>
                      <div class='md'>
                        {' '}
                        <label for='registerFullName'>
                          13.0 PROJECT BUDGET
                        </label>
                      </div>
                    </div>
                  </div>
                  {projectBudgetInputList.map(
                    (projectBudgetSingleInputList, projectBudgetIndex) => {
                      return (
                        <>
                          <div class='row text-left'>
                            <div class='col-lg-3 col-md-12 mb-1'>
                              <div class='md-form'>
                                {' '}
                                <label for='registerFullName'>
                                  {projectBudgetIndex + 1} . Item
                                  <span class='red-text'>*</span>{' '}
                                </label>{' '}
                                <br />
                                <input
                                  type='text'
                                  class='form-control'
                                  placeholder=''
                                  value={
                                    projectBudgetSingleInputList.projectBudgetItem
                                  }
                                  onChange={e =>
                                    handleProjectBudgetInputChange(
                                      e,
                                      projectBudgetIndex
                                    )
                                  }
                                  name='projectBudgetItem'
                                />
                              </div>
                            </div>

                            <div class='col-lg-3 col-md-12 mb-1'>
                              <div class='md-form'>
                                {' '}
                                <label for='registerFullName'>
                                  Quantity
                                  <span class='red-text'>*</span>{' '}
                                </label>{' '}
                                <br />
                                <input
                                  type='text'
                                  class='form-control'
                                  placeholder=''
                                  value={
                                    projectBudgetSingleInputList.projectBudgetQuantity
                                  }
                                  onChange={e =>
                                    handleProjectBudgetInputChange(
                                      e,
                                      projectBudgetIndex
                                    )
                                  }
                                  name='projectBudgetQuantity'
                                />
                              </div>
                            </div>

                            <div class='col-lg-2 col-md-12 mb-1'>
                              <div class='md-form'>
                                {' '}
                                <label for='registerFullName'>
                                  Cost (ZMW)
                                  <span class='red-text'>*</span>{' '}
                                </label>{' '}
                                <br />
                                <input
                                  type='text'
                                  class='form-control'
                                  placeholder=''
                                  value={
                                    projectBudgetSingleInputList.projectBudgetCost
                                  }
                                  onChange={e =>
                                    handleProjectBudgetInputChange(
                                      e,
                                      projectBudgetIndex
                                    )
                                  }
                                  name='projectBudgetCost'
                                />
                              </div>
                            </div>

                            <div class='col-lg-3 col-md-12 mb-1'>
                              <div class='md-form'>
                                {' '}
                                <label for='registerFullName'>
                                  Total (ZMW)
                                  <span class='red-text'>*</span>{' '}
                                </label>{' '}
                                <br />
                                <input
                                  type='text'
                                  class='form-control'
                                  placeholder=''
                                  value={
                                    projectBudgetSingleInputList.projectBudgetTotal
                                  }
                                  onChange={e =>
                                    handleProjectBudgetInputChange(
                                      e,
                                      projectBudgetIndex
                                    )
                                  }
                                  name='projectBudgetTotal'
                                />
                              </div>
                            </div>

                            {projectBudgetInputList.length !== 1 && (
                              <div class='col-lg-1 col-md-2 mb-2'>
                                <div class='md-form'>
                                  <br />
                                  <input
                                    type='button'
                                    class='form-control btn white-text red accent-2'
                                    value='Remove'
                                    onClick={() =>
                                      handleRemoveClickProjectBudget(
                                        projectBudgetIndex
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            )}
                            {/*<!--\Grid column-->	*/}

                            {/*<!--Grid column-->*/}
                            {projectBudgetInputList.length - 1 ===
                              projectBudgetIndex && (
                              <div class='col-lg-12 col-md-12'>
                                <div class='md-form'>
                                  <input
                                    type='button'
                                    class='form-control btn white-text blue accent-2'
                                    value='Add'
                                    onClick={handleAddClickProjectBudget}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </>
                      )
                    }
                  )}
                  <div class='row text-left'>
                    <div class='col-lg-12 col-md-12 mb-1'>
                      <div class='md'>
                        {' '}
                        <label for='registerFullName'>
                          14.0 CLIENT CONTRIBUTION <br />
                          <i>
                            Will any of the above items be financed by the
                            Business? If so, list below.
                          </i>
                        </label>
                      </div>
                    </div>
                  </div>
                  {clientContributionInputList.map(
                    (
                      clientContributionSingleInputList,
                      clientContributionIndex
                    ) => {
                      return (
                        <>
                          <div class='row text-left'>
                            <div class='col-lg-3 col-md-12 mb-1'>
                              <div class='md-form'>
                                {' '}
                                <label for='registerFullName'>
                                  {clientContributionIndex + 1} . Item
                                  <span class='red-text'>*</span>{' '}
                                </label>{' '}
                                <br />
                                <input
                                  type='text'
                                  class='form-control'
                                  placeholder=''
                                  value={
                                    clientContributionInputList.clientContributionItem
                                  }
                                  onChange={e =>
                                    handleClientContributionInputChange(
                                      e,
                                      clientContributionIndex
                                    )
                                  }
                                  name='clientContributionItem'
                                />
                              </div>
                            </div>

                            <div class='col-lg-3 col-md-12 mb-1'>
                              <div class='md-form'>
                                {' '}
                                <label for='registerFullName'>
                                  Quantity
                                  <span class='red-text'>*</span>{' '}
                                </label>{' '}
                                <br />
                                <input
                                  type='text'
                                  class='form-control'
                                  placeholder=''
                                  value={
                                    clientContributionSingleInputList.clientContributionQuantity
                                  }
                                  onChange={e =>
                                    handleClientContributionInputChange(
                                      e,
                                      clientContributionIndex
                                    )
                                  }
                                  name='clientContributionQuantity'
                                />
                              </div>
                            </div>

                            <div class='col-lg-2 col-md-12 mb-1'>
                              <div class='md-form'>
                                {' '}
                                <label for='registerFullName'>
                                  Cost (ZMW)
                                  <span class='red-text'>*</span>{' '}
                                </label>{' '}
                                <br />
                                <input
                                  type='text'
                                  class='form-control'
                                  placeholder=''
                                  value={
                                    clientContributionSingleInputList.clientContributionCost
                                  }
                                  onChange={e =>
                                    handleClientContributionInputChange(
                                      e,
                                      clientContributionIndex
                                    )
                                  }
                                  name='clientContributionCost'
                                />
                              </div>
                            </div>

                            <div class='col-lg-3 col-md-12 mb-1'>
                              <div class='md-form'>
                                {' '}
                                <label for='registerFullName'>
                                  Total (ZMW)
                                  <span class='red-text'>*</span>{' '}
                                </label>{' '}
                                <br />
                                <input
                                  type='text'
                                  class='form-control'
                                  placeholder=''
                                  value={
                                    clientContributionSingleInputList.clientContributionTotal
                                  }
                                  onChange={e =>
                                    handleClientContributionInputChange(
                                      e,
                                      clientContributionIndex
                                    )
                                  }
                                  name='clientContributionTotal'
                                />
                              </div>
                            </div>

                            {clientContributionInputList.length !== 1 && (
                              <div class='col-lg-1 col-md-2 mb-2'>
                                <div class='md-form'>
                                  <br />
                                  <input
                                    type='button'
                                    class='form-control btn white-text red accent-2'
                                    value='Remove'
                                    onClick={() =>
                                      handleRemoveClickClientContribution(
                                        clientContributionIndex
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            )}
                            {/*<!--\Grid column-->	*/}

                            {/*<!--Grid column-->*/}
                            {clientContributionInputList.length - 1 ===
                              clientContributionIndex && (
                              <div class='col-lg-12 col-md-12'>
                                <div class='md-form'>
                                  <input
                                    type='button'
                                    class='form-control btn white-text blue accent-2'
                                    value='Add'
                                    onClick={handleAddClickClientContribution}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </>
                      )
                    }
                  )}
                  <div class='row text-left'>
                    <div class='col-lg-12 col-md-12 mb-1'>
                      <div class='md'>
                        {' '}
                        <label for='registerFullName'>
                          15.0 Loan Information
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class='row text-left'>
                    <div class='col-lg-12 col-md-12 mb-1'>
                      <div class='md'>
                        {' '}
                        <label for='registerFullName'>
                          CAPITAL THRESHOLD REPAYMENT PERIOD (select preferred
                          capital threshold)
                          <br />
                          <i>Note: The interest rate for all loans is 5%</i>
                          <span class='red-text'>*</span>
                        </label>
                        <br />
                        <br />
                        <select
                          class='custom-select d-block w-100'
                          value={capitalThresholdRepaymentPeriod}
                          onChange={e =>
                            setCapitalThresholdRepaymentPeriod(e.target.value)
                          }
                          id='capitalThresholdRepaymentPeriod'
                        >
                          <option value=''>Choose ....</option>
                          <option value='12'>
                            1. K5,000 – K10,000: Repayment Period 12 months
                          </option>
                          <option value='18'>
                            2. K10,001 – K25,000: Repayment Period 18 months
                          </option>
                          <option value='24'>
                            3. K25,001 – K50,000: Repayment Period 24 months
                          </option>
                          <option value='36'>
                            4. K50,001 – K100,000: Repayment Period 36 months
                          </option>
                          <option value='48'>
                            5. K100,001 – K200,000: Repayment Period 48 months
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class='row text-left'>
                    <div class='col-lg-12 col-md-12 mb-1'>
                      <div class='md'>
                        {' '}
                        <label for='registerFullName'>
                          16.0 Project Implementation
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class='row text-left'>
                    <div class='col-lg-12 col-md-12 mb-1'>
                      <div class='md'>
                        {' '}
                        <label for='registerFullName'>
                          If you are funded, when would operations start?
                          <span class='red-text'>*</span>
                        </label>
                        <br />
                        <br />
                        <select
                          class='custom-select d-block w-100'
                          value={ifYouAreFundedWhenWouldOperationsStart}
                          onChange={e =>
                            setIfYouAreFundedWhenWouldOperationsStart(
                              e.target.value
                            )
                          }
                          id='ifYouAreFundedWhenWouldOperationsStart'
                        >
                          <option value=''>Choose ....</option>
                          <option>Within 1-2 months</option>
                          <option>Within 3-4 months</option>
                          <option>Within 5-6 months</option>
                          <option>More than 6 months</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class='row text-left'>
                    <div class='col-lg-12 col-md-12 mb-1'>
                      <div class='md'>
                        {' '}
                        <label for='registerFullName'>17.0 DECLARATION</label>
                      </div>
                    </div>
                  </div>
                  <div class='row'>
                    {/* <!--Grid column-->	*/}
                    <div class='col-lg-12 col-md-12 mb-1 text-left'>
                      <br />
                      We the undersigned, on{' '}
                      <input
                        type='text'
                        placeholder='Indicate Day Here'
                        value={declarationApplicantDay}
                        onChange={e =>
                          setDeclarationApplicantDay(e.target.value)
                        }
                        id='declarationApplicantDay'
                      />{' '}
                      This{' '}
                      <input
                        type='date'
                        placeholder='Indicate Date Here'
                        value={declarationApplicantDate}
                        onChange={e =>
                          setDeclarationApplicantDate(e.target.value)
                        }
                        id='declarationApplicantDate'
                      />{' '}
                      20... declare that the information given herein is the
                      correct state of affairs to the best of my knowledge. We
                      will take full responsibility in the event of abuse,
                      mismanagement, defrauding of the funds provided under this
                      empowerment fund:
                    </div>
                    {/* <!--\Grid column-->		*/}
                  </div>
                  {/*<!--\Grid row-->*/}
                  <br /> <br />
                  <button
                    class='btn btn-success  btn-md btn-block mt-2 mb-5'
                    type='submit'
                    onClick={handleSubmit}
                    id='submit'
                  >
                    Submit
                  </button>
                </div>
                {/* <!--/.Card-- br >*/}
              </div>
            </div>
            {/* <!--Grid row-->*/}
          </div>
        </div>
      </div>
      {/*<!-- Main layout -->*/}
    </>
  )
}

export default Loan
